import React from 'react'
import { Link } from 'react-router-dom'


const Footer = () => {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="footer-connect">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="connect-heading">
                                    <h4>Connect with us</h4>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="social-touch-icons">
                                    <ul>
                                        <li><Link to="#"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
                                        <li><Link to="#"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="footer-panel footer-information">
                                <div className="office-location clearfix">
                                    <span className="location-icon">
                                        <img src="https://procorpsystems.co/frontend/images/location-icon.png" alt="location-icon" />
                                    </span>
                                    <span className="address-title">
                                        2222 W Spring Creek Pkwy,
                                        STE 202,Plano, TX 75023.
                                    </span>
                                </div>
                                <div className="office-location clearfix">
                                    <span className="location-icon">
                                        <img src="https://procorpsystems.co/frontend/images/phone-icon.png" alt="phone-icon" />
                                    </span>
                                    <span className="address-title">
                                        <Link to="tel:14693670669">+1 469-367-0669</Link>
                                    </span>
                                </div>
                                <div className="office-location clearfix">
                                    <span className="location-icon">
                                        <img src="https://procorpsystems.co/frontend/images/send.png" alt="send" />
                                    </span>
                                    <span className="address-title">
                                        <Link to="mailto:info@procorpsystems.co">info@procorpsystems.co</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="footer-panel">
                                <h4>&nbsp;</h4>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/solution">Solutions</Link></li>
                                    <li><Link to="/procorp-global-locations">Global Locations</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/partners">Partners</Link></li>
                                    <li><Link to="/careers">Careers</Link></li>
                                </ul>
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="https://www.linkedin.com/company/procorp-systems/" target="_blank"><i className="fas fa fa-linkedin"></i> Linkedin</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer