import React, { useEffect } from "react";
import "../../css/Energy.css";

import energyImg1 from "../../Assests/energy-img1.jpg";
import energyImg2 from "../../Assests/energy-img2.jpg";
import energyImg3 from "../../Assests/energy-img3.jpg";
import energyImg4 from "../../Assests/energy-img4.jpg";
import energyImg5 from "../../Assests/energy-img5.jpg";
import energyImg6 from "../../Assests/energy-img6.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Energy = () => {

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Energy and Utilities</title>

        <meta name="keywords" content="Energy and Utilities" />

        <meta name="description" content="Energy and Utilities" />
      </Helmet>
      <div>
        <section class="global-web-page">
          <section className="top-global-slide">
            <div class="container">
              <h1>Energy and Utilities</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/resources">
                    Resources
                  </Link>
                </li>
                <li class="active">Energy and Utilities</li>
              </ul>
            </div>
          </section>

          <section class="gray-shade-bg">
            <section class="deatil-page-section">
              <div class="deatil-top">
                <div class="container">
                  <h2>Energy &amp; Utilities</h2>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <p>
                      The continuously changing economic scenario, M&amp;A
                      activities, privatisation, deregulation and growing
                      competition is leading to a plethora of challenges faced
                      by the Energy Industry. It is changing the way energy
                      companies are doing the business. The drastic shift from
                      nuclear to renewable resources of energy has led the
                      energy companies to innovate on technology front to
                      enhance the business efficiency.
                    </p>

                    <p>
                      We can help you by providing innovative and cutting-edge
                      technology solutions which address your energy management
                      and other operational challenges and help you achieve a
                      competitive advantage.
                    </p>

                    <h4>
                      Get the advantage of Procorp Solutions core technological
                      strengths to power your business and race ahead of your
                      competition
                    </h4>

                    <div class="detail-block-list">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={energyImg1} alt="en-ut-img-001" />
                            </div>
                            <h3>
                              Streamline supply chain logistics and optimize
                              asset utilization
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={energyImg2} alt="en-ut-img-002" />
                            </div>
                            <h3>
                              Improve and manage trading, marketing and supply
                              chain
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={energyImg3} alt="en-ut-img-003" />
                            </div>
                            <h3>
                              Manage energy programs, support rate &amp;
                              behavior analytics
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={energyImg4} alt="en-ut-img-004" />
                            </div>
                            <h3>
                              Maximize your benefits through investments in
                              smart grid solutions
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={energyImg5} alt="en-ut-img-005" />
                            </div>
                            <h3>
                              Improve field force productivity through our
                              mobility solutions
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={energyImg6} alt="en-ut-img-006" />
                            </div>
                            <h3>
                              Predict demand better by interpreting usage
                              patterns
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3>Unleash Innovation Across the Value Stream</h3>

                    <h4>Exploration &amp; Production</h4>

                    <p>
                      The Upstream Industry is facing multiple challenges due to
                      continuous growth in the demand for oil and gas. It has
                      become elusive for you to keep high margins as you have
                      been pushed to explore the remote unexplored areas which
                      has shot up your operational cost significantly. There is
                      a significant demand of innovative technology advancements
                      in Exploration and Production which improve the
                      performance of the existing fields in hydrocarbon recovery
                      process.
                    </p>

                    <p>
                      Procorp Solutions can help you by implementing innovative
                      IT solutions and services for achieving operational
                      excellence across the entire E&amp;P value chain..
                    </p>

                    <h5>How can we help?</h5>

                    <ul class="help-list">
                      <li>
                        Customized content management solutions to manage
                        E&amp;P content
                      </li>
                      <li>
                        Predictive analytics solutions to increase the maturity
                        of upstream asset management processes
                      </li>
                      <li>
                        Design, integration, implementation and support services
                        for production surveillance and optimization
                      </li>
                      <li>
                        Advisory services on the use of real-time
                        production&nbsp;data&nbsp;to enable smart decision
                        making
                      </li>
                      <li>
                        Services to reduce costs and increase efficiency for oil
                        field services companies
                      </li>
                    </ul>

                    <h4>Logistic &amp; Trading</h4>

                    <p>
                      Oil and gas price volatility pose a major risk across the
                      entire value chain of the industry. The operational
                      excellence achieved in the rest of the value chain takes a
                      solid hit if the price volatility is not managed properly.
                      Along with price volatility, the oil and gas midstream
                      industry faces other challenges such as long supply chain,
                      huge inventory cycle, credit management with rigorous
                      financial or regulatory standards. The industry requires
                      efficiency in supplies, trading, transmission and risk
                      management.
                    </p>

                    <p>
                      We at Procorp Solutions, with the help of our vast pool of
                      deeply experienced Subject Matter Experts, can help you in
                      implementing diverse and innovative solutions on Energy
                      Trading and Risk Management and Pipelines Management.
                    </p>

                    <h5>How can we help?</h5>

                    <ul class="help-list">
                      <li>Energy trading and risk management solutions</li>
                      <li>
                        Advanced analytics solutions to forecast the demand and
                        optimize operations
                      </li>
                      <li>Customized supply chain management solutions</li>
                      <li>
                        Pipelines management solutions including Geographical
                        Information Systems
                      </li>
                      <li>
                        Automate&nbsp;field force through real-time
                        communication technology solutions
                      </li>
                    </ul>

                    <h5>Payments</h5>

                    <p>
                      Market demand and government regulations are the two most
                      influencing factors affecting the value chain of the
                      Refining and Marketing industry. High volatility in crude
                      prices, variations in refining margins, complex supply
                      chain, CRM, brand marketing efforts and continuously
                      changing regulatory compliance are the major challenges
                      that the industry is facing today. In order to tackle
                      these challenges, mitigate the risk involved and to
                      improve the return on investment, energy companies like
                      you are hugely investing in technological solutions. We
                      can help you gear up your business by providing
                      high-quality technology solutions on refinery decision
                      support, asset management, refinery turnaround management,
                      plant information, and many others.
                    </p>

                    <h5>How can we help?</h5>

                    <ul>
                      <li>
                        Integration services for diverse information systems to
                        seamlessly acquire quality control results, scheduling
                        outputs, etc
                      </li>
                      <li>
                        Advanced reporting solutions&nbsp;on quality control to
                        reduce the lead time
                      </li>
                      <li>
                        Customized retail solutions to improve the service
                        delivery and retail margins
                      </li>
                      <li>
                        Predictive monitoring and integrated logistics planning
                      </li>
                    </ul>

                    <p>
                      The utility industry is undergoing a significant
                      technology transformation due to the impact of global
                      forces on the key stakeholders involved. The companies are
                      facing major challenges across verticals such as business,
                      technology, social and environment. Globally, utility
                      companies require innovative solutions to gain ground in
                      today’s competitive environment and meet consumers’
                      expectations. Procorp Solutions come with a vast
                      experience across the energy value chain and offer
                      innovative solutions to address the challenges faced in
                      utility operations and the implementation of metering
                      systems.
                    </p>

                    <p>
                      We have experience in working with RTOs/ISOs to improve
                      operational performance and reliability of equipment and
                      make better decisions on asset investments, which includes
                      real-time operations, predictive maintenance, integrated
                      asset planning, and operations. We also help improve
                      wholesale operations through the process and system
                      design, integration and development. Our areas of
                      expertise include transmission reservations, energy
                      scheduling, metering, capacity planning, ancillary
                      markets, and disaster recovery systems.
                    </p>

                    <h5>How can we help?</h5>

                    <ul class="help-list">
                      <li>
                        Smart Grid: Benefit from our end-to-end embedded product
                        engineering design to foster an improved turnaround time
                        and have huge savings on-field operations.
                      </li>
                      <li>
                        Smart Metering: By leveraging our&nbsp;AI&nbsp;and
                        Advanced data analytics accelerators, we can enable
                        Smart meters to feed business and household energy data
                        back to retailers, which will promote operational and
                        cost efficiencies.
                      </li>
                      <li>
                        Energy Management Systems: We come with experience in
                        SCADA and Energy Management systems (EMS) and the
                        ability to integrate these modular EMS solutions into
                        custom solutions that meet the needs of the future.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};

export default Energy;
