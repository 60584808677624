import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './css/Form.css';
import GlobalRelation from './pages/GlobalRelation';
import Partners from './pages/Partners/Partners';
import Energy from './pages/Resources/Energy';
import SupplyChain from './pages/Resources/SupplyChain';
import Healthcare from './pages/Resources/Healthcare';
import Banking from './pages/Resources/Banking';
import AboutSection from './pages/About/AboutSection';
import Crm from './pages/Resources/Crm';
import Digital from './pages/Resources/Digital';
import Retail from './pages/Resources/Retail';
import Resources from './pages/Resources/Resources';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import Openbanking from './pages/Solution/Openbanking';
import Apieconamy from './pages/Solution/Apieconamy';
import Microservices from './pages/Solution/Microservices';
import Payments from './pages/Solution/Payments';
import Solution from './pages/Solution/Solution';
import Cloud from './pages/Service/Cloud';
import Consulting from './pages/Service/Consulting';
import Data from './pages/Service/Data';
import Ims from './pages/Service/Ims';
import Platform from './pages/Service/Platform';
import CurrentOpening from './pages/CurrentOpening';
import Services from './pages/Service/Services';
import Navbar from './components/Navbar';
import CookieConsent from './components/CookieConsent ';
import TalktoUs from './pages/TalktoUs';

function App() {
  const { pathname } = useLocation();

  // Scroll to top whenever the pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutSection />} />
        <Route path="/procorp-global-locations" element={<GlobalRelation />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources/energy-and-utilities" element={<Energy />} />
        <Route path="/resources/supply-chain-management" element={<SupplyChain />} />
        <Route path="/resources/healthcare-and-life-sciences" element={<Healthcare />} />
        <Route path="/resources/banking-finance" element={<Banking />} />
        <Route path="/resources/crm" element={<Crm />} />
        <Route path="/resources/digital-transformations" element={<Digital />} />
        <Route path="/resources/retail" element={<Retail />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/solution/openbanking" element={<Openbanking />} />
        <Route path="/solution/apieconamy" element={<Apieconamy />} />
        <Route path="/solution/microservices" element={<Microservices />} />
        <Route path="/solution/payments" element={<Payments />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/cloud" element={<Cloud />} />
        <Route path="/services/consulting" element={<Consulting />} />
        <Route path="/services/data" element={<Data />} />
        <Route path="/services/ims" element={<Ims />} />
        <Route path="/services/platform" element={<Platform />} />
        <Route path="/careers" element={<CurrentOpening />} />
        <Route path="/privacy-policy" element={<CurrentOpening />} />
      </Routes>
      <TalktoUs />
      <Footer />
      {/* <CookieConsent /> */}
    </>
  );
}

// Wrap the App component with Router
export default () => (
  <Router>
    <App />
  </Router>
);