import React, { useEffect } from "react";
import "../../css/Partners.css";
import amazon from "../../Assests/amazon.png";
import salesforce from "../../Assests/saleforce_img.png";
import microsoft from "../../Assests/microsoft_img.png";
import azure_consulting from "../../Assests/azure_consulting.png";
import azure_cloud_support from "../../Assests/Azure_cloud_support.png";
import azure_solution_Design_Architecture_ from "../../Assests/Azure_Solution_Design_Architecture_.png";
import azure_solution_Design_Architecture from "../../Assests/Azure_Solution_Design_Architecture.png";
import azure_managed_services from "../../Assests/Azure_Managed_Services.png";
import Microsoft_Certified_Professionals from "../../Assests/Microsoft_Certified_Professionals.png";
import TalktoUs from "../TalktoUs";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Partners = () => {

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Partners</title>

        <meta name="keywords" content="Partners" />

        <meta name="description" content="Partners" />
      </Helmet>
      <div>
        <div className="Main_Partners">
          <section class="top-global-slide">
            <div class="container">
              <h1>Partners</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li class="active">Partners</li>
              </ul>
            </div>
          </section>
          <section className="Main_bg_section">
            <div className="container_amazon_partners" id="amazon-partners">
              <section className="partners-sections amazon-partners">
                <div className="container">
                  <div className="partner-mark-panel">
                    <img src={amazon} />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="glossy-bg">
                        <h3> Depth of Domain Expertise</h3>
                        <div className="strength">
                          <span className="count">150+</span>
                          <span className="active">active resources</span>
                        </div>
                        <div className="part-tech">
                          <ul>
                            <li>AWS Certified Cloud professionals</li>
                            <li>AWS Certified Cloud Architect</li>
                            <li>AWS Cloud Platform Experience</li>
                          </ul>
                        </div>
                        <h4>Verticals</h4>
                        <div className="vertical-technology clearfix">
                          <ul>
                            <li>
                              <i
                                className="fa fa-arrow-circle-o-right"
                                aria-hidden="true"
                              ></i>
                              Technology
                            </li>
                            <li>
                              <i
                                className="fa fa-arrow-circle-o-right"
                                aria-hidden="true"
                              ></i>{" "}
                                 Manufacturing
                            </li>
                            <li>
                              <i
                                className="fa fa-arrow-circle-o-right"
                                aria-hidden="true"
                              ></i>
                                 Banking Financial Services
                            </li>
                            <li>
                              <i
                                className="fa fa-arrow-circle-o-right"
                                aria-hidden="true"
                              ></i>
                              Healthcare
                            </li>
                            <li>
                              <i
                                className="fa fa-arrow-circle-o-right"
                                aria-hidden="true"
                              ></i>
                                 Energy Utilities
                            </li>
                            <li>
                              <i
                                className="fa fa-arrow-circle-o-right"
                                aria-hidden="true"
                              ></i>
                                 Retail
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="glossy-bg">
                        <h3>Comprehensive Approach</h3>
                        <h4>Native, Full -IT -Lifecycle Ecosystem</h4>
                        <div className="part-tech">
                          <ul>
                            <li>Full IT Delivery Lifecycle</li>
                            <li>End-to-End AWS Implementation</li>
                            <li>AWS Cloud platform engineering</li>
                            <li>Build, Migration, Development – AWS Cloud</li>
                            <li>End-to-End AWS IaaS, PaaS Services</li>
                            <li>
                              End-to-End AWS App services, DB, Developer tools
                            </li>
                            <li>
                              End-to-End AWS Enterprise apps, Security,
                              Integration
                            </li>
                            <li>
                              AWS Container management, EKS Cluster management,
                              AWS build, AWS DevOps., AWS Infrastructure
                              Services, Legacy platform Modernization to AWS
                              Cloud, Cloud migration and management
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="glossy-bg">
                        <h3>Superior Talent Network</h3>
                        <h4>Leading In-house Talent Engine</h4>
                        <div className="part-tech">
                          <ul>
                            <li>
                              consultants across diverse customer projects
                            </li>
                          </ul>
                        </div>

                        <h4>Vast Consultant Depth Chart</h4>
                        <div className="part-tech">
                          <ul>
                            <li>
                              Extensive AWS consultants deployed across all
                              major industries
                            </li>
                            <li>Active AWS Certified Consultants</li>
                            <li>
                              Relationship network includes 31% of IT workforce
                              in North America
                            </li>
                          </ul>
                        </div>

                        <h4>Geographic Reach</h4>
                        <div className="part-tech">
                          <ul>
                            <li>Locations across North America and India</li>
                          </ul>
                        </div>

                        <h4>Thought Leadership</h4>
                        <div className="part-tech">
                          <ul>
                            <li>
                              Recognized as Workforce Planning Thought Leader by
                              leading analysts' community
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="glossy-bg">
                        <h3>AWS Offerings</h3>

                        <div className="panel-aws-off">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="panel-aws">
                                <img src={azure_consulting} />
                                <div className="title">AWS Consulting</div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="panel-aws">
                                <img src={azure_cloud_support} />
                                <div className="title">AWS Cloud Support</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="panel-aws-off">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="panel-aws">
                                <img
                                  src={azure_solution_Design_Architecture_}
                                />
                                <div className="title">
                                  AWS Solution Design / Architecture POV
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="panel-aws">
                                <img src={azure_managed_services} />
                                <div className="title">
                                  AWS Managed Services
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="panel-aws-off">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="panel-aws">
                                <img src={azure_solution_Design_Architecture} />
                                <div className="title">
                                  AWS Solution Design / Architecture POV
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="panel-aws">
                                <img src={Microsoft_Certified_Professionals} />
                                <div className="title">
                                  AWS Certified Professionals
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <Link name="microsoft"></Link>
            <section className="partners-sections microsoft-partners" id="microsoft-partners">
              <div className="container">
                <div className="partner-mark-panel">
                  <img src={microsoft} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Depth of Domain Expertise</h3>
                      <div className="strength">
                        <span className="count">100+</span>
                        <span className="active">active resources</span>
                      </div>
                      <div className="part-tech">
                        <ul>
                          <li>AZURE Certified Cloud professionals</li>
                          <li>AZURE Certified Cloud Architect</li>
                          <li>AZURE Cloud Platform Experience</li>
                        </ul>
                      </div>
                      <h4>Verticals</h4>
                      <div className="vertical-technology clearfix">
                        <ul>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Technology
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Manufacturing
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Banking Financial Services
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Healthcare
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Energy Utilities
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Retail
                          </li>
                        </ul>
                      </div>

                      <h4>Key Customers</h4>
                      <div className="vertical-technology clearfix">
                        <ul>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               THE HARTFORD
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               UHG
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               BARCLAYS
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               DTE
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Lowe's
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Chevron
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               JCPenney
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Toyota
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Comprehensive Approach</h3>
                      <h4>Native, Full -IT -Lifecycle Ecosystem</h4>
                      <div className="part-tech">
                        <ul>
                          <li>Full IT Delivery Lifecycle</li>
                          <li>End-to-End Azure Platform Implementation</li>
                          <li>Azure Cloud platform engineering</li>
                          <li>Build, Migration, Development – Azure Cloud</li>
                          <li>End-to-End Azure IaaS, PaaS Services</li>
                          <li>
                            End-to-End Azure App services, DB, Developer tools
                          </li>
                          <li>
                            End-to-End Azure Enterprise apps, Security,
                            Integration
                          </li>
                          <li>
                            Azure Container management, AKS Cluster management,
                            Azure build, Azure DevOps., Azure Infrastructure
                            Services, Legacy platform Modernization to Azure
                            Cloud, Cloud migration and management
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Superior Talent Network</h3>
                      <h4>Leading In-house Talent Engine</h4>
                      <div className="part-tech">
                        <ul>
                          <li>
                            100+ consultants across diverse customer projects
                          </li>
                        </ul>
                      </div>

                      <h4>Vast Consultant Depth Chart</h4>
                      <div className="part-tech">
                        <ul>
                          <li>
                            Extensive Azure consultants deployed across all
                            major industries
                          </li>
                          <li>Active Azure Certified Consultants</li>
                          <li>
                            Relationship network includes 31% of IT workforce in
                            North America
                          </li>
                        </ul>
                      </div>

                      <h4>Geographic Reach</h4>
                      <div className="part-tech">
                        <ul>
                          <li>Locations across North America and India</li>
                        </ul>
                      </div>

                      <h4>Thought Leadership</h4>
                      <div className="part-tech">
                        <ul>
                          <li>
                            Recognized as Workforce Planning Thought Leader by
                            leading analysts' community
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Azure Offerings</h3>

                      <div className="panel-aws-off">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_consulting} />
                              <div className="title">Azure Consulting</div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_cloud_support} />
                              <div className="title">Azure Cloud Support</div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="panel-aws-off">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_solution_Design_Architecture} />
                              <div className="title">
                                Azure Solution Design / Architecture POV
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_managed_services} />
                              <div className="title">
                                Azure Managed Services
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="panel-aws-off">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_solution_Design_Architecture_} />
                              <div className="title">
                                Azure Solution Design / Architecture POV
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={Microsoft_Certified_Professionals} />
                              <div className="title">
                                Microsoft Certified Professionals
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Link name="saleforce"></Link>
            <section className="partners-sections microsoft-partners" id="salesforce-partners">
              <div className="container">
                <div className="partner-mark-panel">
                  <img src={salesforce} />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Depth of Domain Expertise</h3>
                      <div className="strength">
                        <span className="count">200+</span>
                        <span className="active">active resources</span>
                      </div>
                      <div className="part-tech">
                        <ul>
                          <li>Sales Cloud Certified professionals</li>
                          <li>Service Cloud Certified professionals</li>
                          <li>Salesforce CRM Cloud experience</li>
                        </ul>
                      </div>
                      <h4>Verticals</h4>
                      <div className="vertical-technology clearfix">
                        <ul>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Technology
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Manufacturing
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Banking Financial Services
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Healthcare
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Energy Utilities
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Retail
                          </li>
                        </ul>
                      </div>

                      <h4>Key Customers</h4>
                      <div className="vertical-technology clearfix">
                        <ul>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               StubHub
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               ALTERRA
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               EDISON
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Airbnb
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Cigna
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               WELLS FARGO
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                            Kimberly-Clark
                          </li>
                          <li>
                            <i
                              className="fa fa-arrow-circle-o-right"
                              aria-hidden="true"
                            ></i>
                               Fifth Third Bank
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Comprehensive Approach</h3>
                      <h4>Native, Full -IT -Lifecycle Ecosystem</h4>
                      <div className="part-tech">
                        <ul>
                          <li>Full IT Delivery Lifecycle</li>
                          <li>End-to-End SFDC Implementation</li>
                          <li>Salesforce 1 customer success platform</li>
                          <li>
                            Build, Migration, Development – Force.com, Visual
                            Force
                          </li>
                          <li>
                            System Integration ~ Service mapping ~ Business
                            Services
                          </li>
                          <li>
                            Service Cloud, Heroku implementations, Lightning
                          </li>
                          <li>
                            Salesforce Cloud Apex, Cloud Migration, Marketing
                            Cloud
                          </li>
                          <li>
                            IT Service Desk, Customer Service Management, ITSM
                            setup and build – Incident mgmt., Change mgmt.,
                            problem mgmt., Knowledge, Case mgmt.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Superior Talent Network</h3>
                      <h4>Leading In-house Talent Engine</h4>
                      <div className="part-tech">
                        <ul>
                          <li>
                            200+ consultants across diverse customer projects
                          </li>
                        </ul>
                      </div>

                      <h4>Vast Consultant Depth Chart</h4>
                      <div className="part-tech">
                        <ul>
                          <li>
                            Extensive ServiceNow consultants deployed across all
                            major industries
                          </li>
                          <li>Active SFDC Certified Consultants</li>
                          <li>
                            Relationship network includes 81% of IT workforce in
                            North America
                          </li>
                        </ul>
                      </div>

                      <h4>Geographic Reach</h4>
                      <div className="part-tech">
                        <ul>
                          <li>Locations across North America and India</li>
                        </ul>
                      </div>

                      <h4>Thought Leadership</h4>
                      <div className="part-tech">
                        <ul>
                          <li>
                            Recognized as Workforce Planning Thought Leader by
                            leading analysts' community
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="glossy-bg">
                      <h3>Salesforce CRM Cloud Offerings</h3>

                      <div className="panel-aws-off">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_consulting} />
                              <div className="title">Salesforce Consulting</div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_cloud_support} />
                              <div className="title">
                                Salesforce Cloud Support
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="panel-aws-off">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_solution_Design_Architecture_} />
                              <div className="title">
                                Salesforce Solution Design / Architecture POV
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_managed_services} />
                              <div className="title">
                                Salesforce Managed Services
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="panel-aws-off">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={azure_solution_Design_Architecture} />
                              <div className="title">
                                Salesforce Solution Design / Architecture POV
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="panel-aws">
                              <img src={Microsoft_Certified_Professionals} />
                              <div className="title">
                                Salesforce Certified Professionals
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </>
  );
};

export default Partners;
