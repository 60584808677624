import React, { useEffect } from "react";
import security from "../../Assests/apieconamy/security.jpg";
import trafficmanagement from "../../Assests/apieconamy/traffic-management.jpg";
import cache from "../../Assests/apieconamy/cache.jpg";
import apidd from "../../Assests/apieconamy/api-dd.jpg";
import lang from "../../Assests/apieconamy/lang.jpg";
import messagetech from "../../Assests/apieconamy/message-tech.jpg";
import apidetail from "../../Assests/apieconamy/api-detail.jpg";
import APIFlow from "../../Assests/apieconamy/API-Flow.png";
import APIComponents from "../../Assests/apieconamy/API-Components.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Apieconamy = () => {
  const servicesData = [
    {
      title: "Security",
      image: security,
      alt: "Security",
      features: [
        "App level authentication",
        "User level authentication",
        "2-way SSL",
        "DoS Protection",
        "Code Injection blocking",
        "Content threat protection",
        "oAuth Toolkit for enhanced security",
      ],
    },
    {
      title: "Traffic Management",
      image: trafficmanagement,
      alt: "Traffic Management",
      features: ["Quota", "Spike Arrest", "Rate limit"],
    },
    {
      title: "Cache Management",
      image: cache,
      alt: "Cache Management",
      features: ["Transient cache", "Persistence Cache", "Analytics Dashboard"],
    },
    {
      title: "API Management",
      image: apidd,
      alt: "API Management",
      features: [
        "Control and throttle API traffic",
        "Transformation between supported protocols",
        "Protection mechanisms, API versioning",
        "Node.js environment for building APIs",
        "Analytics dashboards",
      ],
    },
    {
      title: "Protocol Mediation",
      image: lang,
      alt: "Protocol Mediation",
      features: [
        "Transformation between REST and SOAP",
        "Transformation between JSON and XML",
        "Transformation to HTTP/HTTPS endpoints",
      ],
    },
    {
      title: "Versioning Support & Message Enrichment",
      image: messagetech,
      alt: "Versioning Support",
      features: [
        "Automatic version update",
        "Rollback to previous version",
        "Mashup data from sources",
        "Modify headers and payloads",
      ],
    },
  ];
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>API Economy</title>

        <meta name="keywords" content="API Economy" />

        <meta name="description" content="API Economy" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="global-slide-lg"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/b25bcb2400b34a0d9742a14927136507.jpg')",
          }}
        >
          <div className="container">
            <h1>API Economy</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/solution">
                  Solutions
                </Link>
              </li>
              <li className="active">API Economy</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="deatil-page-section">
            <div className="deatil-top">
              <div className="container">
                <h2>API Economy</h2>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <section className="consulting-section cloud-computing-section">
                    <div>
                      <div className="row ">
                        <div
                          className="col-md-6"
                          style={{ margin: "0 0 30px" }}
                        >
                          <div className="common-image-overflow">
                            <img src={apidetail} alt="API Detail" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="digital-consult-panel margin-zero">
                            <p>
                              The API economy refers to the set of business
                              models and practices designed around the use of
                              APIs in today's digital economy. It involves the
                              exposure of an organization's digital services and
                              assets through application programming interfaces
                              (APIs) in a controlled way.
                            </p>
                            <p>
                              Applications and their underlying data are
                              long-established cornerstones of many
                              organizations. All too often, however, they have
                              been the territory of internal R&amp;D and IT
                              departments. From the earliest days of computing,
                              systems have had to talk to each other to share
                              information across physical and logical boundaries
                              and solve for the interdependencies inherent in
                              many business scenarios. The trend toward
                              integration has been steadily accelerating over
                              the years. It is driven by increasingly
                              sophisticated ecosystems and business processes
                              that are supported by complex interactions across
                              multiple endpoints in custom software, in-house
                              packaged applications, and third-party services
                              (cloud or otherwise).
                            </p>

                            <p>
                              The growth of APIs stems from an elementary need:
                              a better way to encapsulate and share information
                              and enable transaction processing between elements
                              in the solution stack. Unfortunately, APIs have
                              often been treated as tactical assets until
                              relatively recently. APIs, their offspring of EDI
                              and fuel the new API economy.
                            </p>
                          </div>
                        </div>
                      </div>

                      <h2 className="mt-2">API Economy - Benefits?</h2>
                      <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <div className="row">
                          {servicesData.map((service, index) => (
                            <div key={index} className="col-md-4 col-sm-6">
                              <div className="dg-consult custom-benefit-height">
                                <img src={service.image} alt={service.alt} />
                                <h3>{service.title}</h3>
                                <ul>
                                  {service.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <h2 className="mt-5">
                        API Management - Integrated Workflow
                      </h2>
                      <div className="api-management-center">
                        <img src={APIFlow} alt="API Flow" />
                      </div>

                      <h2 className="mt-5">API Management Components</h2>
                      <div className="api-management-center">
                        <img src={APIComponents} alt="API Components" />
                      </div>

                      <h2 className="mt-5">Enterprise Integration Solutions</h2>
                      <p>
                        Connectivity is the key when it comes to your customers,
                        partners, and vendors. There is an increasing demand for
                        omni-channel experience at every stage of the customer
                        journey, irrespective of the business domain. To stay
                        relevant, enterprises need to modernize their
                        integration abilities, optimize, and automate processes
                        to connect ‘on the go’ and make their business more
                        cohesive. This not only reduces operational complexities
                        but also keeps the businesses ahead in their digital
                        journey through streamlined processes.
                      </p>

                      <h2>ProCorp’s Integration Center of Excellence </h2>
                      <p>
                        Helps in servicing integration requirements such as
                        API-led connectivity, microservices enablement,
                        application integration, business activity monitoring,
                        SOA, EAI &amp; ESB integration, mobility integrations
                        and Cloud, on–premise and hybrid integrations. We have
                        unique experience in integrating Salesforce, SAP, and
                        Oracle to Business Applications. We have been helping
                        enterprises in designing optimal enterprise application
                        integration solutions, leveraging our strategic
                        alliances, and using best–in–class industry standards
                        and processes.
                      </p>
                      <div className="common-services-panel-area transparent-bg-services">
                        <div className="row">
                          <div className="col-sm-6">
                            <h4>Value-add customers derive:</h4>
                            <ul>
                              <li>Enhanced customer loyalty</li>
                              <li>Personalized content across channels</li>
                              <li>Smoother channel transformation</li>
                              <li>Increased revenue due to retention</li>
                            </ul>
                          </div>
                          <div className="col-sm-6">
                            <h4>Solution Portfolio</h4>
                            <ul>
                              <li>Advisory & Execution</li>
                              <li>API Reference Architecture</li>
                              <li>Legacy Platform Modernization</li>
                              <li>Process Optimization</li>
                              <li>Integration Platform as-a-Service</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <p className="mt-5">
                        Developing a digital experience or product from end to
                        end requires input and expertise from a variety of
                        roles. Our teams unite to deliver technology solutions
                        that move your business forward.
                      </p>
                      <p>
                        Through our global Engineering delivery, we train our
                        engineers at all levels on the newest technologies and
                        implementation frameworks. With our Global Delivery
                        engineering centers, we deploy the best teams for your
                        business from anywhere in the world and deliver speed
                        and quality at scale.
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Apieconamy;
