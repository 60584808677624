import React, { useEffect } from "react";
import cloudServices from "../../Assests/ims/cloud-services.jpg";
import devDifferent from "../../Assests/ims/dev-different.jpg";
import imsDetailTop from "../../Assests/ims/ims-detail-top.jpg";
import itInvt from "../../Assests/ims/it-invt.jpg";
import itServices from "../../Assests/ims/it-services.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Ims = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Infrastructure Managed Services</title>

        <meta name="keywords" content="Infrastructure Managed Services" />

        <meta name="description" content="Infrastructure Managed Services" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="top-global-slide"
          style={{
            backgroundColor: "#454545",
            backgroundImage: `url('/Images/af2ecd48a04ba347c12cd2ad2e13086b.jpg')`,
          }}
        >
          <div className="container">
            <h1>IMS</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">
                  Services
                </Link>
              </li>
              <li className="active">IMS</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="consulting-section cloud-computing-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="common-image-overflow">
                    <img src={imsDetailTop} alt="IMS Detail" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="digital-consult-panel margin-zero">
                    <p>
                      Many small and medium-sized businesses cannot afford to
                      hire a full-time IT professional. Why hire a person when
                      you can gain a team of experts through ProCorp at a
                      fraction of the costs? For over 20 years, we have been
                      offering businesses Managed IT services for needs ranging
                      from computer support to data recovery, to network
                      security, cloud computing, and more.
                    </p>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <h2>What We Do - Services Portfolio</h2>

              <div className="common-services-panel-area">
                <h2>Managed IT Services</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={itServices} alt="IT Services" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      As an enterprise-class IT services company, we have a
                      range of delivery models designed out of a strong
                      foundation of adaptability, reliability, and expertise to
                      help your clients drive their businesses towards their
                      transformational goals. Our comprehensive IT professional
                      services can cover every aspect of your technology needs
                      from development to operations.
                    </p>
                    <p>
                      No matter what your needs are, our comprehensive Managed
                      IT services can help create a solution for your business,
                      including:
                    </p>
                    <ul>
                      <li>Migrations & Deployments</li>
                      <li>Backup & Disaster Recovery</li>
                      <li>PC & Mac Solutions</li>
                      <li>Server & Networking</li>
                      <li>Compliance Audits</li>
                      <li>Network Security</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area transparent-bg-services custom-ip-row">
                <div className="row">
                  <div className="col-md-12">
                    <p>
                      Many companies do not realize the benefits of having
                      managed services for their IT investments. Our seasoned
                      and certified IT experts work to make your IT environment
                      an asset rather than a liability, so you can improve
                      productivity and focus better on expanding and growing the
                      business.
                    </p>
                    <p>
                      Whether you leverage us to supplement your IT department
                      or to become your IT department, with ProCorp, you will
                      reduce costs while gaining access to the latest available
                      technology. Some of the Managed IT Services we offer
                      include:
                    </p>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <h4>What we provide:</h4>
                        <ul>
                          <li>Network Management</li>
                          <li>Desktop & Laptop Support</li>
                          <li>Software Support</li>
                          <li>Anti-Virus Management</li>
                          <li>ServiceNow managed services</li>
                          <li>IaaS, PaaS, SaaS Services support</li>
                        </ul>
                      </div>
                      <div className="col-md-9">
                        <img src={itInvt} alt="IT Investments" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area">
                <h2>Cloud Managed Services</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      Convert your IT investments into a predictable operating
                      expense. Our managed cloud solutions give you the
                      flexibility to store a part of your data in the cloud,
                      such as emails, while keeping your sensitive data stored
                      locally. Our Managed Hosting services enable you to
                      eliminate upfront server costs while giving you access to
                      Fortune 500 class data hosted solutions for your email,
                      file storage, and backup needs.
                    </p>
                    <p>
                      We offer a wide range of reliable, scalable, and secure
                      services, including:
                    </p>
                    <ul>
                      <li>Server Migration & Deployments</li>
                      <li>Microsoft Office 365 Services</li>
                      <li>Virtual Desktop Hosting</li>
                      <li>Application Hosting</li>
                      <li>Hosted File Storage</li>
                      <li>Disaster Recovery & Business Continuity</li>
                      <li>Infrastructure as a Service (IaaS)</li>
                      <li>Virtualization Support</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={cloudServices} alt="Cloud Services" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area transparent-bg-services">
                <h2>Our Differentiators</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img
                        src={devDifferent}
                        alt="Development Differentiators"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      A managed service allows you to focus on other aspects of
                      your business while retaining overall governance and
                      oversight of the services we provide. Working from secure
                      and reliable facilities in the U.S., our teams provide
                      remote managed services that span all industries,
                      technologies, and disciplines. We manage our teams and
                      their work to achieve the outcomes and level of service
                      you require.
                    </p>
                    <ul>
                      <li>Reduced on-site contractor costs</li>
                      <li>Access to full lifecycle development capabilities</li>
                      <li>
                        Availability of hard-to-find or specialized skill sets
                      </li>
                      <li>Long-term remote staff engagements</li>
                      <li>Low employee turnover</li>
                      <li>Shorter training and ramp-up times</li>
                      <li>Detailed reporting of defined service delivery</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Ims;
