import React, { useEffect } from "react";
import analyticsSmarter from "../../Assests/data/Analytics_smarter.svg";
import bigDataConsulting from "../../Assests/data/big-data-consulting.jpg";
import bigDataImplementation from "../../Assests/data/big-data-implementation.jpg";
import bigDataVis from "../../Assests/data/big-data-vis.jpg";
import dataArch from "../../Assests/data/data-arch.jpg";
import dataDiscovery from "../../Assests/data/data-discovery.jpg";
import dataImg001 from "../../Assests/data/data-img-001.jpg";
import dataInt from "../../Assests/data/data-int.jpg";
import dataManagement from "../../Assests/data/data-management.jpg";
import dataModeling from "../../Assests/data/data-modeling.jpg";
import dataPlatform from "../../Assests/data/data-platform.jpg";
import dataScience from "../../Assests/data/data-science.jpg";
import dataVis from "../../Assests/data/data-vis.jpg";
import devDifferent from "../../Assests/data/dev-different.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Data = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  const services = [
    {
      title: "Data Consulting",
      image: bigDataConsulting,
      alt: "Data Consulting",
      listItems: [
        "Landscape Assessment",
        "Strategy And Roadmap",
        "Technology Selection",
        "Capacity Planning",
        "Design and Architecture",
      ],
    },
    {
      title: "Data Engineering",
      image: bigDataImplementation,
      alt: "Data Engineering",
      listItems: [
        "Installation and Integrations",
        "Ingestion, Transformation, and Processing",
        "Governance and Security",
        "Performance Optimization",
      ],
    },
    {
      title: "Visualization & BI",
      image: bigDataVis,
      alt: "Data Visualization",
      listItems: [
        "Advanced Analytics",
        "Reports and Visualization",
        "Dashboards and Alerts",
      ],
    },
  ];

  const timelineData = [
    {
      title: "Past",
      listItems: ["IT Led", "Manual Data Modelling and Report Building"],
    },
    {
      title: "Present",
      listItems: ["Business Led", "Self Service Data Exploration and Analysis"],
    },
    {
      title: "Future",
      listItems: ["Omnipresent", "Context-driven Interactive Visualization"],
    },
  ];
  const dataOfferings = [
    {
      title: "Augmented Data Discovery",
      listItems: [
        "Automated Self-service BI",
        "Supports Reuse and Governance",
        "Suitable for Large Data Sets",
        "Supports Unstructured Data",
      ],
    },
    {
      title: "Business Intelligence",
      listItems: [
        "Data Architecture and Design",
        "Data Modeling",
        "Master Data Management",
        "Data Security",
        "Data Integration",
      ],
    },
    {
      title: "Data Engineering",
      listItems: [
        "Enterprise Data Warehousing",
        "Data Lakes",
        "Stream Analytics",
        "Data Warehouse Modernization and ETL",
        "Data Visualization and Reporting",
      ],
    },
    {
      title: "Data Science",
      listItems: [
        "Predictive Intelligence",
        "Supervised Learning",
        "Unsupervised Learning",
        "Reinforced Learning",
        "Predictive analytics",
        "AI / ML",
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Data Services</title>

        <meta name="keywords" content="Data Services" />

        <meta name="description" content="Data Services" />
      </Helmet>
      <section className="global-web-page">
        {/* Top Section with Background Image */}
        <section
          className="top-global-slide"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              'url("/Images/af2ecd48a04ba347c12cd2ad2e13086b.jpg")',
          }}
        >
          <div className="container">
            <h1>Data</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">
                  Services
                </Link>
              </li>
              <li className="active">Data</li>
            </ul>
          </div>
        </section>

        {/* Gray Shade Background Section */}
        <section className="gray-shade-bg">
          <section className="consulting-section cloud-computing-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="data-img-panel"></div>
                </div>
                <div className="col-md-6">
                  <div className="digital-consult-panel">
                    <p>
                      We are approaching a data-led economy where data is
                      revolutionizing the way we conduct business. While an
                      exploding data volume opens exciting possibilities, it is
                      not sufficient to make smarter business decisions. We need
                      to have the appropriate infrastructure in place to process
                      big data in meaningful ways for deeper business
                      understanding.
                    </p>
                  </div>
                </div>
              </div>

              <h2>What We Do - Services Portfolio</h2>

              {/* Data Consulting, Engineering, and Visualization */}
              <div className="digital-consulting-option cloud-digital-consulting-option data-consulting-option">
                <div className="row">
                  {services.map((service, index) => (
                    <div key={index} className="col-md-4">
                      <div className="dg-consult">
                        <h3>{service.title}</h3>
                        <img src={service.image} alt={service.alt} />
                        <ul>
                          {service.listItems.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <p>
                We help leading organizations set up their big data ecosystem as
                per their business needs. We advise you on implementing
                cost-effective big data solutions to extract unprecedented value
                from your data.
              </p>

              {/* Data Science Section */}
              <div className="common-services-panel-area">
                <h2>Data Science</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={dataScience} alt="Data Science" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      We use data science solutions to leverage the power of
                      predictive analytics and derive real-time insights to
                      reduce customer churn. We help our clients solve complex
                      data challenges, predict demand, improve customer
                      satisfaction, and guide business strategies.
                    </p>
                    <h4>We enable organizations to:</h4>
                    <ul>
                      <li>
                        Craft customized statistical models and algorithms
                      </li>
                      <li>
                        Leverage advanced customer, operational, and IoT
                        analytics
                      </li>
                      <li>
                        Design and deploy intelligent insights in near real-time
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Data Discovery Section */}
              <div className="common-services-panel-area transparent-bg-services">
                <h2>Data Discovery</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      Augmented data discovery extends the capabilities of
                      traditional BI by automating data preparation and
                      organization. Besides the ability to comprehend
                      unstructured data, data sources can be extended to email,
                      social media channels, IoT feeds, and customer service
                      interactions.
                    </p>
                    <h4>What we provide:</h4>
                    <ul>
                      <li>Automated Data Preparation and Organizing</li>
                      <li>Analysis of Unstructured Data</li>
                      <li>Significant Reduction in Time-to-insight</li>
                      <li>
                        Integration of Cognitive Services with Visualization
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={dataDiscovery} alt="Data Discovery" />
                    </div>
                  </div>
                </div>
              </div>

              {/* Our Differentiators Section */}
              <div className="common-services-panel-area mrtp-50">
                <h2>Our Differentiators</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={devDifferent} alt="Differentiators" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      What separates us from the crowd is our ability to
                      converge visualization platforms with the cognitive
                      platforms to extract more value from your data. Benefit
                      from our mature, time-tested processes on Change
                      Management, Information Security, Governance, and
                      Infrastructure Configuration that guide us along each step
                      of our Data to Insights pipeline.
                    </p>
                    <ul>
                      <li>
                        Selection of right tools by drawing on our experience
                        across all industry-leading BI products
                      </li>
                      <li>
                        Designing and development of context-driven reports and
                        intuitive visualizations
                      </li>
                      <li>
                        Interactive dashboards for real-time decision-making
                      </li>
                      <li>
                        Standard reports for business-as-usual &amp; regulatory
                        reporting
                      </li>
                      <li>Agile visualization development</li>
                      <li>
                        Capabilities to marry visualization with cognitive AI
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Data Insights Section */}
              <div className="common-services-panel-area transparent-bg-services">
                <h2>Data Insights</h2>
                <p>
                  Over the past decade, there has been a considerable change in
                  the way we perceive and process data. Embracing this change is
                  the only way to ride the digital wave. Innovate the way you
                  use data to let digital insight guide you to meet the needs of
                  the future.
                </p>
                <div className="ppf-section">
                  <div className="row">
                    {timelineData.map((section, index) => (
                      <div key={index} className="col-sm-4">
                        <div className="dg-consult">
                          <h3>{section.title}</h3>
                          <ul>
                            {section.listItems.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="common-services-panel-area mrtp-50">
                <h2>Offerings</h2>
                <p>
                  We advise and provide services across the Ingestion, Storage,
                  Transformation, Analysis, and Visualization phase of any
                  data-to-insights project. Derive actionable insights and
                  transform data visualization to expedite business value
                  creation.
                </p>
                <br />

                <div class="svg-img-analysis">
                  <img src={analyticsSmarter} alt="" />
                </div>

                <div className="ppf-section data-offering">
                  <div className="row">
                    {dataOfferings.map((offering, index) => (
                      <div key={index} className="col-sm-3">
                        <div className="dg-consult">
                          <h3>{offering.title}</h3>
                          <ul>
                            {offering.listItems.map((item, idx) => (
                              <li key={idx}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <div class="common-services-panel-area transparent-bg-services">
                <h2>Data Architecture &amp; Design</h2>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      Your data continues to accumulate at an exponential rate
                      from the web, social media, mobile, IoT, and many other
                      sources. A robust master data management strategy and
                      roadmap can connect your business goals to your data and
                      help your stakeholders use that data to achieve those
                      goals.
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>Reconciled data from disparate sources</li>
                      <li>Access to a unified view of data in real-time</li>
                      <li>
                        Data security through authorization and authentication
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <div class="common-image-overflow">
                      <img src={dataArch} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="common-services-panel-area">
                <h2>Master Data Management</h2>
                <div class="row">
                  <div class="col-md-6">
                    <div class="common-image-overflow">
                      <img src={dataManagement} alt="" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <p>
                      Master data management (MDM) allows enterprises to link
                      all their data and have a single source of truth.
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>Creating a single point of reference for all data</li>
                      <li>Systematizing the approach to data integration</li>
                      <li>Consistent use and reuse of data</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="common-services-panel-area transparent-bg-services">
                <h2>Data Modelling</h2>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      We create logical data models, considering the unique
                      nature of your business. While designing the data model,
                      we consider key factors such as scalability, adaptability,
                      efficiency, and performance that fall in line with your
                      business goals.
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>Business specific data models</li>
                      <li>Data model optimization</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <div class="common-image-overflow">
                      <img src={dataModeling} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="common-services-panel-area">
                <h2>Data Integration</h2>
                <div class="row">
                  <div class="col-md-6">
                    <div class="common-image-overflow">
                      <img src={dataInt} alt="" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <p>
                      We design, develop, and implement interfaces between
                      vendor products, custom applications, and other disparate
                      systems, applications, and data. Our consultants
                      understand standards, frameworks, and legislations for
                      information exchange (e.g., NIEM, FHIR) and security
                      (e.g., HIPAA, NCIC).
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>Custom interfaces for applications and systems</li>
                      <li>
                        Consultation on industry standards and best practices
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="common-services-panel-area transparent-bg-services">
                <h2>Data Platform Modernization (EDW, EDL, ETL)</h2>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      With our expertise across data ingestion, migration, and
                      transformation technologies, we accelerate the development
                      and deployment of your data warehouse or modernize your
                      current infrastructure to reflect your growing business
                      needs.
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>Database &amp; tool selections</li>
                      <li>Scalable data warehouse designing</li>
                      <li>Performance tuning &amp; optimization</li>
                      <li>Enterprise-grade security</li>
                      <li>Real-time &amp; near real-time data loading</li>
                      <li>Fail-safe and robust disaster recovery</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <div class="common-image-overflow">
                      <img src={dataPlatform} alt="" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="common-services-panel-area">
                <h2>Data Visualization &amp; Reporting</h2>
                <div class="row">
                  <div class="col-md-6">
                    <div class="common-image-overflow">
                      <img src={dataVis} alt="" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <p>
                      We enable enterprises to convert data into strategic
                      assets that help them improve the top-line and optimize
                      the bottom-line.
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>
                        Selection of the right tools by drawing on our
                        experience across all industry-leading BI products
                      </li>
                      <li>
                        Design and development of advanced reports and appealing
                        visualizations
                      </li>
                      <li>
                        Interactive dashboards for a meaningful view of
                        enterprise-wide data
                      </li>
                      <li>
                        Standard reports for business-as-usual &amp; regulatory
                        reporting
                      </li>
                      <li>Agile visualization development</li>
                      <li>Embedded BI</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Data;
