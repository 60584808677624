import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import microconsult from "../../Assests/microservices/micro-consult.jpg";
import microimple from "../../Assests/microservices/micro-imple.jpg";
import microtesting from "../../Assests/microservices/micro-testing.jpg";
import microsupport from "../../Assests/microservices/micro-support.jpg";
import microsolution from "../../Assests/microservices/micro-solution.jpg";
import microservicestrucure from "../../Assests/microservices/microservice-strucure.png";
import monoarch from "../../Assests/microservices/mono-arch.png";
import microarchtrans from "../../Assests/microservices/micro-arch-trans.png";
import microapi from "../../Assests/microservices/micro-api.png";
import microdevops from "../../Assests/microservices/micro-devops.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Microservices = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const consultingOptions = [
    {
      src: microconsult,
      alt: "Consulting",
      title: "Consulting",
    },
    {
      src: microimple,
      alt: "Implementation",
      title: "Implementation",
    },
    {
      src: microtesting,
      alt: "Testing",
      title: "Testing",
    },
    {
      src: microsupport,
      alt: "Support",
      title: "Support",
    },
  ];
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Microservices</title>

        <meta name="keywords" content="Microservices" />

        <meta name="description" content="Microservices" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="global-slide-lg"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/9178caea118bc944a0d37fe5366fa809.jpg')",
          }}
        >
          <div className="container">
            <h1>Microservices</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/solution">
                  Solutions
                </Link>
              </li>
              <li className="active">Microservices</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="deatil-page-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <section className="consulting-section cloud-computing-section">
                    <div>
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{ margin: "0 0 30px" }}
                        >
                          <div className="common-image-overflow">
                            <img
                              src={microsolution}
                              alt="Microservices solution"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="digital-consult-panel margin-zero">
                            <p>
                              Microservices help overcome these shortcomings of
                              monolithic architecture and provide a solution
                              that helps systems and applications be as modular
                              as possible. It comes with a pre-integrated
                              eco-system with architectural reference tools that
                              ensure best practices for NFR’s (Non-Functional
                              Requirements) like logging, monitoring, discovery,
                              security. Microservices reference architecture
                              results in a system that is scalable, agile, and
                              flexible. With our Microservices and API
                              solutions, companies can iterate quickly, simplify
                              deployment, and shorten the time-to-market.
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />

                      <h2>ProCorp’s Microservices Reference Architecture</h2>
                      <p>
                        Defines ownership boundaries to each system component
                        and provides the ability to adopt any framework for
                        different parts. It aims to increase agility by
                        decoupling system components and reducing the cost of
                        application scaling. By creating a reference
                        architecture, we have mastered the art of achieving the
                        transformation from monoliths to microservices.
                      </p>
                      <br />

                      <div className="common-image-overflow center-image">
                        <img
                          src={microservicestrucure}
                          alt="Microservices reference architecture"
                        />
                      </div>
                      <br />

                      <div className="row">
                        <div className="col-md-6">
                          <div className="arch-pattern-panel">
                            <h3>Traditional Monolithic Architecture Pattern</h3>
                            <img
                              src={monoarch}
                              alt="Monolithic architecture pattern"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="arch-pattern-panel">
                            <h3>Microservice Architecture Pattern</h3>
                            <img
                              src={microarchtrans}
                              alt="Microservice architecture pattern"
                            />
                          </div>
                        </div>
                      </div>

                      <br />

                      <p>
                        Microservices split each application into sets of
                        smaller, interconnected services, cutting the time it
                        takes an IT department to build, maintain and upgrade
                        each one. This gives any development team more
                        opportunities to customize those unique end-user
                        experiences, even while keeping to the tighter schedule
                        that Agile releases require. In the era of digital
                        transformation and disruption, the need to accelerate
                        implementation of new and shifting business requirements
                        is driving the need for rapid platform and application
                        development.
                      </p>
                      <br />

                      <div className="common-image-overflow center-image">
                        <img src={microapi} alt="Microservices API" />
                      </div>
                      <br />

                      <p>
                        In response, software development, cybersecurity, and
                        information technology (IT) operations have needed to
                        find more efficient ways of working together, known
                        today as “DevSecOps.” Rather than rebranding
                        long-standing processes into new buzzwords, the
                        DevSecOps model demands a fundamentally new approach to
                        address secure product development and deployment when
                        considering Microservices architecture.
                      </p>

                      <div className="common-services-panel-area transparent-bg-services">
                        <h2>What we do – Services Portfolio</h2>
                        <div className="digital-consulting-option microservice-dg-consult">
                          <div className="row">
                            {consultingOptions.map((option, index) => (
                              <div key={index} className="col-md-3">
                                <div className="dg-consult">
                                  <img src={option.src} alt={option.alt} />
                                  <h4>{option.title}</h4>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <br />
                        <p>
                          This digital asset is usable in any domain like
                          Banking, Finance, Supply Chain, Omnichannel Commerce,
                          Healthcare, Technology etc. Today’s end users expect
                          dynamic yet consistent experiences across a range of
                          devices. For this to happen, organizations of all
                          sizes are adopting a microservices architecture. We
                          build API management platforms to provide the
                          security, reliability, visibility, and adaptability
                          required to run a Microservices architecture
                          efficiently and effectively.
                        </p>

                        <h4>Our key differentiators are:</h4>
                        <ul>
                          <li>Microservices feasibility analysis</li>
                          <li>
                            Dedicated COE for Microservices &amp;
                            Containerization
                          </li>
                          <li>
                            Re-usable assets for faster implementation and
                            Services framework
                          </li>
                          <li>
                            Robust in-house frameworks to build custom
                            Microservices
                          </li>
                          <li>
                            Strong partner ecosystem and open-source expertise
                          </li>
                        </ul>
                      </div>

                      <div className="common-services-panel-area transparent-bg-services">
                        <h2>Transformation to DevSecOps</h2>
                        <div className="row">
                          <div
                            className="col-md-6"
                            style={{ margin: "0 0 30px" }}
                          >
                            <img src={microdevops} alt="DevSecOps" />
                          </div>
                          <div className="col-md-6">
                            <p>
                              Moving to a DevSecOps model doesn’t happen
                              overnight. Rather, it’s both a strategic and
                              continual improvement process aimed at delivering
                            </p>
                            <ul>
                              <li>
                                <strong>Continuous security:</strong> Embracing
                                a “secure by design” principle, leveraging
                                automated code scanning and automated
                                application security testing throughout the
                                development lifecycle and at a granular level
                                (e.g., in the integrated development environment
                                (IDE), on code submit to the repository, during
                                code build, test-driven security).
                              </li>
                              <li>
                                <strong>
                                  Increased efficiency and product quality:
                                </strong>{" "}
                                Security vulnerabilities are detected and
                                remediated as early as possible in the pipeline
                                when the cost to fix them is lower. This
                                increases the speed at which quality code can be
                                delivered.
                              </li>
                              <li>
                                <strong>Empowered teams:</strong> DevSecOps
                                enables autonomous and secure development teams
                                with streamlined processes.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Microservices;
