import React, { useEffect } from "react";

import cloudRoadmap from "../../Assests/cloud/cloud-roadmap.jpg";
import cloudTransformation from "../../Assests/cloud/cloud-transformation.jpg";
import cloudVision from "../../Assests/cloud/cloud-vision.jpg";
import valueAssessment from "../../Assests/cloud/value-assement.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Cloud = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  const consultingOptions = [
    {
      image: cloudVision,
      alt: "Cloud Vision",
      title: "Cloud Vision",
      description:
        "We assess the cloud maturity and readiness of your current application and infrastructure portfolio and align them with your core business objectives to design a viable way forward to cloud.",
    },
    {
      image: valueAssessment,
      alt: "Cloud Value Assessment",
      title: "Cloud Value Assessment",
      description:
        "We determine the costs and business benefits of the various stages of your cloud journey based on reference architectures and optimal technology standard prerequisites.",
    },
    {
      image: cloudRoadmap,
      alt: "Cloud Roadmap",
      title: "Cloud Roadmap",
      description:
        "We make suitable technology (SaaS, IaaS, PaaS) selections, and plot your journey to the desired end-state.",
    },
    {
      image: cloudTransformation,
      alt: "Cloud Transformation Plan",
      title: "Cloud Transformation Plan",
      description:
        "We examine the required capabilities matrix in addition to the related organizational changes and skills needed to derive maximum value from cloud and design an ideal cloud strategy for your unique business needs.",
    },
  ];
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Cloud Services</title>

        <meta name="keywords" content="Cloud Services" />

        <meta name="description" content="Cloud Services" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="top-global-slide"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/af2ecd48a04ba347c12cd2ad2e13086b.jpg')",
          }}
        >
          <div className="container">
            <h1>Cloud</h1>

            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>

              <li>
                <Link to="/services">
                  Services
                </Link>
              </li>

              <li className="active">Cloud</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="consulting-section cloud-computing-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="cloud-computing"></div>
                </div>
                <div className="col-md-6">
                  <div className="digital-consult-panel">
                    <p>
                      The advent of the cloud has democratized computing by
                      opening opportunities to set up enterprise-class services,
                      even for individuals and small businesses. Cloud computing
                      offers access to data and applications from anywhere with
                      internet connectivity and slashes IT infrastructural costs
                      tremendously.
                    </p>
                  </div>
                </div>
              </div>

              <h2>What We Do - Services Portfolio</h2>

              <p>
                Whether you are beginning to or have already started your cloud
                journey, a vendor-agnostic partner can accelerate your company’s
                cloud transformation and empower your business to stay ahead of
                the competition. Explore our cloud offerings.
              </p>

              <p>
                Through our global Engineering delivery, we train our engineers
                at all levels on the newest technologies and implementation
                frameworks. With our Global Delivery engineering centers, we
                deploy the best teams for your business from anywhere in the
                world and deliver speed and quality at scale.
              </p>

              <div className="row">
                <div className="col-md-6">
                  <div className="digital-consult-panel">
                    <p>
                      The advent of the cloud has democratized computing by
                      opening opportunities to set up enterprise-class services,
                      even for individuals and small businesses. Cloud computing
                      offers access to data and applications from anywhere with
                      internet connectivity and slashes IT infrastructural costs
                      tremendously.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="cloud-computing"></div>
                </div>
              </div>

              <div className="common-services-panel-area">
                <h2>Cloud Strategy</h2>

                <p>
                  A robust cloud strategy tailored to your business objectives
                  can be the difference between creating a sustainable
                  competitive edge in a fast-paced digital world and risking
                  wasted investment.
                </p>

                <p>
                  Our hyper-focused cloud consulting services accelerate digital
                  transformation with effective:
                </p>

                <div className="digital-consulting-option cloud-digital-consulting-option new-cloudvision-transformation">
                  <div className="row">
                    {consultingOptions.map((option, index) => (
                      <div className="col-md-3" key={index}>
                        <div className="dg-consult">
                          <img src={option.image} alt={option.alt} />
                          <h3>{option.title}</h3>
                          <p>{option.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area">
                <h2>Application Portfolio Rationalization (APR)</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="apr-bg"></div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Moving IT infrastructure and applications into cloud poses
                      essential questions for businesses about the performance
                      of their existing software in the new environment.
                      Software refactoring for cloud, essentially, is making
                      your applications cloud-ready to ensure a seamless
                      migration.
                    </p>
                    <h4>What We Do - Services Portfolio</h4>
                    <p>
                      We take a deep dive into your digital ecosystem,
                      infrastructure, and application workload, and offer
                      relevant comprehensive code refactoring services
                      including:
                    </p>
                    <ul>
                      <li>Application Re-hosting</li>
                      <li>Application Re-engineering</li>
                      <li>Application Refactoring and Re-platforming</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area transparent-bg-services">
                <h2>Cloud Migration</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      We drive faster time-to-value with our near-zero risk
                      cloud migration offerings. Leveraging a suite of
                      industry-proven approach, tools, and accelerators, we
                      align your cloud ambitions with seamless result-oriented
                      digital transformations.
                    </p>
                    <h4>Our cloud migration services include: </h4>
                    <ul>
                      <li>Infrastructure Migration</li>
                      <li>Data Migration</li>
                      <li>Platform Migration</li>
                      <li>Application Migration</li>
                    </ul>
                    <p>
                      Drive business agility and reduce operational costs with
                      on-premise to cloud migration of your IT infra assets.
                      Take an accurate, predictable, and accelerated route for
                      complex migration needs.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="cloud-migration"></div>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area mrtp-50">
                <h2>Cloud Engineering</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="cloud-eng-bg"></div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Our Agile cloud development services allow enterprises to
                      build and deploy future-secure cloud-enabled applications
                      quickly faster than ever. Attain improved scalability by
                      leveraging our microservices, DevOps, PaaS, and
                      containerization technologies.
                    </p>
                    <p>
                      We draw from our deep proficiency in combining the
                      strengths of private, public, and hybrid clouds to engage
                      in both enterprise-level cloud development aimed to
                      accelerate your digital transformation journey and
                      specialized cloud deployments that aid specific business
                      objectives. The result
                    </p>
                    <ul>
                      <li>Effective cloud-native applications that</li>
                      <li>Platform-agnostic</li>
                      <li>Robust, resilient, and secure.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="common-services-panel-area transparent-bg-services">
                <h2>Cloud Monitoring</h2>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      Early detection of anomalies, atypical patterns and issues
                      in your cloud-based assets can help take proactive
                      measures to improve the reliability and performance of
                      your cloud infrastructure. Consistent monitoring helps
                      reduce the manual work dedicated to ensuring that the
                      application is running as expected and making your MTTR
                      more efficient.
                    </p>
                    <h4>
                      Our systematic cloud monitoring capabilities include:
                    </h4>
                    <ul>
                      <li>Application Performance Monitoring</li>
                      <li>Infrastructure Monitoring</li>
                      <li>Network Monitoring</li>
                      <li>Database Monitoring</li>
                    </ul>
                    <p>
                      Our team of cloud experts works collaboratively,
                      standardize artifacts, optimize performance, and increase
                      availability.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <div class="cloud-mont"></div>
                  </div>
                </div>
              </div>
              <div class="common-services-panel-area mrtp-50">
                <h2>DevOps</h2>
                <div class="row">
                  <div class="col-md-6">
                    <div class="devops-cloud-bg"></div>
                  </div>
                  <div class="col-md-6">
                    <p>
                      In a time, when continuous evolution is the only way to
                      stay ahead of the curve, DevOps can bring in immense value
                      by streamlining IT through synergizing your business,
                      development, and operation processes.
                    </p>
                    <p>
                      As a digital-first organization, we offer valuable
                      differentiators to help your organization derive maximum
                      results from your digital assets. DevOps can help make
                      simple processes dynamic and help in maximizing
                      operational efficiency and predictability, while improving
                      security. Our DevOps services and solutions include:
                    </p>
                    <ul>
                      <li>DevOps Consulting &amp; Advisory Services</li>
                      <li>Configuration Management</li>
                      <li>Automated Provisioning</li>
                      <li>
                        Continuous Integration, Automation &amp; Monitoring
                      </li>
                      <li>Containerization &amp; Container Orchestration</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Cloud;
