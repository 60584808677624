import React, { useEffect } from "react";
import "../../css/AboutSection.css";
import AOS from "aos";
import { Helmet } from "react-helmet";

// Images
import formalman from "../../Assests/formalman.jpg";
import intelcore from "../../Assests/inteltech.jpg";
import diversityimg from "../../Assests/diversity-img-caption.jpg";
import adapttaion from "../../Assests/day-imgcaption.jpg";
import ethics from "../../Assests/ethics-img-caption.jpg";
import new_what_we from "../../Assests/new-what-we.jpg";
import teamsthumbsup from "../../Assests/teamsthumbsup.jpg";
import miss01 from "../../Assests/miss-icon-001.png";
import miss02 from "../../Assests/miss-icon-002.png";
import miss03 from "../../Assests/miss-icon-003.png";
import TalktoUs from "../TalktoUs";
import { Link } from "react-router-dom";

const AboutSection = () => {


  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>
          Meet best industries techies providing all types of digital services
          and solutions at Procorp Systems
        </title>

        <meta
          name="keywords"
          content="Meet Expert Digital Transformation, Microservices and all types of Digital Services and Solutions Provider "
        />

        <meta
          name="description"
          content="Meet Expert Digital Transformation, Microservices and all types of Digital Services and Solutions Provider "
        />
      </Helmet>

      <section class="global-web-page">
        <section class="top-global-slide">
          <div class="container">
            <h1>About</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li class="active">About</li>
            </ul>
          </div>
        </section>
        <section class="gray-shade-bg">
          <section class="about-section">
            <div class="container">
              <br />
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="image-overflow"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <img src={formalman} />
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="about-content-panel"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <p>
                      At ProCorp Systems, we’re obsessed with technology. Its
                      power to change everything. Technology fuels our passion
                      and commitment to helping organizations do what they set
                      out to. When we engage, we bring fresh ideas that help you
                      galvanize your performance. Refine your strategy. Spark
                      new energy. Delivering breakthrough solutions through
                      working arrangements that are analogous to distributed
                      processing requires a grasp of how virtual teams can
                      coalesce around a project and work together across time,
                      space and organizational boundaries.
                    </p>
                    <p>
                      The future—and how we get there—depends on those who
                      build, connect, create and transform our world. The most
                      successful and innovative businesses are already doing it,
                      and we’re skilled experts at bringing in the teams and
                      support needed to thrive. Our ability to hire, inspire and
                      retain the cream of the world’s software talent gives us
                      an unassailable advantage when it comes to providing both
                      remote and onsite software development and support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <a name="wwr"></a>
          <section class="who-we-are-section" id="who-we-are">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Who We Are
              </h2>
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="who-we-paragraph"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <p>
                      We deliver business and technology transformation from
                      start to finish, leveraging agile methodologies, proven
                      customer collaboration frameworks, engineering excellence
                      tools, hybrid teams and our award-winning proprietary
                      global delivery platform. Our customers are central to our
                      mission. We are a specialized outsourcing solutions
                      provider and committed to providing highly efficient
                      employees to various corporate and opening up
                      opportunities for both by providing the highest quality of
                      outsources employment and human resource services. We
                      understand that success is important to both our clients
                      and our associates.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="image-overflow"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <img src={intelcore} />
                  </div>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-4">
                  <div
                    class="who-panel-options text-center"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div class="image-overflow">
                      <img src={ethics} />
                    </div>
                    <h3>Ethics in action</h3>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="who-panel-options text-center"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <div class="image-overflow">
                      <img src={diversityimg} />
                    </div>
                    <h3>A diversity of voices</h3>
                  </div>
                </div>
                <div class="col-md-4">
                  <div
                    class="who-panel-options text-center"
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    <div class="image-overflow">
                      <img src={adapttaion} />
                    </div>
                    <h3>Adaptability from day one</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <a name="wwd"></a>
          <section class="what-we-do-section" id="what-we-do">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                What We Do
              </h2>

              <div class="row">
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
                  <div class="division-italic">
                    We orchestrate each engagement to help you scale your
                    business solutions to meet the growing demands of your
                    customers and to reach across geographies, time zones and
                    functions.
                  </div>

                  <ul>
                    <li>
                      As your trusted partner, we measure our success based on
                      your results. We leverage the latest technologies, tools
                      and frameworks for collaboration to ensure that our
                      engagements are transparent and instantly traceable from
                      anywhere in the world.
                    </li>
                    <li>
                      Developing a digital experience or product from end to end
                      requires input and expertise from a variety of roles. Our
                      multidisciplinary teams unite to deliver well-rounded
                      technology solutions that move your business goals
                      forward.
                    </li>

                    <li>
                      Through our global Engineering delivery, we train our
                      engineers at all levels on the newest technologies and
                      implementation frameworks. With our Global Delivery
                      engineering centers, we deploy the best teams for your
                      business from anywhere in the world and deliver speed and
                      quality at scale.
                    </li>
                  </ul>
                </div>
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="700">
                  <div class="image-overflow">
                    <img src={new_what_we} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="working-vision-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Our Working Vision
              </h2>
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="working-vision-section-bg"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  ></div>
                </div>
                <div class="col-md-6">
                  <div
                    class="working-vision-content-panel"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <p>
                      We treat our business partners as if they were our clients
                      and value our alliances with them. We go to extraordinary
                      lengths to ensure mutual success - without keeping score -
                      and expect the same in return from the people we choose to
                      do business with.We believe serving clients is a privilege
                      and accept the responsibility to do it well. We demand
                      unquestioned integrity and veracity from all our people in
                      all situations. Our Team consistently demonstrates
                      superior performance while balancing creativity and
                      passion with professionalism and pragmatism
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="what-we-second-section">
            <div class="container">
              <div class="staff-overflow">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  Our Core Values
                </h2>
                <p>
                  We believe innovation combined with action can change the
                  world. We value different ways of thinking and problem solving
                  and expect lively debate and dissent from all levels. The
                  diversity of our teams brings fresh perspective and insight to
                  our work.
                </p>
                <p>
                  We invest in the long-term success of our individual clients,
                  not only the companies they work for. We celebrate client
                  promotions and view them as a measure of our value. As trusted
                  advisors, the spotlight is reserved for clients only.
                </p>
                <div
                  class="core-imgae-overflow"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <img src={teamsthumbsup} alt="team" />
                </div>
              </div>
              <div class="services-option">
                <div class="row">
                  <div class="col-md-4">
                    <div
                      class="services-option-panel"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <h4>Customer-first Philosophy</h4>
                      <p>
                        We are proactive, collaborative and committed to success
                        for our customers.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div
                      class="services-option-panel"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <h4>Adaptable</h4>
                      <p>
                        We are an accountable, disciplined, high-performing
                        company with proven results
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div
                      class="services-option-panel"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <h4>Agile & Nimble</h4>
                      <p>
                        We adopt new technologies and evolve services to meet
                        customers where they are in their journey.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div
                      class="services-option-panel"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <h4>Compassion</h4>
                      <p>
                        We’re one team doing the right thing for our customers,
                        communities and each other.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div
                      class="services-option-panel"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <h4>Committed Support</h4>
                      <p>
                        We strongly believe in long-term commitment for our
                        customers, communities and each other.
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div
                      class="services-option-panel"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <h4>Customer Satisfaction</h4>
                      <p>
                        We are passionate learners who are embedded in our
                        customers’ businesses to provide unbiased solutions that
                        meet business demands.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <a name="oms"></a>
          <section class="our-mission-vision" id="our-mission">
            <h2 data-aos="fade-up" data-aos-delay="300">
              Our Mission
            </h2>
          </section>

          <section class="mission-vision-section">
            <div class="container">
              <div class="mi-vi-section">
                <div class="row">
                  <div class="col-md-4">
                    <div
                      class="mi-vi-panel"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div class="icon-overflow">
                        <img src={miss01} alt="miss-icon" />
                      </div>
                      <h4>Mission</h4>
                      <p>
                        We can help you reimagine your business through a
                        digital lens. Our software engineering heritage combined
                        with our strategic business and innovation consulting,
                        design thinking, and physical-digital capabilities
                        provide real business value to our customers through
                        human-centric innovation.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div
                      class="mi-vi-panel"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <div class="icon-overflow">
                        <img src={miss02} alt="miss-icon" />
                      </div>
                      <h4>Vision</h4>
                      <p>
                        We want to emerge as a major player to help reimagine
                        your business through a digital lens. Our commitment to
                        our customers, employees, and society is to act with
                        integrity and as a trusted partner. This guides
                        everything we do—the way we serve our clients and the
                        work we do to help them build better businesses.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div
                      class="mi-vi-panel"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <div class="icon-overflow">
                        <img src={miss03} alt="miss-icon" />
                      </div>
                      <h4>Goals</h4>
                      <p>
                        In a highly competitive industry, everyone at ProCorp
                        recognizes that it is our reputation, the behavior of
                        our people and the quality of our work that determines
                        whether customers decide to work with us—and stay with
                        us. We do not cut corners, bend the rules or look for
                        shortcuts. We go above and beyond the required standards
                        and aggressively challenge ourselves to maintain a level
                        of compliance that is the best of any employer in our
                        industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="working-vision-section innovation-section about-innovation-system">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Innovative Technology and Systems
              </h2>
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="working-vision-content-panel"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <p>
                      Our founders, tired of doing things the old way, started a
                      firm to radically alter the consulting landscape with a
                      mission to deliver on the promise of innovation.
                    </p>
                    <p>
                      We have lived and breathed innovation since, expanding our
                      services to meet our clients` evolving needs along the
                      way.
                    </p>
                    <p>
                      Despite the recent digital disruption, our clients’
                      challenge is still the same: to develop products that
                      improve people`s lives and deliver business results. This
                      allows us to remain who we’ve always been at the heart –
                      with a growing set of capabilities to deliver on our
                      mission.
                    </p>
                    <p>
                      By better understanding peoples’ expectations and applying
                      innovative technology and systems, we help companies
                      transform how they engage with the right people, through
                      the best channel, and at the right time.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="about-innovation-sustem-bg"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  ></div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>

  
    </>
  );
};

export default AboutSection;
