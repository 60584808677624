import React, { useEffect, useState } from "react";
import "../css/GlobalRelation.css";
import globalImage from "../Assests/global-locations.png";
import Modal from "react-modal";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const GlobalRelation = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [mapSrc, setMapSrc] = useState("");

  const handleViewMap = (e, location) => {
    e.preventDefault();
    const googleMapUrl = `https://maps.google.com/maps?width=100%25&height=400&hl=en&q=${encodeURIComponent(
      location
    )}&t=&z=14&ie=UTF8&iwloc=B&output=embed`;
    setMapSrc(googleMapUrl);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setMapSrc(""); // Clear the map source when closing
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta     
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Procorp Systems Global Offices Worldwide</title>

        <meta
          name="keywords"
          content="Procorp Systems Global Offices Worldwide"
        />

        <meta
          name="description"
          content="Procorp Systems Global Offices Worldwide."
        />
      </Helmet>

      <section className="global-web-page">
        <section className="top-global-slide">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-sm-7">
                <h1>Global Locations</h1>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Global Locations</li>
                </ul>
              </div>
              <div className="col-md-5 col-sm-5">
                <div className="global-worl-map">
                  <img
                    src={globalImage}
                    //  alt="global-locations"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="global-location-section">
          <div class="container">
            <h1>Global Locations</h1>
            <div class="country-heading-section">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <h2>North America</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="country-background">
            <div class="container">
              <div class="row">
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <div class="location-hq-office clearfix">
                      <div class="office-colm">
                        <h3>Plano, TX</h3>
                      </div>
                      <div class="head-quater-colm">Corporate HQ</div>
                    </div>
                    <p>
                      2222 W Spring Creek Pkwy, STE 202, <br />
                      Plano, TX 75023
                    </p>
                    <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          " 1390 Market Street, Suite 200,  San Francisco, CA 94102"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>San Francisco, CA</h3>
                    <p>
                      1390 Market Street, Suite 200, <br />
                      San Francisco, CA 94102
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          " 1390 Market Street, Suite 200,  San Francisco, CA 94102"
                        )
                      }
                    >
                      View Map
                    </Link>{" "}
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Arizona, Phoenix</h3>
                    <p>
                      Two North Central Ave, 18th and 19th Floor, <br />
                      Phoenix, Arizona 85004
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>{" "}
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Denver, CO</h3>
                    <p>
                      1600 Broadway, Suite 1600, <br />
                      Denver, CO 80202
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>{" "}
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Minneapolis, MN</h3>
                    <p>
                      2355 Highway 36 West, Suite 400, <br />
                      Roseville, MN 55113
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>{" "}
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Charlotte, NC</h3>
                    <p>
                      101 N. Tryon St., Suite 112 and 6000, <br />
                      Charlotte, North Carolina
                    </p>
                   <Link to="#"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Toronto, ON, Canada</h3>
                    <p>
                      439 University Avenue, 5th Floor, <br />
                      Toronto, ON M5G 1Y8
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="country-heading-section">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <h2>Europe</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="country-background country-background-transparent">
            <div class="container">
              <div class="row">
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>London, UK</h3>
                    <p>
                      18 Soho Square, <br />
                      London, W1D 3QL
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>{" "}
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Brussels, Belgium</h3>
                    <p>
                      Square de Meeûs 37, <br />
                      Brussels, 1000
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Eindhoven, Netherlands</h3>
                    <p>
                      Stationsplein 91 & 105, 1ste & 4de Verdieping, <br />
                      Den Bosch, 5211 BM
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Madrid, Spain</h3>
                    <p>
                      Calle de Méndez Álvaro, 20, <br />
                      Madrid, 28045
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="country-heading-section">
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <h2>Asia</h2>
                </div>
              </div>
            </div>
          </div>

          <div class="country-background">
            <div class="container">
              <div class="row">
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Hyderabad, India</h3>
                    <p>
                      1-10-39 to 44 , Level 4, Gumidelli Towers, <br />
                      Hyderabad, Telangana 500016
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Bangalore, India</h3>
                    <p>
                      26-27, Mahatma Gandhi Road, Level 9, <br />
                      Raheja Towers, Bangalore.
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Gurgaon, Haryana</h3>
                    <p>
                      Sohna Road, Level 9, Spaze i-Tech Park, A1 Tower, <br />
                      Sector 49, Gurgaon
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Manila, Philippines</h3>
                    <p>
                      Makati Avenue / Paseo De Roxas Avenue, <br />
                      20th Floor, Zuellig Building
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4">
                  <div class="country-location-address">
                    <h3>Paya Lebar, Singapore</h3>
                    <p>
                      1 Paya Lebar Link, <br />
                      Singapore, 408533
                    </p>
                   <Link to="#"
                      className="view-map-location"
                      onClick={(e) =>
                        handleViewMap(
                          e,
                          "2222 W Spring Creek Pkwy, STE 202, Plano, TX 75023"
                        )
                      }
                    >
                      View Map
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Location Map"
        className="modal-content"
        overlayClassName="map-modal-overlay"
      >
        <div className="modal-dialog">
          <div className="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span onClick={closeModal} aria-hidden="true">
                &times;
              </span>
            </button>
            <h4 className="modal-title" id="modalLabel">
              Procorp Systems
            </h4>
          </div>
          <div className="map-container">
            <iframe
              title="Location Map"
              width="100%"
              height="400"
              src={mapSrc}
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
          <button onClick={closeModal} className="close-button">
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default GlobalRelation;
