import React, { useEffect } from "react";
import "../../css/Banking.css";

import bankImg1 from "../../Assests/bank-img1.jpg";
import bankImg2 from "../../Assests/bank-img2.jpg";
import bankImg3 from "../../Assests/bank-img3.jpg";
import bankImg4 from "../../Assests/bank-img4.jpg";
import bankImg5 from "../../Assests/bank-img5.jpg";
import bankImg6 from "../../Assests/bank-img6.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Banking = () => {

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Banking Finance</title>

        <meta name="keywords" content="Banking Finance" />

        <meta name="description" content="Banking Finance" />
      </Helmet>
      <div>
        <section class="global-web-page">
          <section class="top-global-slide">
            <div class="container">
              <h1>Banking &amp; Financial Services</h1>

              <ul>
                <li>
                  <Link to="/">Home</Link >
                </li>

                <li>
                  <Link to="resources">
                    Resources
                  </Link >
                </li>

                <li class="active">Banking &amp; Financial Services</li>
              </ul>
            </div>
          </section>

          <section class="gray-shade-bg">
            <section class="deatil-page-section">
              <div class="deatil-top">
                <div class="container">
                  <h2>Banking and Financial Services</h2>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    {/* <h4>Banking & Financial Services</h4>  */}
                    <p>
                      The Banking and Financial Services industry is witnessing
                      a rapidly changing landscape, the rise of fintech
                      companies being the catalyst. Traditional banking
                      products, services, and channels are losing relevance as
                      customers, increasingly influenced by technological
                      advancements in other spheres and social media, have
                      started demanding better customer experience and
                      convergence in financial services.
                    </p>

                    <p>
                      Changing regulatory environment and macroeconomic
                      indicators have also contributed to make this a
                      never-before opportunity for banks and financial
                      institutions to embrace and leverage digitization.
                      Partnership with fintech companies can help banks and
                      financial services organizations harness digital
                      technologies, advanced analytics, and personalization for
                      cutting-edge solutions that improve customer experience.
                    </p>

                    <h4>
                      Get the advantage of procorp Solutions’ core technological
                      strengths to power your business and race ahead of your
                      competition
                    </h4>

                    <div class="detail-block-list">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={bankImg1} alt="bank-fn-ad-001" />
                            </div>
                            <h3>
                              Customized online banking portal and mobile
                              banking app
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={bankImg2} alt="bank-fn-ad-002" />
                            </div>
                            <h3>
                              Asset management application for individuals and
                              institutions
                            </h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={bankImg3} alt="bank-fn-ad-003" />
                            </div>
                            <h3>Social Media Banking</h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={bankImg4} alt="bank-fn-ad-004" />
                            </div>
                            <h3>Custom Application for Credit Scoring</h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={bankImg5} alt="bank-fn-ad-005" />
                            </div>
                            <h3>Mobile Wallets for Credit Scoring</h3>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="detail-block-list-panel">
                            <div class="img-overflow">
                              <img src={bankImg6} alt="bank-fn-ad-006" />
                            </div>
                            <h3>Risk management and compliance programs</h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3>Unleash Innovation Across the Value Stream</h3>

                    <h4>Wealth and Asset Management</h4>

                    <p>
                      The wealth and asset management industry is facing
                      increased regulatory supervision and new competition from
                      rising fintech companies and their innovative services.
                      Multiple challenges such as weak growth in developed
                      economies and robust growth in developing markets have
                      contributed to greater volatility. Investment management
                      firms that execute plans that help them anticipate
                      demographic shifts, improve efficiency and decision making
                      with technology, and keep pace with regulatory changes
                      will likely find themselves ahead of the competition.
                    </p>

                    <p>
                      The rapidly changing environment demands seamless digital
                      channels of communication and access to portfolio
                      management, driving firms to digital transformation.
                      Increased focus on cybersecurity, application of
                      artificial intelligence (AI) and analytics are some of the
                      emerging trends.
                    </p>

                    <h5>How can we help?</h5>

                    <ul>
                      <li>
                        We provide insights and analysis or investment selection
                        using big data analytics and AI.
                      </li>
                      <li>
                        We improve cost-effectiveness by replacing expensive
                        human analyst resources with scalable technology.
                      </li>
                      <li>
                        We help make AI-supported investment decisions and asset
                        allocation algorithms.
                      </li>
                      <li>
                        We help streamline front and back-office functions and
                        automate critical tasks.
                      </li>
                      <li>
                        We help reduce manual intervention in tasks such as
                        client onboarding and regulatory compliance.
                      </li>
                    </ul>

                    <h4>Banking</h4>

                    <p>
                      The last decade saw the adoption of core banking solutions
                      by banks to make their service faster, smoother and cater
                      to a larger client base. Recent years have witnessed
                      stupendous changes in traditional banking products and
                      channels largely driven by customers. Internet and mobile
                      banking have seen rapid adoption resulting in fewer
                      customers queueing up at branches to open accounts or seek
                      loans. Service levels are no longer the differentiating
                      factor.
                    </p>

                    <p>
                      The optimal customer experience at every touchpoint, that
                      is efficient, convenient, personalized and streamlined is
                      what sets apart financial institutions. Banks and
                      financial institutions are faced with a stiff challenge of
                      reinventing themselves to address the pain points of
                      customers and ensuring a delightful experience every step
                      of the way.
                    </p>

                    <h5>How can we help?</h5>

                    <ul>
                      <li>
                        Cutting edge technology for customized internet banking
                        interface and mobile banking applications for a rich
                        user experience
                      </li>
                      <li>
                        The convergence of banking interfaces using IoT
                        application
                      </li>
                      <li>
                        Greater personalization and channel optimization through
                        Big Data, Advanced Analytics, and Business Intelligence
                      </li>
                      <li>
                        Use of Predictive Analytics to optimize customer
                        engagement.
                      </li>
                    </ul>

                    <h4>Payments</h4>

                    <p>
                      The global payments industry is undergoing a paradigm
                      shift with an influx of technology, demographics, and
                      regulatory dynamics. While the customer-facing part of the
                      value chain continues to witness elevated levels of
                      innovation, service providers are still grappling with
                      back-end infrastructure enhancements.
                    </p>

                    <p>
                      Trends such as new opportunities in the payments industry
                      in terms of adoption of Open Application Programming
                      Interfaces (APIs), growth in digital payments, innovation
                      in cross-border payments, and challenges from the entry of
                      alternative service providers are impacting the industry
                      in terms of fostering competition, nurturing innovation,
                      and enhancing process and system-related efficiencies.
                    </p>

                    <h5>How can we help?</h5>

                    <ul>
                      <li>
                        Real-time or instant payment solutions to power your
                        business
                      </li>
                      <li>Digital cross border remittances</li>
                      <li>Enhanced security and authentication services</li>
                      <li>
                        Frictionless customer experience in e-commerce and
                        m-commerce
                      </li>
                      <li>
                        Credit scoring and loans to the unbanked based on the
                        transaction history in loans, card payments, risk
                        category, and other parameters..
                      </li>
                    </ul>

                    <h4>Compliance</h4>

                    <p>
                      The world of ethics, risk management, and compliance saw
                      many upheavals in 2016 which has reinforced the need to
                      move away from a siloed approach to risk management to an
                      integrated and consistent enterprise-wide framework. The
                      role of a compliance function is continuing to change as
                      well. Along with complying with standards & regulations,
                      compliance professionals are increasingly responsible for
                      creating and nurturing strong and ethical corporate
                      cultures and protecting organizations from financial,
                      legal and reputational risk
                    </p>

                    <p>
                      Emphasis has been placed on regulatory filings and
                      reporting, enhanced due diligence and surveillances to
                      mitigate cyber and data-related risks. With around the
                      clock news and social media’s speed and uncontrollability,
                      organizations are at the edge of a compliance failure.
                    </p>

                    <h5>How can we help?</h5>

                    <ul class="help-list">
                      <li>
                        Enterprise risk management solution that provides
                        dynamic, timely and ongoing insights into risks that
                        matter
                      </li>
                      <li>
                        Real-time analysis of transaction data for immediate
                        notification of risk
                      </li>
                      <li>
                        Monitoring and surveillance of written and oral
                        communication to identify potential breaches
                      </li>
                      <li>
                        Social media analytics to prevent information breach
                      </li>
                      <li>
                        Use of Analytics and <b>Big data</b> to assimilate and
                        analyze data from multiple internal and external sources
                        and predict exposure and risk.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};

export default Banking;
