import React, { useEffect, useRef, useState } from "react";
// import "../pages/custom.css";
import "aos/dist/aos.css";
import AOS from "aos";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
// import "../css/homepage.css";
import headerImg from "../Assests/45413660edc93d2ed6cba13eabd61ddb.png";
import heder1image from "../Assests/heder1image.png";
import hederImage2 from "../Assests/hederImage2.png";
import solutionicon1 from "../Assests/solution-icon-001.png";
import solutionicon2 from "../Assests/solution-icon-002.png";
import solutionicon3 from "../Assests/solution-icon-003.png";
import solutionicon4 from "../Assests/solution-icon-004.png";
import solutionicon5 from "../Assests/solution-icon-005.png";
import solutionicon6 from "../Assests/solution-icon-006.png";
import solutionicon7 from "../Assests/solution-icon-007.png";
import newsolutionicon1 from "../Assests/new-solution-icon-001.png";
import newsolutionicon2 from "../Assests/new-solution-icon-002.png";
import newsolutionicon3 from "../Assests/new-solution-icon-003.png";
import newsolutionicon5 from "../Assests/new-solution-icon-005.png";
import newsolutionicon7 from "../Assests/new-solution-icon-007.png";
import newsolutionicon8 from "../Assests/new-solution-icon-008.png";
import solicon1 from "../Assests/sol-icon-001.png";
import solicon2 from "../Assests/sol-icon-002.png";
import solicon3 from "../Assests/sol-icon-003.png";
import solicon4 from "../Assests/sol-icon-004.png";
import solicon5 from "../Assests/sol-icon-005.png";
import solicon6 from "../Assests/sol-icon-006.png";

import key from "../Assests/key.png";
import cloud from "../Assests/cloud.png";
import location from "../Assests/locaton-globe.png";
import { Link, useNavigate } from "react-router-dom";

const slides = [headerImg, heder1image, hederImage2];
const HomePage = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  useEffect(() => {
    AOS.init();
  }, []);
  const slides = [headerImg, heder1image, hederImage2];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const goToSlide = (index) => {
    setActiveIndex(index);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };
  const servicesData = [
    {
      title: "Digital Transformation",
      description:
        "Digital Transformations is more than just changing the underlying technology. As technology evolves, connected...",
      imgSrc: newsolutionicon1,
      link: "/resources/digital-transformations",
    },
    {
      title: "Microservices",
      description:
        "Microservices help overcome these  of monolithic architecture and provide a solution that helps systems...",
      imgSrc: newsolutionicon2,
      link: "/solution/microservices",
    },
    {
      title: "API Economy",
      description:
        "The API economy refers to the set of business models and practices designed around the use of APIs...",
      imgSrc: newsolutionicon3,
      link: "/solution/api-economy",
    },
    {
      title: "Open Banking",
      description:
        "New technologies and business models are redefining bank operating models and how banks interact with customers...",
      imgSrc: newsolutionicon5,
      link: "/solution/openbanking",
    },
    {
      title: "Payments",
      description:
        "The global cards and payments industry is witnessing a diminishing trend in traditional banking...",
      imgSrc: newsolutionicon7,
      link: "/solution/payments",
    },
    {
      title: "Cloud",
      description:
        "The advent of the cloud has democratized computing by opening opportunities to set up enterprise-class services...",
      imgSrc: newsolutionicon8,
      link: "/services/cloud",
    },
  ];

  const solutions = [
    {
      title: "Retail",
      icon: solicon1,
      description:
        "Every aspect of CPG and Retail companies are undergoing tremendous pressure as digital tools and changing...",
      link: "/resources/retail",
      panelClass: "solution-e-com",
    },
    {
      title: "CRM",
      icon: solicon2,
      description:
        "CRM combination of business strategies, software and processes build long-lasting relationships companies and their customers...",
      link: "/resources/crm",
      panelClass: "solution-marketing",
    },
    {
      title: "Banking and Financial Services",
      icon: solicon3,
      description:
        "The Banking and Financial Services industry is witnessing a rapidly changing landscape, the rise of fintech companies..",
      link: "/resources/banking-finance",
      panelClass: "solution-sales",
    },
    {
      title: "Supply Chain Management",
      icon: solicon4,
      description:
        "Globally, the Logistics and Transportation industry is evolving into a lean and agile industry powered by the advancements...",
      link: "/resources/supply-chain-management",
      panelClass: "solution-services",
    },
    {
      title: "Healthcare and Life Sciences",
      icon: solicon5,
      description:
        "The healthcare industry is undergoing a significant technology transformation. The focus of the business has been shifting...",
      link: "/resources/healthcare-and-life-sciences",
      panelClass: "health-medical",
    },
    {
      title: "Energy & Utilities",
      icon: solicon6,
      description:
        "The continuously changing economic scenario, M&A activities, privatisation, deregulation and growing competition is leading...",
      link: "/resources/energy-and-utilities",
      panelClass: "energy-utilities",
    },
  ];

  const solutionIcons = [
    solutionicon1,
    solutionicon2,
    solutionicon3,
    solutionicon4,
    solutionicon5,
    solutionicon6,
    solutionicon7,
  ];
  const searchFields = [
    {
      name: "keyword",
      placeholder: "Job search by keyword",
      icon: key,
      animation: "fade-left",
    },
    {
      name: "skill",
      placeholder: "Job search by Skill",
      icon: cloud,
      animation: "fade-down",
    },
    {
      name: "location",
      placeholder: "Job search by Locations",
      icon: location,
      animation: "fade-right",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/careers", { state: formData });
  };

  return (
    <div>
      <section className="slider-boot">
        <div
          id="carouselExampleGeneric"
          className="carousel slide"
          data-interval="false"
        >
          <div className="carousel-inner" role="listbox">
            <div className="wrapper-caption">
              <h2>Accelerate your digital journey</h2>
              <Link to="#"
                data-aos="fade-up"
                data-aos-delay="800"
                data-toggle="modal"
                data-target="#wizard-assessment"
                onClick={(e) => {
                  e.preventDefault();
                  goToSlide(1);
                }}
              >
                Get Started
              </Link>
            </div>
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{ clickable: true }}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
              loop={true}
              className="mySwiper"
              style={{
                position: "relative",
                width: "100%",
                height: "500px",
              }}
            >
              {slides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={slide}
                    alt={`Slide ${index}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </SwiperSlide>
              ))}

              {/* Custom Navigation Arrows */}
              <div
                className="swiper-button-prev"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  zIndex: "10",
                  display: "block", // Ensure custom prev arrow is visible
                }}
              >
                <span
                  className="glyphicon glyphicon-chevron-left"
                  aria-hidden="true"
                  style={{ color: "#fff", fontSize: "30px" }}
                ></span>
              </div>
              <div
                className="swiper-button-next"
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  zIndex: "10",
                  display: "block", // Ensure custom next arrow is visible
                }}
              >
                <span
                  className="glyphicon glyphicon-chevron-right"
                  aria-hidden="true"
                  style={{ color: "#fff", fontSize: "30px" }}
                ></span>
              </div>
            </Swiper>
          </div>
          {/*  */}
        </div>
      </section>

      {/* second sections */}

      <section className="services-section">
        <div className="common-heading-panel">
          <h2 data-aos="fade-up" data-aos-delay="300">
            Explore Our Next-Gen Engineering Solutions
          </h2>
        </div>
        <div className="services-sg-panel solutions-background-image">
          <div className="container">
            <div className="row">
              {servicesData.map((service, index) => (
                <div className="col-md-4 col-sm-4" key={index}>
                  <div
                    className="services-panel-area"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="icon-overflow">
                      <img src={service.imgSrc} alt="solutions-icon" />
                    </div>
                    <h4>{service.title}</h4>
                    <p>{service.description}</p>
                    <Link to={service.link}>Read more</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* third section  */}

      <section className="procrop-web-solutions business-background-image">
        <div className="common-heading-panel">
          <h2 data-aos="fade-up" data-aos-delay="300">
            Explore{" "}
            <span className="span-underline">Our Enterprise Business</span>{" "}
            Solutions
          </h2>
        </div>

        <div className="services-utilities">
          <h3 data-aos="fade-up" data-aos-delay="400">
            Serving Fortune Global clients in Retail, Financial Services,
            <br /> Banking, Energy Utilities, & Technology
          </h3>
          <h4 data-aos="fade-up" data-aos-delay="400">
            Re-imagine Engagement
          </h4>
          <p data-aos="fade-up" data-aos-delay="300">
            Digital Disruption is causing changes everywhere – specifically in
            your business, in your competitors’ businesses, and with your
            customers’ behavior. It’s how you turn these challenges into
            opportunities that will drive your success. At ProCorp, we share
            insights, best practices, and lessons learned, to give you the
            competitive edge.
          </p>
          <p data-aos="fade-up" data-aos-delay="300">
            The speed with which people embrace new technologies and channels is
            only getting faster, blindsiding traditional firms that struggle to
            adapt. Peoples’ expectations have widely changed, and as a result,
            relationships with brands have also changed. To compete in the age
            of digital disruption, companies are re-imagining engagement while
            also driving agility and efficiency through innovation,
            architecture, and enhanced digital operations.
          </p>
        </div>

        {solutions.map((solution, index) => (
          <div className="container" key={index}>
            <div className="soluttion-row">
              <div className="row">
                {index % 2 === 0 ? (
                  <>
                    <div className="col-md-6">
                      <div
                        data-aos="fade-left"
                        data-aos-delay="400"
                        className={`solutions-bg-panel ${solution.panelClass}`}
                      ></div>
                    </div>
                    <div className="col-md-6">
                      <div
                        data-aos="fade-right"
                        data-aos-delay="400"
                        className="solution-blue-panel"
                      >
                        <h3>{solution.title}</h3>
                        <div className="ro-colm clearfix">
                          <div className="col-sol-icon">
                            <div className="solutions-icons">
                              <img src={solution.icon} alt="sol-icon" />
                            </div>
                          </div>
                          <div className="col-content-sol">
                            <p>{solution.description}</p>
                          </div>
                        </div>
                        <div className="pull-right">
                          <Link to={solution.link}>Read More</Link>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-md-6">
                      <div
                        data-aos="fade-right"
                        data-aos-delay="400"
                        className="solution-blue-panel"
                      >
                        <h3>{solution.title}</h3>
                        <div className="ro-colm clearfix">
                          <div className="col-sol-icon">
                            <div className="solutions-icons">
                              <img src={solution.icon} alt="sol-icon" />
                            </div>
                          </div>
                          <div className="col-content-sol">
                            <p>{solution.description}</p>
                          </div>
                        </div>
                        <div className="pull-right">
                          <Link to={solution.link}>Read More</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        data-aos="fade-left"
                        data-aos-delay="400"
                        className={`solutions-bg-panel ${solution.panelClass}`}
                      ></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </section>

      {/* fourth sections */}

      <section className="building-solutions">
        <div className="common-heading-panel">
          <h2 data-aos="fade-up" data-aos-delay="400">
            Building solutions with Global Technology
            <br /> Platform Providers
          </h2>
        </div>
        <div className="container">
          <div style={{ position: "relative", width: "80%", margin: "auto" }}>
            <Swiper
              modules={[Navigation]}
              spaceBetween={30}
              slidesPerView={4}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              loop={true}
              onInit={(swiper) => {
                // Assign the elements to Swiper after initialization
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              style={{ position: "relative" }}
            >
              {solutionIcons.map((icon, index) => (
                <SwiperSlide key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "150px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <img
                      src={icon}
                      alt={`Solution Icon ${index + 1}`}
                      style={{ height: "80px" }}
                    />
                  </div>
                </SwiperSlide>
              ))}
              {/* Navigation buttons */}
              <div
                ref={prevRef}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  display: "none",
                }}
              >
                &#8592; {/* Left Arrow */}
              </div>
              <div
                ref={nextRef}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  zIndex: 10,
                  display: "none",
                }}
              >
                &#8594; {/* Right Arrow */}
              </div>
            </Swiper>
            <div
              ref={prevRef}
              style={{
                position: "absolute",
                top: "50%",
                left: "-23px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "25px",

                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                border: "1px solid #8292a9",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              &#10094;
            </div>
            <div
              ref={nextRef}
              style={{
                position: "absolute",
                top: "50%",
                right: "-23px",
                transform: "translateY(-50%)",
                cursor: "pointer",
                fontSize: "24px",
                background: "#fff",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                border: "1px solid #8292a9",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              &#10095;
            </div>
          </div>
        </div>
      </section>

      <form onSubmit={handleSubmit}>
        <section className="excited-talk-section join-our-team text-center jobs-background-image">
          <div className="container">
            <h2 data-aos="fade-up" data-aos-delay="400">
              Join Our Team
            </h2>
            <div className="opening-jobs-panel">
              <Link to="/careers">Jobs Openings</Link>
            </div>
            <div className="row">
              {searchFields.map((field, index) => (
                <div className="col-md-4 col-sm-4" key={index}>
                  <div
                    data-aos={field.animation}
                    data-aos-delay="400"
                    className="job-search"
                  >
                    <div className="icon-overflow">
                      <img src={field.icon} alt={field.name} />
                    </div>
                    <div className="job-search-box clearfix">
                      <div className="opening-jobs-panel">
                        <input
                          type="text"
                          placeholder={field.placeholder}
                          name={field.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="search-go-panel">
                        <button className="btn-go" type="submit">
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default HomePage;
