import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

Modal.setAppElement("#root");

const CookieConsent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Show modal when component mounts
    setIsModalOpen(true);
  }, []);

  const setCookieResponse = async (response) => {
    console.log(response);
  
    if (response === 1) {
      try {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        
        // Constructing the cookie string
        const cookieString = `XSRF-TOKEN=eyJpdiI6IlBcL0NqUlNFc2wzWHpMTnBJb2M5UFwvZz09IiwidmFsdWUiOiJUak96U0ZERThnV3hPMlI5dW42d01qaTc2amRLU2RORXhlZ2dKdUFQY3NsOXlkdTJZd2pEVzljQ2JyMWlyaHhiIiwibWFjIjoiODk1MmJjY2NmZjI0ZTI0MmYxZWQ3ZjIxMjIwNWUxYTdmYTM5MGQ5ZWY4ZjI5YjY0NDU2NzhlZjhmYjE3YzEzZSJ9; procorp_new_web_cms_session=eyJpdiI6IkdPc3RLTTAwXC96MGpMVHR0NWFNQWp3PT0iLCJ2YWx1ZSI6Iis5VGdGR0RhYlpsKzNaTGo3QVwveUxJMFhhVURlZE04UjZQM3A1WHFDZ1VLU2xudEZmeGlzYlFubU9NUlVYcHp2IiwibWFjIjoiMjBjYTEwN2RhODc3OWFjMGQzZDE5MGEyNjVhYWNkNDQwNTFmOTAyMzgxMDRhOWFlYjk1YjA4ZWY1NjIzMzBlNyJ9`;
  
        const result = await axios.post(
          'https://procorpsystems.co/set-cookie-response', // Replace with your endpoint
          {}, // Request body (empty in this example, update if needed)
          {
            headers: {
              'X-CSRF-TOKEN': csrfToken,
              'Content-Type': 'application/json',
              'Cookie': cookieString
            }
          }
        );
  
        console.log('Success:', result.data);
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      setIsModalOpen(false);
    }
  };
  

  const customStyles = {
    overlay: {
      backgroundColor: "none", // Semi-transparent black background
      zIndex: 1000, // Ensure it overlays all content
    },
    content: {
      backgroundColor: "none",
      width: "660px",
      maxWidth: "90%",
      height: "350px",
      margin: " 100px auto auto auto",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      boxShadow: "0px 4px 8px none",
      position: "relative",
      transition: "all 0.3s ease",
      borderRadius: "8px"
    },
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{ ...customStyles, backgroundColor: "none" }}
    >

      <div className="modal-body">
        <div className="cookies-section">
          <h3>Cookies Policy</h3>
          <p>
            This website stores cookies on your computer. These cookies
            are used to collect information about how you interact with
            our website and allow us to remember you. We use this
            information to improve and customize your browsing experience
            and for analytics and metrics about our visitors on this
            website and other media. To find out more about the cookies we
            use, see our{" "}
            <Link
              target="_blank"
              to="/privacy-policy"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-default accept-btn accept-cookies"
          onClick={() => setCookieResponse(1)}
        >
          Accept
        </button>
        <button
          type="button"
          className="btn btn-default deny-btn deny-cookies"
          onClick={() => setCookieResponse(2)}
        >
          Deny
        </button>
      </div>


    </Modal>
  );
};

export default CookieConsent;
