import React, { useEffect, useState } from "react";
import "../../css/Resources.css";
import resCardImage1 from "../../Assests/res-card-img-001.jpg";
import resCardImage2 from "../../Assests/res-card-img-002.jpg";
import resCardImage3 from "../../Assests/res-card-img-003.jpg";
import resCardImage4 from "../../Assests/res-card-img-004.jpg";
import resCardImage5 from "../../Assests/res-card-img-005.jpg";
import resCardImage6 from "../../Assests/res-card-img-006.jpg";
import resCardImage7 from "../../Assests/res-card-img-007.jpg";
import resCardImage8 from "../../Assests/res-card-img-008.jpg";
import resCardImage9 from "../../Assests/res-card-img-009.jpg";
import resCardImage10 from "../../Assests/res-card-img-010.jpg";
import resCardImage11 from "../../Assests/res-card-img-011.jpg";
import resCardImage12 from "../../Assests/res-card-img-012.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Resources = () => {

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Resources</title>

        <meta name="keywords" content="Resources" />

        <meta name="description" content="Resources" />
      </Helmet>
      <div>
        <section class="global-web-page">
          <section class="top-global-slide">
            <div class="container">
              <h1>Resources</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li class="active">Resources</li>
              </ul>
            </div>
          </section>
          <section class="gray-shade-bg">
            <section class="case-studies-section text-center">
              <div class="case-studies-option">
                <div class="container">
                  <div class="row">
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage1} alt="res-dg-img-001" />
                        </div>
                        <h3>Digital Information</h3>
                        <p>
                          Digital Tansformations is more than just changing the
                          underlying technology. As technology evolves,
                          connected...
                        </p>
                        <Link to="/resources/digital-transformations">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage2} alt="res-dg-img-001" />
                        </div>
                        <h3>Microservices</h3>
                        <p>
                          Microservices help overcome these shortcomings of
                          monolithic architecture and provide a solution that
                          helps systems...
                        </p>
                        <Link to="/solution/microservices">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage3} alt="api-economy-res" />
                        </div>
                        <h3>API Economy</h3>
                        <p>
                          The API economy refers to the set of business models
                          and practices designed around the use of APIs...
                        </p>
                        <Link to="/solution/apieconamy">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage4} alt="res-open-banking" />
                        </div>
                        <h3>Open Banking</h3>
                        <p>
                          New technologies and business models are redefining
                          bank operating models and how banks interact with
                          customers...
                        </p>
                        <Link to="/solution/openbanking">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage5} alt="res-payment" />
                        </div>
                        <h3>Payments</h3>
                        <p>
                          The global cards and payments industry is witnessing a
                          diminishing trend in traditional banking...
                        </p>
                        <Link to="/solution/payments">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage6} alt="res-cloud-technology" />
                        </div>
                        <h3>Cloud</h3>
                        <p>
                          The advent of the cloud has democratized computing by
                          opening opportunities to set up enterprise-class
                          services...
                        </p>
                        <Link to="/services/cloud">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage7} alt="res-retail" />
                        </div>
                        <h3>Retail</h3>
                        <p>
                          Every aspect of CPG and Retail companies are
                          undergoing tremendous pressure as digital tools and
                          changing...
                        </p>
                        <Link to="/resources/retail">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage8} alt="res-crm" />
                        </div>
                        <h3>CRM</h3>
                        <p>
                          CRM combination of business strategies, software and
                          processes build long-lasting relationships companies
                          and their customers...
                        </p>
                        <Link to="/resources/crm">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage9} alt="bfsi-res" />
                        </div>
                        <h3>Banking and Financial</h3>
                        <p>
                          The Banking and Financial Services industry is
                          witnessing a rapidly changing landscape, the rise of
                          fintech companies...
                        </p>
                        <Link to="/resources/banking-finance">Read More</Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img
                            src={resCardImage10}
                            alt="suppy-management-res"
                          />
                        </div>
                        <h3>Supply Chain Management</h3>
                        <p>
                          Globally, the Logistics and Transportation industry is
                          evolving into a lean and agile industry powered by the
                          advancements...
                        </p>
                        <Link to="/resources/supply-chain-management">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage11} alt="health-care-res" />
                        </div>
                        <h3>Healthcare and Life Sciences</h3>
                        <p>
                          The healthcare industry is undergoing a significant
                          technology transformation. The focus of the business
                          has been shifting...
                        </p>
                        <Link to="/resources/healthcare-and-life-sciences">
                          Read More
                        </Link>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-4">
                      <div class="case-studies-option-panel">
                        <div class="image-overflow-panel">
                          <img src={resCardImage12} alt="energy-ut-res" />
                        </div>
                        <h3>Energy &amp; Utilities</h3>
                        <p>
                          The continuously changing economic scenario, M&amp;A
                          activities, privatisation, deregulation and growing
                          competition is leading...
                        </p>
                        <Link to="/resources/energy-and-utilities">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};
export default Resources;
