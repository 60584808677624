import React, { useEffect } from "react";
import onlinepayment from "../../Assests/payments/online-payment.jpg";
import commercialcard from "../../Assests/payments/commercial-card.jpg";
import consumercardservices from "../../Assests/payments/consumer-card-services.jpg";
import paymyorder from "../../Assests/payments/paymy-order.jpg";
import paymentaccess from "../../Assests/payments/payment-access.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Payments = () => {
 
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Payments</title>

        <meta name="keywords" content="Payments" />

        <meta name="description" content="Payments" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="global-slide-lg"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/4db89b114f10c658c0edd8f1e8396b5c.jpg')",
          }}
        >
          <div className="container">
            <h1>Payments</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/solution">
                  Solutions
                </Link>
              </li>
              <li className="active">Payments</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="deatil-page-section">
            <div className="deatil-top">
              <div className="container">
                <h2>Payments</h2>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <section className="consulting-section cloud-computing-section">
                    <div className="row">
                      <div className="col-md-6" style={{ margin: "0 0 30px" }}>
                        <div className="common-image-overflow">
                          <img src={onlinepayment} alt="Online Payment" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="digital-consult-panel margin-zero">
                          <p>
                            The global cards and payments industry is witnessing
                            a diminishing trend in traditional banking and
                            constantly progressing towards emerging digital
                            technologies. To keep up with the trend, financial
                            institutions are adopting to new technologies and
                            complying with constantly changing regulations /
                            compliances. Looking ahead, we must adapt –
                            providing solutions that solve customer challenges
                            when and where needed. Our offerings are designed to
                            be frictionless, be automated, and promote growth
                            beyond to radically transform the way we operate to
                            drive global standardization and scalability.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 payment-block-panel">
                          <h4>Cards &amp; Payments </h4>
                          <p>
                            Cards &amp; Payments business is one such segment
                            within the financial services industry which
                            exemplifies this business trend. As digital commerce
                            grows more rapidly, both the incumbents and
                            challengers must find ways to reduce friction in
                            payments and improve security to win in the
                            marketplace. Digitization in payments will continue
                            to make it ubiquitous and embedded but it is equally
                            important to make it more secure as well.
                          </p>
                        </div>
                        <div className="col-md-6 col-sm-6 payment-block-panel">
                          <h4 className="mt-5">ProCorp has rich experience</h4>
                          <p>
                            ProCorp has rich experience in handling complex and
                            diverse platforms across every aspect of Cards &amp;
                            Payments value chain covering card networks, card
                            processors, merchant acquirers, payments service
                            providers, clearing &amp; settlement schemes,
                            digital payments, and remittance players. Our
                            seasoned experts embrace design thinking along with
                            deep insights into the transformative and disruptive
                            impact of emerging technologies and innovative
                            business models are well equipped to help industry
                            player navigate these dynamic forces.
                          </p>
                        </div>
                      </div>
                    </div>

                    <h2 className="mt-5">ProCorp offers a complete suite </h2>
                    <p>
                      ProCorp has rich experience in handling complex and
                      diverse platforms across every aspect of Cards &amp;
                      Payments value chain covering card networks, card
                      processors, merchant acquirers, payments service
                      providers, clearing &amp; settlement schemes, digital
                      payments, and remittance players. Our seasoned experts
                      embrace design thinking along with deep insights into the
                      transformative and disruptive impact of emerging
                      technologies and innovative business models are well
                      equipped to help industry player navigate these dynamic
                      forces.
                    </p>

                    <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                      <h2>Commercial Card Services</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            Commercial Card Services provide tools to manage
                            expense reporting and address the risks and
                            administrative costs of processing invoices and
                            payments. We can offer your customers commercial
                            cards for employee use for business expenses. This
                            enables your customers to manage costs by keeping
                            track of when, where and by whom the money is spent.
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="common-image-overflow">
                            <img src={commercialcard} alt="Commercial Card" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                      <h2>Consumer Card Services</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="common-image-overflow">
                            <img
                              src={consumercardservices}
                              alt="Consumer Card Services"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p>
                            Consumer Card Services offer an "always-on"
                            end-to-end solution to your card transaction needs.
                            We process purchases quickly at the point of sale
                            and route the transactions to the correct location.
                            We also apply the correct fees and interest rates to
                            customer bills and collect money from them to make
                            sure all accounts are paid in full. Credit, debit,
                            and gift cards are now the most common forms of
                            payment. These challenges present numerous customer
                            churn, ever-changing fraud patterns, and new
                            competition from mobile phone companies and online
                            players.
                          </p>
                        </div>
                      </div>
                    </div>

                    <p className="mt-5">
                      <strong>Merchant Acquirer Services</strong> is a
                      transaction-processing system that helps acquirer banks
                      supply card payment services to merchants. We manage the
                      card pricing factors and calculate best interchange rate
                      for each transaction. Statements are adaptable and
                      deposits flexible. For example, you can specify settlement
                      rules and define multiple merchant accounts to route
                      charges and deposits separately. We support any form of
                      debit or credit card payment and package your business
                      rules in a PCI-compliant environment and deliver services
                      on a 'pay for use' basis. Capital investments are
                      minimized, and ongoing costs are predictable.
                    </p>

                    <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                      <h2>Secure Payment Services</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            Secure Payment Services eliminate the cost and
                            ongoing effort of complying with Payment Card
                            Industry Data Security Standards (PCI DSS). We offer
                            services to a more secure, seamless, environment for
                            accepting credit card payments—without touching
                            customers’ sensitive card information.
                          </p>

                          <p>
                            Our drive to be the trusted global partner for
                            Enterprise payment solutions - crafting intuitive
                            customer experiences deeply ingrained in the moments
                            and places that matter.
                          </p>
                          <ul>
                            <li>
                              The marketplace is becoming increasingly
                              competitive
                            </li>
                            <li>
                              New technology and innovations are reshaping the
                              environment
                            </li>
                            <li>
                              Our competitors are attempting to differentiate
                              themselves
                            </li>
                            <li>Our clients are demanding we keep up</li>
                            <li>
                              With changes in strategy, investment level, and
                              priorities...
                            </li>
                            <li>
                              We have an opportunity to alter the current
                              trajectory
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <div className="common-image-overflow">
                            <img src={paymyorder} alt="Secure Payment" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 className="mt-5">What we do – Services Portfolio</h2>

                    <ul>
                      <li>Real Time Payments</li>
                      <li>Payments as-a Service</li>
                      <li>Blockchain for Cross Border Payments</li>
                      <li>Supplier Financing / Merchant Financing</li>
                      <li>Non-Card Lending / POS Financing</li>
                      <li>Alternative Payment Rail</li>
                      <li>E-Wallets / QR Codes</li>
                      <li>Open Banking API</li>
                      <li>Wearable / Digital Payments</li>
                      <li>New Age Mobile POS</li>
                      <li>Secure Remote Commerce / Mobile Commerce</li>
                      <li>B2B Payments</li>
                      <li>Payments Data Monetization</li>
                      <li>Tokenization</li>
                      <li>Strong Customer Authentication</li>
                      <li>Mobile Based Geo-Fencing</li>
                      <li>Fraud Prevention</li>
                    </ul>
                    <h2>
                      ProCorp’s Payment Reference Architecture – PayHub ™
                      Framework
                    </h2>

                    <div class="payment-acess-center">
                      <img src={paymentaccess} alt="payment-access" />
                    </div>
                    <p className="mt-5">
                      The global cards and payments industry is witnessing a
                      diminishing trend in traditional banking and constantly
                      progressing towards emerging digital technologies.
                    </p>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Payments;
