import React from "react";
import infaImgOverflow from "../../Assests/services/infa-img-overflow.jpg"
import newDataImg from "../../Assests/services/new-data-img.jpg";
import newUx from "../../Assests/services/new-ux.jpg";
import serviceConsulting from "../../Assests/services/service-consulting.jpg";
import servicesCloud from "../../Assests/services/services-cloud.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="csrf-token" content="8OBqFkW1I6P5iuflNFfnycwElePh1pBiNJ0Pyld2" />


        <title>Procorp Services</title>

        <meta name="keywords" content="Procorp Services" />

        <meta name="description" content="Procorp Services" />
      </Helmet>
      <section class="global-web-page">
        <section
          class="top-global-slide"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/af2ecd48a04ba347c12cd2ad2e13086b.jpg')",
          }}
        >
          <div class="container">
            <h1>Services</h1>

            <ul>
              <li>
               <Link to="/">Home</Link>
              </li>

              <li class="active">Services</li>
            </ul>
          </div>
        </section>

        <section class="gray-shade-bg">
          <section class="about-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Digital Transformation
              </h2>
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="about-content-panel"
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    <p>
                      Digital transformation is more than just changing the
                      underlying technology. As technology evolves, connected
                      devices increase, experiences get interactive, and
                      consumer expectations grow– businesses need to adapt to
                      the Digital-first landscape by transforming their People,
                      Processes, and Products.
                    </p>
                    <p>
                      Digital advancements and the focus on customer-centric
                      business models are driving change and creating new
                      avenues of growth. Reimagine your digital ecosystem to
                      ensure product and service quality, enhance operational
                      efficiency, and boost profits with our Solutions digital
                      transformation framework.
                    </p>
                    <Link
                      to="/resources/digital-transformations.html"
                      class="border-btn"
                    >
                      Learn More{" "}
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="digital-transformation"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  ></div>
                </div>
              </div>
            </div>
          </section>

          <Link name="consulting"></Link>
          <section class="about-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Consulting
              </h2>
              <div class="row">
                <div class="col-md-6">
                  {/* <!-- <div class="tech-consult-bg" data-aos="fade-up" data-aos-delay="500"></div> --> */}
                  <div
                    class="image-overflow"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    <img src={serviceConsulting} alt="serviceConsulting" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div
                    class="about-content-panel"
                    data-aos="fade-up"
                    data-aos-delay="700"
                  >
                    <p>
                      Our digital transformation offerings are aimed at
                      identifying gaps in your business strategy and suggesting
                      the best technology stack to drive digital disruption,
                      accelerate innovation, increase productivity, reduce
                      costs, and optimize asset utilization.
                    </p>
                    <h3 style={{ margin: "20px 0 20px" }}>
                      Transformative Growth
                    </h3>
                    <p>
                      While Corporate Boards and CEOs expect growth from
                      innovation, the reality is that most large and mid-sized
                      companies are optimized for incremental growth.
                      Longer-range innovation inherently has higher risk and
                      many companies do not invest in it enough as a result,
                      leaving their brands to grow at market rate through less
                      risky, but less rewarding, incremental moves.
                    </p>
                   <Link to="/services/consulting.html" class="border-btn">
                      Learn More{" "}
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="technology-sh">
                <div class="row">
                  <div class="col-md-6"
                   data-aos="fade-up" data-aos-delay="500"
                   >
                    <div class="technology-sh-benefit">
                      <h3>Core Business Innovation</h3>
                      <p>
                        Creating brand-new categories and disruptive business
                        models is a key strategy for companies looking to
                        achieve growth from innovation but optimizing the core
                        business for successful execution can have a dramatic
                        impact on the top and bottom line as well.
                      </p>
                      <p>
                        However, many companies fight to consistently meet their
                        product development effectiveness objectives for time,
                        cost, and quality. To beat competitors to market and hit
                        product-driven growth targets, they must be able to
                        execute their product development processes flawlessly.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6" data-aos="fade-up" data-aos-delay="700">
                    <h3>Innovation Strategy</h3>
                    <p>
                      Many organizations have business strategies that depend
                      upon growth from innovation but often don’t articulate how
                      much of that growth should come from innovation and what
                      forms it needs to take. An effective innovation strategy
                      formally defines how innovation will contribute to
                      business growth and provides a roadmap to keep the focus
                      on executing successfully.
                    </p>
                    <p>
                      We work with clients to develop innovation strategies that
                      reinvigorate core businesses and create brand-new sources
                      of growth. This means translating business objectives into
                      specific goals, direction, and metrics for innovation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Link name="cloud"></Link>
          <section class="who-we-are-section cloud-service-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Cloud
              </h2>
              <div class="row">
                <div class="col-md-6">
                  <div class="cloud-section-left">
                    <div
                      class="cloud-paragraph"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <p>
                        The advent of the cloud has democratized computing by
                        opening opportunities to set up enterprise-class
                        services, even for individuals and small businesses.
                        Cloud computing offers access to data and applications
                        from anywhere with internet connectivity and slashes IT
                        infrastructural costs tremendously.
                      </p>
                      <h3>Cloud Strategy</h3>
                      <p>
                        A robust cloud strategy tailored to your business
                        objectives can be the difference between creating a
                        sustainable competitive edge in a fast-paced digital
                        world and risking wasted investment.
                      </p>
                    </div>
                  </div>
                 <Link to="/services/cloud.html" class="border-btn">
                    Learn More{" "}
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
                <div class="col-md-6">
                  {/* <!-- <div class="cloud-technology-infographic" data-aos="fade-up" data-aos-delay="600"></div> --> */}
                  <div
                    class="image-overflow"
                    data-aos="fade-up"
                    data-aos-delay="600"
                    style={{ margin: "60px 0 0" }}
                  >
                    <img src={servicesCloud} alt="servicesCloud" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Link name="platform"></Link>
          <section class="what-we-do-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Platform
              </h2>
              <div class="row">
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="700">
                  {/* <!-- <div class="platform-bg-infographic"></div> --> */}
                  <div class="image-overflow" style={{ margin: "40px 0 0" }}>
                    <img src={newUx} alt="newUx" />
                  </div>
                </div>
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
                  <div class="division-italic">
                    Customers do not care about the underlying technology. They
                    only care about how effectively their problem was addressed
                    and solved. That is why we design our products by placing
                    the customer at the center of the digital business model.
                    Smart devices and ease of scaling have enabled businesses to
                    engage with their customers like never. Innovation and
                    expertise in business transformation are now the key metrics
                    determining the success of companies in the future.
                  </div>

                  <p>
                    Companies increasingly understand the potential of the
                    Internet of Things (IoT) to grow their business yet limit
                    themselves to narrow proof of concepts. As a result, many
                    struggle to prove the full value.
                  </p>

                  <p>
                    Implementing a smart, connected strategy using an IoT
                    platform requires a comprehensive, integrated, yet practical
                    approach, combining new technology, skills and capabilities
                    supported by effective organizational change management. It
                    also requires building vendor relationships and a digital
                    ecosystem to provide connectivity, managed services and
                    embedded hardware or software.
                  </p>

                 <Link to="/services/platform.html" class="border-btn">
                    Learn More{" "}
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </section>

          <Link name="ims"></Link>
          <section class="who-we-are-section ims-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Infrastructure Managed Services
              </h2>
              <div class="row">
                <div class="col-md-6">
                  {/* <!-- <div class="ims-management-panel" data-aos="fade-up" data-aos-delay="600"></div> --> */}
                  <div
                    class="image-overflow"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <img src={infaImgOverflow} alt="infaImgOverflow" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="cloud-section-left">
                    <div
                      class="cloud-paragraph"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <p>
                        Many small and medium-sized businesses cannot afford to
                        hire a full-time IT professional. Why hire a person when
                        you can gain a team of experts through ProCorp at a
                        fraction of the costs? For over 20 years, we have been
                        offering businesses Managed IT services for needs
                        ranging from computer support to data recovery, to
                        network security, cloud computing, and more.
                      </p>
                    </div>
                  </div>
                  <Link
                    to="/services/infrastructure-managed-services.html"
                    class="border-btn blackborder"
                  >
                    Learn More{" "}
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>

              <div class="ims-white">
                <div class="row">
                  <div class="col-md-6">
                    <h3>Managed IT Services</h3>
                    <p>
                      As an enterprise-class IT services company, we have a
                      range of delivery models that are designed out of the
                      strong foundation of adaptability, reliability, and
                      expertise to help your clients drive their businesses
                      towards their transformational goals. Our comprehensive IT
                      professional services can cover every aspect of your
                      technology needs from development to operations.
                    </p>
                  </div>
                  <div class="col-md-6">
                    <h3>Cloud Managed Services</h3>
                    <p>
                      Convert your IT investments into a predictable operating
                      expense. Our managed cloud solutions give you the
                      flexibility to store a part of your data in the cloud,
                      such as emails, while keeping your sensitive data stored
                      locally. Our Managed Hosting services enable you to
                      eliminate upfront server costs while giving you access to
                      Fortune 500 class data hosted solutions for your email,
                      file storage and backup needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Link name="data"></Link>
          <section class="data-section">
            <div class="container">
              <h2 data-aos="fade-up" data-aos-delay="300">
                Data
              </h2>
              <div class="row">
                <div class="col-md-6">
                  {/* <!-- <div class="data-management-panel" data-aos="fade-up" data-aos-delay="600"></div> --> */}
                  <div
                    class="image-overflow"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <img src={newDataImg} alt="newDataImg" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="cloud-section-left">
                    <div
                      class="cloud-paragraph"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <p>
                        We are approaching a data-led economy where data is
                        revolutionizing the way we conduct business. While an
                        exploding data volume opens exciting possibilities, it
                        is not sufficient to make smarter business decisions. We
                        need to have the appropriate infrastructure in place to
                        process big data in meaningful ways for deeper business
                        understanding.
                      </p>
                    </div>
                  </div>
                 <Link to="/services/data.html" class="border-btn black">
                    Learn More{" "}
                    <i class="fa fa-angle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
              <div class="data-list-panel" style={{ margin: "30px 0 0" }}>
                <h3>What We Do - Services</h3>
                <div class="row">
                  <div class="col-md-6">
                    <p>
                      We help leading organizations set up their big data
                      ecosystem as per their business needs. We advise you on
                      implementing cost-effective big data solutions to extract
                      unprecedented value from your data.
                    </p>
                  </div>

                  <div class="col-md-6">
                    <p>
                      We use data science solutions to leverage the power of
                      predictive analytics and derive real-time insights to
                      reduce customer churn. We help our clients solve complex
                      data challenges, predict demand, improve customer
                      satisfaction, and guide business strategies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Services;
