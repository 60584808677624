import React, { useEffect } from "react";
import "../../css/Retail.css";
import retailImg1 from "../../Assests/retail-img1.jpg";
import retailImg2 from "../../Assests/retail-img2.jpg";
import retailImg3 from "../../Assests/retail-img3.jpg";
import retailImg4 from "../../Assests/retail-img4.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Retail = () => {

  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Retail Resources</title>

        <meta name="keywords" content="Retail Resources" />

        <meta name="description" content="Retail Resources" />
      </Helmet>
      <div>
        <section class="global-web-page">
          <section class="top-global-slide">
            <div class="container">
              <h1>Retail</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/resources">
                    Resources
                  </Link>
                </li>
                <li class="active">Retail</li>
              </ul>
            </div>
          </section>
          <section class="gray-shade-bg">
            <section class="deatil-page-section">
              <div class="deatil-top">
                <div class="container">
                  <h2>Retail</h2>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <section class="consulting-section cloud-computing-section">
                      <h2>
                        Modernization Strategies as a Foundation for Digital
                        Transformation{" "}
                      </h2>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="common-image-overflow">
                            <img src={retailImg1} />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="digital-consult-panel margin-zero">
                            <p>
                              The retail industry is witnessing a paradigm
                              shift. Rapidly evolving technologies, changing
                              customer expectations, and emerging digital firms
                              are driving widespread disruption. Clearly, from a
                              plethora of new products, markets, and customer
                              segments to the burgeoning expansion of sales and
                              marketing channels (like mobile and social
                              commerce), retail is at a tipping point. Customers
                              are now demanding rich shopping experiences that
                              are personalized, hyper-connected, and engaging.
                              Additionally, several forces are revolutionizing
                              the landscape: the explosion of data and connected
                              devices, cloud enabled as-a-service and
                              experience, and outcome-driven digital platforms.
                              This makes it imperative for retailers to showcase
                              agility in tech adoption and business process
                              optimization.
                            </p>
                            <p>
                              Customers are benefactors of limitless choice,
                              fingertip access to information, and low switching
                              costs. Retailers must be ready at any moment to
                              give them what they want, which can be a stretch
                              for operations running on archaic business
                              processes and technology.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <div class="row">
                          <div class="col-md-6">
                            <p>
                              Retail solutions and CPG companies need to be
                              agile and flexible to cater to customers 24×7.
                              Providing a personalized shopping experience is
                              fast becoming an imperative, calling for a
                              360-degree customer view with seamless integration
                              across all interaction channels. This requires a
                              connected supply chain to enable omni-channel
                              operations, respond to demand-supply fluctuations
                              in real-time and make better pricing decisions.
                              Organizations are turning towards technology to do
                              more than just support their businesses. Cloud,
                              Machine Learning, AI, Microservices and APIs are
                              being used to roll out new business capabilities
                              and create a unique offering for customers,
                              combining efficiency and experience.
                            </p>
                            <p>
                              Our clientele includes some of the leading and
                              most innovative companies in sectors such as:
                            </p>
                            <ul>
                              <li>Apparel and footwear</li>
                              <li>Supermarkets</li>
                              <li>Convenience stores</li>
                              <li>Quick service restaurants</li>
                              <li>Specialty retailers</li>
                              <li>Consumer packaged goods</li>
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <div class="image-overflow">
                              <img src={retailImg2} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div class="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <h2>ProCorp’s Digital Commerce Platform</h2>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="image-overflow">
                              <img src={retailImg3} />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <p>
                              Retail solutions need to move from these
                              inside-out vertically integrated systems to
                              outside-in horizontally connected systems. They
                              need to leverage channel data and market
                              intelligence that translate market shifts without
                              latency. Harmonization and synchronization of data
                              needs to take place across the functional silos.
                              Data also needs to move at the speed of business,
                              which would help in continuously planning and
                              developing an optimal response to today’s changing
                              market environment - Fast changing customer
                              expectations are putting pressures on retail
                              systems which are not flexible enough to deliver
                              value in line with evolving business needs.
                            </p>
                            <p>
                              ProCorp’s Digital Commerce platform is an
                              intelligent retail management solution which
                              enhances their execution and planning systems
                              through AI, ML and financial portfolio
                              optimization, helping them improve forecast
                              accuracy, optimize revenue and margin, reduce
                              planning cycle, and more….
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <h2 className="h2-retail">
                        THE FUTURE OF SHOPPING WILL BE SHAPED BY RETAILERS
                        PROVIDING AN INTEGRATED, STREAMLINED PAYMENT EXPERIENCE
                      </h2>
                      <br />
                      <p>
                        Ensuring a seamless and enjoyable customer journey has
                        always been a focal point for retailers. Until recently,
                        the standard journey was well covered: Go to the store,
                        browse the merchandise, and select a product for
                        purchase. However, the payment experience – albeit
                        central to the journey – has long been neglected, with
                        retailers generally choosing to outsource the
                        responsibility to banks, largely due to its complexity.
                        While making payments may seem run-of-the-mill and
                        seamless to many customers, complexity arises as
                        shopping channels, technology, issues with types of
                        payment, and consumer preferences evolve rapidly.
                      </p>
                      <p>
                        Retailers are beginning to develop strategies around
                        integrating payments into their value proposition. While
                        some still see payment as a process to be optimized from
                        a technical and cost standpoint, implementing the right
                        payments strategy is an opportunity for retailers to
                        gain a real strategic advantage
                      </p>
                      <div class="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <h2>ProCorp’s Digital Commerce platform </h2>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="image-overflow">
                              <img src={retailImg4} />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <p>
                              ProCorp’s Digital Commerce platform is developed
                              on an intelligent retail management philosophy. It
                              is built on top of Salesforce Commerce Cloud that
                              enhances execution capabilities like Retail POS &
                              Store Operations, Employee Management,
                              Merchandising, Distributed Order Management with
                              IBM Sterling OMS system, planning solutions like
                              Demand Planning, Financial Merchandise &
                              Assortment Planning and Wholesale Order
                              Management. This single package takes care of the
                              complete planning and execution needs of retail
                              enterprises, reducing their operational costs and
                              integration hassles.
                            </p>
                            <h4>What we do – Services Portfolio</h4>
                            <ul>
                              <div class="row">
                                <div class="col-md-6">
                                  <li>Demand Planning</li>
                                  <li>Retail Sales enablement</li>
                                  <li>Stores Management</li>
                                  <li>Product Management</li>
                                  <li>Express Checkout</li>
                                  <li>
                                    Blockchain-based Unified B2B/B2C Commerce{" "}
                                  </li>
                                  <li>Supplier enablement</li>
                                  <li>Merchandising</li>
                                </div>
                                <div class="col-md-6">
                                  <li>Digital Payments</li>
                                  <li>
                                    Secure Remote Commerce / Mobile Commerce
                                  </li>
                                  <li>Wholesale Order management</li>
                                  <li>Strong Customer Authentication</li>
                                  <li>Accounting, Reporting, Auditing</li>
                                  <li>
                                    Enterprise Data Management & Analytics
                                  </li>
                                  <li>Advanced Analytics</li>
                                  <li>Supply Chain / Inventory</li>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <p>
                        We want to emerge as a major player to help reimagine
                        your business through a digital lens. Our commitment to
                        our customers, employees, and society is to act with
                        integrity and as a trusted partner. This guides
                        everything we do—the way we serve our clients and the
                        work we do to help them build better businesses.
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};
export default Retail;
