import React, { useEffect } from "react";
import "../../css/Crm.css";
import crmImg1 from "../../Assests/crm-img1.jpg";
import crmImg2 from "../../Assests/crm-img2.jpg";
import crmImg3 from "../../Assests/crm-img3.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Crm = () => {

  return (
    <>
      <Helmet   >
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>CRM</title>

        <meta name="keywords" content="CRM" />

        <meta name="description" content="CRM" />
      </Helmet>

      <div>
        <section className="global-web-page">
          <section
            className="top-global-slide"
            style={{
              backgroundColor: "#454545",
              backgroundImage:
                "url('https://procorpsystems.co/uploads/custom-uploads/b207b2ee2a848c04bdf4b441b0dfc0f3.jpg')",
            }}
          >
            <div className="container">
              <h1>CRM</h1>
              <ul>
                <li>
                  <Link to="/">Home</Link >
                </li>
                <li>
                  <Link to="/resources">
                    Resources
                  </Link >
                </li>
                <li className="active">CRM</li>
              </ul>
            </div>
          </section>
          <section className="gray-shade-bg">
            <section className="deatil-page-section">
              <div className="deatil-top">
                <div className="container">
                  <h2>CRM</h2>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <section className="consulting-section cloud-computing-section">
                      <div className="row">
                        <div
                          className="col-md-6"
                          style={{ margin: "0 0 30px" }}
                        >
                          <div className="common-image-overflow">
                            <img src={crmImg1} alt="CRM" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="digital-consult-panel margin-zero">
                            <p>
                              The biggest advantage of having a CRM is that you
                              have all your data consolidated and stored in one
                              place. From customer profiles to customer purchase
                              history, every interaction that you have with your
                              customers can be documented so you can utilize the
                              information you have access to and build deeper,
                              more meaningful relationships with your customers.
                              ProCorp delivers Salesforce customer relationship
                              management (CRM) and customer experience
                              management (CEM) solutions through sales, service,
                              and marketing cloud in addition to custom
                              platform-as-a-service (PaaS) applications that
                              provide the foundation for sustainable growth and
                              increased success. Our in-depth processes and
                              application knowledge, combined with best
                              practices, enable us to offer Salesforce services,
                              including consultancy, implementation, and
                              support.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <h2>Our Salesforce Solutions</h2>
                      <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <div className="row">
                          <div className="col-md-6">
                            <h4>
                              We deliver, optimize, and automate diverse
                              business processes onto a single Salesforce
                              Platform
                            </h4>
                            <p>
                              Partnering with ProCorp provides you with a highly
                              motivated team with a range of talents...
                            </p>
                            <h4>Maximize customer interactions</h4>
                            <ul>
                              <li>
                                Rich customer profiles created through internal
                                and external sources
                              </li>
                              <li>
                                Integration to business management, desktop
                                productivity, e-marketing, and social media
                                tools
                              </li>
                              <li>
                                Sales intelligence application that puts
                                customer data to work
                              </li>
                              <li>
                                Back-office integration with multiple ERP
                                systems
                              </li>
                              <li>
                                Enterprise-wide visibility in a single
                                management and modeling environment
                              </li>
                              <li>
                                Deeply integrated, unified platform for social,
                                mobile, and cloud
                              </li>
                              <li>
                                Reports, dashboards, and analytics provide
                                better visibility into the sales process
                              </li>
                              <li>
                                Real-time access to both operational and sales
                                data
                              </li>
                              <li>Automation of manual processes</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <div className="image-overflow">
                              <img src={crmImg2} alt="Sales" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="image-overflow">
                            <img src={crmImg3} alt="CRM" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p>
                            During the last decade, we have assisted many
                            businesses from diverse backgrounds in their quest
                            for customer relationship management success. Our
                            Certified Salesforce Developers fully understand the
                            needs of a modern-day workplace and provide you with
                            an optimal Salesforce solution with the help of
                            meticulous business analysis and diligent design and
                            implementation.
                          </p>
                          <p>
                            With extensive experience of more than a decade in
                            the CRM industry and large number of satisfied
                            clients, we are more than capable of mapping your
                            unique business processes seamlessly onto the
                            Salesforce Platform, allowing your business to
                            utilize the complete potential of Salesforce and
                            deliver phenomenal results. We understand the common
                            challenges faced by Salesforce customers and
                            implement solutions in advance to ensure that you
                            can focus exclusively on your business while we take
                            care of your CRM needs.
                          </p>
                        </div>
                      </div>
                      <br />
                      <p>
                        Our team of Certified Salesforce Consultants includes
                        Software Engineers, Technical Architects, Business
                        Analysts, Project Managers, Quality Assurance engineers,
                        and Certified Developers. We ensure that our clients are
                        utilizing the complete potential of Salesforce by
                        empowering them to get a 360-degree view of their
                        complete sales cycle, identify trends, spot
                        opportunities, increase efficiency and reduce costs.
                        Having a deep understanding of the scale and complexity
                        of manufacturing data models is critical to gaining
                        pipeline transparency and increasing predictability in
                        an evolving landscape. Developing repeatable sales
                        processes which align with key operational metrics
                        supplies manufacturers with the critical insights needed
                        to forecast new run rates.
                      </p>
                      <p>
                        ProCorp’s Salesforce Practice Team has expertise in
                        aiding commercial and enterprise level manufacturers in
                        understanding the critical role of CRM for developing a
                        holistic view of their customers. Our strategic approach
                        to Manufacturing CRM implementation allows us to assess
                        an organization's readiness for a solution and deploy in
                        iterative, value-focused phases. Our team offers
                        certifications in Sales Cloud, CPQ and was selected as
                        one of the initial participants in Salesforce's
                        Manufacturing Cloud Academy based on our proven track
                        record in the manufacturing space. We can all use an
                        expert sometimes. Our Salesforce Team offers a variety
                        of flexible packages to ensure that help is an email or
                        phone call away. Access our team when you need them and
                        grow your Salesforce instance with confidence.
                      </p>
                      <p>
                        Our Managed Services program allows customers to set up
                        a monthly budget and call on dedicated resources as
                        needs arise. The ProCorp Customer Satisfaction Team
                        monitors usage and coordinates with consultants to
                        proactively identify larger projects or renewal
                        opportunities.
                      </p>
                      <div className="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <h2>What we do – Services Portfolio</h2>
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              We want to emerge as a major player to help
                              reimagine your business through a digital lens.
                              Our commitment to our customers, employees, and
                              society is to act with integrity and as a trusted
                              partner. This guides everything we do—the way we
                              serve our clients and the work we do to help them
                              build better businesses.
                            </p>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>Salesforce consulting</li>
                              <li>Salesforce Implementation</li>
                              <li>Salesforce Customization</li>
                              <li>Salesforce Integration</li>
                              <li>Salesforce Data Migration</li>
                              <li>Salesforce Training</li>
                              <li>Salesforce Support</li>
                              <li>Salesforce Lightning</li>
                              <li>Salesforce Marketing Cloud</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};
export default Crm;
