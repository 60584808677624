import React, { useEffect } from "react";
import "../../css/SupplyChain.css";

import supplychainImg1 from "../../Assests/supply-chain-img1.jpg";
import supplychainImg2 from "../../Assests/supply-chain-img2.jpg";
import supplychainImg3 from "../../Assests/supply-chain-img3.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const SupplyChain = () => {


  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Supply Chain Management</title>

        <meta name="keywords" content="Supply Chain Management" />

        <meta name="description" content="Supply Chain Management" />
      </Helmet>
      <div>
        <section class="global-web-page">
          <section class="top-global-slide">
            <div class="container">
              <h1>Supply Chain Management</h1>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li>
                  <Link to="/resources">
                    Resources
                  </Link>
                </li>

                <li class="active">Supply Chain Management</li>
              </ul>
            </div>
          </section>

          <section class="gray-shade-bg">
            <section class="deatil-page-section">
              <div class="deatil-top">
                <div class="container">
                  <h2>Supply Chain Management</h2>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <section class="consulting-section cloud-computing-section">
                      <h2>
                        Modernization Strategies as a Foundation for Digital
                        Transformation - Is Your Supply Chain Ready for a
                        Digital Economy?
                      </h2>

                      <div class="row">
                        <div class="col-md-6">
                          <div class="common-image-overflow">
                            <img src={supplychainImg1} />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="digital-consult-panel margin-zero">
                            <p>
                              Conventional supply chain operations and processes
                              cannot meet the complex requirements of today’s
                              digital-first economy. A digital supply chain can
                              not only help your business unlock greater
                              opportunities and value but also create a
                              strategic competitive advantage. ProCorp can help
                              you transform your supply chain into an
                              intelligent, digital supply network that can
                              rapidly respond to your business needs - Our
                              approach is simple ― we start off with our
                              customer at the center. Supply chain processes are
                              horizontal and work across internal, vertical
                              organizations to deliver value to customers.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <h2>Risk Management</h2>
                        <div class="row">
                          <div class="col-md-6">
                            <p>
                              People, process, technology, and assets must work
                              seamlessly, meeting, and exceeding customer
                              expectations to win in the marketplace. We first
                              assimilate your business goals and then work with
                              you to develop supply chain strategies that drive
                              superior competitive advantage and value.
                            </p>
                            <p>
                              Customers are benefactors of limitless choice,
                              fingertip access to information, and low switching
                              costs. Retailers must be ready at any moment to
                              give them what they want, which can be a stretch
                              for operations running on archaic business
                              processes and technology.
                            </p>
                            <p>
                              ProCorp uses a full range of proven data
                              management techniques to capture the correct data
                              accurately, minimize redundancy and reduce
                              inconsistency. We work with data from nearly all
                              existing ERP, inventory or warehouse management,
                              transportation management, accounting, or other
                              back-end systems — including SAP, Oracle, or any
                              other leading software platform. Data management
                              solutions from ProCorp put all the information you
                              need to manage a more efficient, more agile, and
                              less costly supply chain at your fingertips.
                              Achieve increased compliance with regulatory
                              requirements through robust data governance
                              processes. Get improved visibility across
                              procurement, warehousing, inventory, distribution,
                              logistics, and customers. Make data work for you.
                            </p>
                          </div>
                          <div class="col-md-6">
                            <div class="image-overflow">
                              <img src={supplychainImg2} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />

                      <h2>
                        Capture untapped value with fully Integrated Supply
                        Chain Planning
                      </h2>

                      <div class="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <h2>
                          ProCorp’s AI-powered Digital Supply Chain platform
                        </h2>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="image-overflow">
                              <img src={supplychainImg3} />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <p>
                              ProCorp’s AI-powered Digital Supply Chain platform
                              delivers unmatched forecast accuracy,
                              collaborative visibility, and actionable planning
                              intelligence—enabling CPG brands to plan and
                              execute agile supply chain strategies at the click
                              of a button.
                            </p>
                            <p>
                              From warehouses, factory yards and industrial
                              plants, to marine terminals and transportation
                              fleets, ProCorp’s all-encompassing suite of Supply
                              Chain Software is designed to help manufacturing
                              and distribution organizations to maximize space,
                              improve efficiencies and increase productivity
                              across the entire business
                            </p>
                            <p>
                              Manufacturing and distribution organizations know
                              that automation can improve efficiencies, maximize
                              space, and increase productivity across their
                              supply chains. Retailers are beginning to develop
                              strategies around integrating payments into their
                              value proposition. While some still see payment as
                              a process to be optimized from a technical and
                              cost standpoint, implementing the right payments
                              strategy is an opportunity for retailers to gain a
                              real strategic advantage.
                            </p>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />

                      <p>
                        <strong>ProCorp’s Digital Supply Chain platform</strong>{" "}
                        is developed on an intelligent management philosophy.
                        Managing costs is vital for businesses, but it shouldn’t
                        drive every decision.
                      </p>
                      <p>
                        In fact, thinking from a cost-first perspective is
                        incomplete, leaving businesses vulnerable to missed
                        opportunities for growth and new revenue.
                      </p>

                      <h2>ROI</h2>

                      <p>
                        It costs real money to make the returns process simple
                        for a customer. But depending on a business’s realities,
                        the long-term benefits—such as increasing customer
                        satisfaction and loyalty—might make an investment in
                        reverse logistics worthwhile. Let cost-cutting be one
                        piece in the logistics puzzle. Evaluate it within the
                        context of all the elements making up your durable
                        supply chain. Approaching your business from a more
                        holistic perspective can help position you to increase
                        performance, spark innovation and overcome volatile
                        circumstances.
                      </p>

                      <p>
                        The success of your business comes down to how resilient
                        your supply chain management is. Your customers’ needs
                        are ever changing, which means you need a supply chain
                        that’s engineered to keep up. From site selection to
                        inventory forecasting, we work with you to design your
                        perfect supply chain network, while making sure you get
                        the agility and low costs you expect. Through
                        integrating with your current control tower or
                        implementing innovative systems and tools that drive
                        actionable analytics and data, we create a culture of
                        continuous improvement. With an optimized transportation
                        network and supply chain, you will be better equipped to
                        speed products to market, drive growth, and focus on
                        what matters most — your customers.
                      </p>

                      <h2>What we do – Services Portfolio</h2>

                      <div class="common-services-panel-area transparent-bg-services benefits-api-panel">
                        <h2>
                          ProCorp’s AI-powered Digital Supply Chain platform
                        </h2>
                        <div class="row">
                          <div class="col-md-6">
                            <ul>
                              <li>SCM Consulting</li>
                              <li>SCM Risk Management</li>
                              <li>SCM Intelligence</li>
                              <li>Forecast Collaboration</li>
                              <li>Purchase Order Collaboration</li>
                              <li>Cost Management</li>
                              <li>Product Lifecycle Management</li>
                            </ul>
                          </div>
                          <div class="col-md-6">
                            <li>Zero-Touch Demand Forecasting</li>
                            <li>SKU- and Ship-to-Level Granularity</li>
                            <li>AI United with Human Intelligence</li>
                            <li>Automated Performance Analytics</li>
                            <li>Accounting, Reporting, Auditing</li>
                            <li>Enterprise Data Management & Analytics</li>
                            <li>Advanced Analytics</li>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />

                      <p>
                        We want to emerge as a major player to help reimagine
                        your business through a digital lens. Our commitment to
                        our customers, employees, and society is to act with
                        integrity and as a trusted partner. This guides
                        everything we do—the way we serve our clients and the
                        work we do to help them build better businesses.
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
    </>
  );
};

export default SupplyChain;
