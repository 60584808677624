import React, { useEffect, useState } from "react";
import "../css/CurrentOpening.css";
import AOS from "aos";
import Modal from "react-modal";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

// import jobs_bg from "../Assests/jobs-bg.jpg"
const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
    zIndex: 1000, // Ensure it overlays all content
  },
  content: {
    width: "600px",
    maxWidth: "97%",
    inset: "0px",

    height: "470px",
    margin: "100px auto auto auto",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "8px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

    position: "relative", // For positioning the close button
    transition: "all 0.3s ease", // Smooth transition effect
  },
};

Modal.setAppElement("#root");

const jobPlacements = [
  {
    title: "Software Architect(s)",
    company: "Procorp Systems Inc - Cincinnati, OH 45227",
    description:
      "Analyze, design, develop, test, implement, maintain software to meet user needs. Develop Architectural solutions and create high-quality programs, modify existing code, expand the functionality, optimize the performance and adapt new services. Build complex Dashboards as per customer requirements.",
    requirements:
      "Bachelor’s Degree in Engineering or related field and 5 years of progressive experience in java, J2EE, Java Script, EJB, JMS, Spring Framework, HibernateServices, JDBC, Oracle, DB2, Linux, Sun Solaris and Windows 7.",
    location:
      "Cincinnati, OH Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Senior Software Quality Assurance Engineer (Sr. SQA Engineer)",
    company: "Procorp Systems Inc - Plano, TX 75023",
    description:
      "Design, Develop, maintain test plan, test Strategies, different QA metrics for large, key programs. Develop and maintain automated test cases, test scripts, execute test suites, review and diagnose reported bugs and ensures overall system quality prior to a customer release. Develop standards and procedures to provide quality guidance methods.",
    requirements:
      "Bachelor Degree in Engineering in Computer Science or any related field. 5 years experience using QE, ReadyAPI, Postman, Katalon, JavaScript, Groovy, E2E testing, Db2, APIs, Splunk, GIT, Agile, Banking Domain.",
    location:
      "Plano, TX Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Genesys Intelligent Automation Engineer",
    company: "Procorp Systems Inc - Plano, TX 75023",
    description:
      "Design, develop, configure, test, deploy and provide support for Genesys stack of products for call center technologies; Review and estimate user stories and consult engagements; Propose best practices and provide solutions for complex issues to the client to enhance their business operations.",
    requirements:
      "Bachelor’s Degree in Engineering in Computer Science or any related field. 5 years’ experience using Voice XML, Avaya Peroproducer, Voice Response Unit, Genesys Voice Platform, Java, Nuance Automatic Speech Recognition, Text to Speech, Backend Tomcat Webservices, SQL Server, IBM Doors, and MS Visio.",
    location:
      "Plano, TX Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Software Quality Assurance (QA) Engineer",
    company: "Procorp Systems Inc - Plano, TX 75023",
    description:
      "Design, Develop, maintain test plan, test Strategies, different QA metrics for large, key programs. Develop and maintain automated test cases, test scripts, execute test suites, review and diagnose reported bugs and ensures overall system quality prior to a customer release. Develop standards and procedures to provide quality guidance methods.",
    requirements:
      "Master's Degree in Engineering, Computer Science or any related field. 6 months experience using Java, Selenium WebDriver, Maven, Cucumber, Tosca, TestNG, SOAP UI, REST UI, ReadyAPI, Postman, Groovy, SQL, HTML, Jmeter and JIRA.",
    location:
      "Plano, TX Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Java/Full Stack Developer",
    company: "Procorp Systems Inc - Cincinnati, OH 45227",
    description:
      "Duration: 12 months+.\nDUTIES AND RESPONSIBILITIES: Implement new features while maintaining overall quality of software. Contribute to a culture of learning by documenting and sharing technical knowledge as a developer on an agile squad. Participate in developer communities of practice by following best practices, participating in code reviews, and sharing knowledge. Remain current on relevant technology trends. Participate in researching and resolving customer issues. Other duties as assigned.",
    requirements:
      "Bachelor’s degree in a relevant technology field or equivalent combination of education and work experience. 5+ years of engineering or other IT work experience relevant to the position. Strong problem-solving and communication skills. Willingness to work in a highly-collaborative environment. Solid understanding and significant experience with modern software development practices, tools, and hardware technologies. Solid understanding of IT security best practices. Expertise in one or more technical and/or functional domains relevant to the position. Experience designing, implementing, and supporting complex technical solutions. Knowledge of object-oriented and functional software design. Proficiency in Java and the Spring Framework is required. Experience designing, developing, and consuming APIs (REST, SOAP, and/or message-driven) is strongly preferred. Experience with messaging technologies (e.g., JMS, MQ, Kafka) is preferred. Experience with Git / GitHub, Spring-Boot, and Gradle/Maven is preferred. Experience with web front-end development using one or more major UI frameworks (e.g., Angular, React, Vue) and TypeScript is desirable.",
    location:
      "Cincinnati, OH Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Systems Engineer(S)",
    company: "Procorp Systems Inc",
    location: "Plano, TX 75023",
    description:
      "Design and Implement VDI virtualization technologies, Deliver VDI to Thin and Zero Clients and migration ESXi hosts for VDI implementation. Develop RDS and Application Pools. Configure Secure access to RDS and VDI platforms using VMware vSphere 6.X/5.X including vCenter Server, VMware ESXi Hosts, Horizon 7.X, vRealize Operation Manager, Python, PowerCLI and Ansible.",
    requirements:
      "Masters in Computer Science or related field. 3 years’ experience in job offered.",
    jobLocation:
      "Plano, TX Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Software Engineer(s)",
    company: "Procorp Systems Inc",
    location: "Plano, TX 75023",
    description:
      "Analyze, design, develop, test, implement, maintain software to meet user needs. Create high-quality programs, modify existing code, expand the functionality, optimize the performance and adapt new services. Build complex Dashboards as per customer requirements.",
    requirements:
      "Master’s Degree in Engineering or related field and 2 years of progressive experience in Splunk Enterprise, JavaScript, Python, Angular JS, TypeScript, CSS, XML Jenkins, SOAP & REST, JIRA, Git, and AGILE.",
    jobLocation:
      "Plano, TX Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Enterprise Solution Architect",
    company: "Procorp Systems Inc",
    location: "Cincinnati, OH 45227",
    description:
      "Developing Architectural solutions for Commercial, Consumer, Payments and Corporate Applications LOBs, leveraging platforms like Cloud Technologies, DevOps, Micro services, API Management, ESB/SOA, Big Data & Analytics, Web & Mobile Technologies. Security architecture model designing for enterprise security product across existing and new banking applications with token- based access/authorization privileges. Providing thought leadership in IT strategy, business and IT alignment, and working with the Business and IT stakeholders.",
    requirements:
      "BA Degree Engineering in any field. 5 years experience in TOGAF, AWS, Azure, C, C++, Java / JEE / SOA, ESB, Data power, Microservices, BPM, MQ, DB2, Oracle, Cloud Virtualization, Hadoop, GitHub, Jenkins.",
    jobLocation:
      "Cincinnati, OH Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Integration Developer(s)",
    company: "Procorp Systems Inc",
    location: "Cincinnati, OH 45227",
    description:
      "Integration Developer(s) Analyze, design, resource-based API modeling, micro-services architecture. Develop services based on standards for data integration techniques, messaging, transformation engines and non-standard services. Develop security certification, OAuth, IP security setup. Support all the phases of SDLC; Debug application codes, enhance and deploy new functionalities to improve programs.",
    requirements:
      "MS in Engineering or Computer Science. 6 month experience using JAVA/J2EE, Spring, Hibernate, Microservices, Tomcat, Java Script, Node Js, JSP, XML, UNIX, ANT, REST, SOAP, GIT and Agile.",
    jobLocation:
      "Cincinnati, OH Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
  {
    title: "Senior Integration Developer(s)",
    company: "Procorp Systems Inc",
    location: "Cincinnati, OH 45227",
    description:
      "Senior Integration Developer(s) Analyze, solution architecture and design, resource-based API modeling, micro-services architecture. Develop services based on different standards for REST, web services, data integration techniques, messaging, and transformation engines and non-standard services. Partner with Enterprise Architecture and Middleware Development teams to identify and execute overall architecture and design of API Gateway and ESB environments. Develop security certification, authentication, authorization, IP security setup, and endpoint configuration.",
    requirements:
      "BA Degree in Engineering. 5 years’ experience in Java, J2EE, Ajax, AngularJS ,JUnit, Restful Webservices, Maven, Hibernate, Spring, Struts, Ant, XML, JSON, Tomcat, WebLogic, Oracle.",
    jobLocation:
      "Cincinnati, OH Mail resume to HRProCorp Systems 2222 W. Spring Creek Pkwy, Ste 202, Plano, TX 75023.",
  },
];
const CurrentOpening = () => {
  const location = useLocation();
  const formData1 = location.state || {};
  const searchKeyword = formData1.keyword || "";
  const skills = formData1.skill || "";
  const locationSearch = formData1.location || "";

  const [filteredJobs, setFilteredJobs] = useState(jobPlacements);

  useEffect(() => {
    let filtered = jobPlacements;

    if (searchKeyword.trim() !== "") {
      filtered = filtered.filter((job) => {
        const titleMatch = job.title
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
        return titleMatch;
      });
    }

    if (skills.trim() !== "") {
      const skillArray = skills
        .toLowerCase()
        .split(",")
        .map((skill) => skill.trim());
      filtered = filtered.filter((job) => {
        const requirementSkills = job.requirements.toLowerCase();

        return skillArray.some((skill) => requirementSkills.includes(skill));
      });
    }
    if (locationSearch.trim() !== "") {
      filtered = filtered.filter((job) =>
        job.location.toLowerCase().includes(locationSearch.toLowerCase())
      );
    }

    setFilteredJobs(filtered);
  }, [searchKeyword, skills, location]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    coverNote: "",
    resume: null,
  });

  // Open and close modal functions
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle file input
  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("firstName", formData.firstName);
    formDataToSend.append("lastName", formData.lastName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("coverNote", formData.coverNote);
    if (formData.resume) {
      formDataToSend.append("resume", formData.resume);
    }

    console.log(formData, "formdata");

    try {
      const response = await axios.post(
        "https://procorpsystems.co/set-pref",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
      } else {
        console.error("There was an error submitting the form.");
      }
    } catch (error) {
      console.error("Network error:", error);
    }

    // closeModal();
  };

  const handleOhterClick = () => {
    setFilteredJobs(jobPlacements);
  };

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="global-web-page">
        <section className="top-global-slide">
          <div className="container">
            <h1>Current Openings</h1>

            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="active">Careers</li>
            </ul>
          </div>
        </section>
        <section className="gray-shade-bg">
          <section className="job-opening-section">
            <div className="container">
              <>
                {filteredJobs.length > 0 ? (
                  filteredJobs.map((job, index) => (
                    <div
                      className="job-placement-section"
                      data-aos="fade-up"
                      data-aos-delay="300"
                      key={index}
                    >
                      <div className="row">
                        <div className="col-md-9">
                          <div className="white-gy-background">
                            <h3>{job.title}</h3>
                            <p>{job.company}</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="white-gy-background">
                            <button
                              className="apply-now-btn"
                              onClick={openModal}
                            >
                              Apply Now
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="job-placement-desciption">
                        <h4>Job Description</h4>
                        <p>{job.description}</p>

                        <h4>Requirement</h4>
                        <p>{job.requirements}</p>

                        <h4>Job location</h4>
                        <p>{job.location}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    class="job-placement-section aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div class="row">
                      <div class="col-md-12">
                        <div class="white-gy-background">
                          <div class="alert alert-info">
                            No matching job openings found!{" "}
                            <button
                              class="btn btn-sm btn-outline"
                              onClick={handleOhterClick}
                            >
                              View other openings
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </div>
          </section>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <div className="modal-header px-2">
              <h2 className="my-1">Apply for the Job Opening</h2>

              <span
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg " style={{ fontSize: 30 }}></i>
              </span>
            </div>
            <div className="custom-talk-form ">
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="form-group d-flex">
                  <div className="input-field">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="firstName">
                      <span className="cover-bg">First Name</span>
                    </label>
                  </div>
                  <div className="input-field">
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="lastName">
                      <span className="cover-bg">Last Name</span>
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-field">
                    <input
                      type="file"
                      id="company-name-pp-mm"
                      required
                      name="resume"
                    ></input>
                    <label for="company-name-pp-mm">
                      <span class="cover-bg">Attach Resume</span>
                    </label>
                  </div>
                  <div className="input-field">
                    <input
                      type="email"
                      name="email"
                      id="femail"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="femail">
                      <span className="cover-bg">Email</span>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <div className="input-field">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="phone">
                      <span className="cover-bg">Phone Number</span>
                    </label>
                  </div>
                  <div className="input-field">
                    <input
                      name="coverNote"
                      id="coverNote"
                      value={formData.coverNote}
                      onChange={handleChange}
                      className="textarea"
                      required
                    ></input>
                    <label htmlFor="coverNote">
                      <span className="cover-bg">Cover Note</span>
                    </label>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    style={{
                      marginTop: "20px",
                      padding: "10px",
                      fontWeight: "bold",
                      borderRadius: "0px !important",
                    }}
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        </section>
      </section>
    </>
  );
};

export default CurrentOpening;
