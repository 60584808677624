import React, { useEffect } from "react";
import appImg001 from "../../Assests/platform/app-img-001.png";
import appImg002 from "../../Assests/platform/app-img-002.png";
import appImg003 from "../../Assests/platform/app-img-003.png";
import appImg004 from "../../Assests/platform/app-img-004.png";
import appImg005 from "../../Assests/platform/app-img-005.png";
import cloudAuto from "../../Assests/platform/cloud-auto.jpg";
import dataModeling from "../../Assests/platform/data-modeling.jpg";
import dektopAuto from "../../Assests/platform/dektop-auto.jpg";
import desktopAutomationBg from "../../Assests/platform/desktop-automation-bg.jpg";
import devDifferent from "../../Assests/platform/dev-different.jpg";
import digitalPlatform from "../../Assests/platform/digital-platform.jpg";
import entAuto from "../../Assests/platform/ent-auto.jpg";
import entProcessAuto from "../../Assests/platform/ent-process-auto.jpg";
import mobileAppLg001 from "../../Assests/platform/mobile-app-lg-001.png";
import mobileAppLg002 from "../../Assests/platform/mobile-app-lg-002.png";
import mobileAppLg003 from "../../Assests/platform/mobile-app-lg-003.png";
import mobileAppLg004 from "../../Assests/platform/mobile-app-lg-004.png";
import mobileAppLg005 from "../../Assests/platform/mobile-app-lg-005.png";
import mobileAppLg006 from "../../Assests/platform/mobile-app-lg-006.png";
import mobileAppLg007 from "../../Assests/platform/mobile-app-lg-007.png";
import mobileAppLg008 from "../../Assests/platform/mobile-app-lg-008.png";
import mobileAppLg009 from "../../Assests/platform/mobile-app-lg-009.png";
import mobileAppLg010 from "../../Assests/platform/mobile-app-lg-010.png";
import mobileAppLg011 from "../../Assests/platform/mobile-app-lg-011.png";
import mobileAppLg012 from "../../Assests/platform/mobile-app-lg-012.png";
import mobileAppLg013 from "../../Assests/platform/mobile-app-lg-013.png";
import mobileApplication from "../../Assests/platform/mobile-application.jpg";
import mobileBi from "../../Assests/platform/mobile-bi.jpg";
import mobileManagement from "../../Assests/platform/mobile-management.jpg";
import mobilePlatform from "../../Assests/platform/mobile-platform.jpg";
import mobileQuAss from "../../Assests/platform/mobile-qu-ass.jpg";
import servPort001 from "../../Assests/platform/serv-port-001.png";
import servPort002 from "../../Assests/platform/serv-port-002.png";
import servPort003 from "../../Assests/platform/serv-port-003.png";
import servPort004 from "../../Assests/platform/serv-port-004.png";
import servPort005 from "../../Assests/platform/serv-port-005.png";
import ux from "../../Assests/platform/ux.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Platform = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  const Offerings = [
    {
      title: "Mobile Application Development",
      imgSrc: mobileApplication,
      imgAlt: "Mobile Application Development",
      points: [
        "Cross Platform with deep expertise",
        "Impeccable UI Designs",
        "Robust architecture for high performance",
        "Content personalization",
        "Deep integration with cross platform",
        "Consistent look and feel",
      ],
    },
    {
      title: "Mobile Quality Assurance",
      imgSrc: mobileQuAss,
      imgAlt: "Mobile Quality Assurance",
      points: [
        "UI/UX design testing",
        "Functional testing",
        "Performance testing",
        "Test Automation",
      ],
    },
    {
      title: "Mobile BI",
      imgSrc: mobileBi,
      imgAlt: "Mobile BI",
      points: [
        "Transform your data and make information readily available to users for real-time decision making. Empower your organization to visualize the health of its business and take actions anytime, anywhere leading to better business outcomes.",
      ],
    },
    {
      title: "Enterprise Mobile Management",
      imgSrc: mobileManagement,
      imgAlt: "Enterprise Mobile Management",
      points: [
        "Manage users, devices, information, security, updates, and support using our EMM service through customized operating models.",
      ],
    },
  ];
  const technologyLogos = [
    mobileAppLg001,
    mobileAppLg002,
    mobileAppLg003,
    mobileAppLg004,
    mobileAppLg005,
    mobileAppLg006,
    mobileAppLg007,
    mobileAppLg008,
    mobileAppLg009,
    mobileAppLg010,
    mobileAppLg011,
    mobileAppLg012,
    mobileAppLg013,
  ];
  const processSteps = [
    {
      imgSrc: appImg001,
      description: "Discover opportunities for automation",
    },
    {
      imgSrc: appImg002,
      description: "Analyze automation possibilities and tool selection",
    },
    {
      imgSrc: appImg003,
      description: "Develop script on the RPA tool and conduct live trials",
    },
    {
      imgSrc: appImg004,
      description: "Deploy script in a production environment",
    },
    {
      imgSrc: appImg005,
      description: "Pilot POC and collect feedback",
    },
  ];
  const processSteps2 = [
    { imgSrc: servPort001, label: "Discover" },
    { imgSrc: servPort002, label: "Define" },
    { imgSrc: servPort003, label: "Design" },
    { imgSrc: servPort004, label: "Develop" },
    { imgSrc: servPort005, label: "Test" },
  ];
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Platform Services</title>

        <meta name="keywords" content="Platform Services" />

        <meta name="description" content="Platform Services" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="top-global-slide"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/af2ecd48a04ba347c12cd2ad2e13086b.jpg')",
          }}
        >
          <div className="container">
            <h1>Platform</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">
                  Services
                </Link>
              </li>
              <li className="active">Platform</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="consulting-section cloud-computing-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="common-img-overflow">
                    <img src={digitalPlatform} alt="Digital Platform" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="digital-consult-panel margin-zero">
                    <p>
                      Customers do not care about the underlying technology.
                      They only care about how effectively their problem was
                      addressed and solved. That is why we design our products
                      by placing the customer at the center of the digital
                      business model. Smart devices and ease of scaling have
                      enabled businesses to engage with their customers like
                      never. Innovation and expertise in business transformation
                      are now the key metrics determining the success of
                      companies in the future.
                    </p>
                    <p>
                      Companies increasingly understand the potential of the
                      Internet of Things (IoT) to grow their business yet limit
                      themselves to narrow proof of concepts. As a result, many
                      struggle to prove the full value.
                    </p>
                    <p>
                      Implementing a smart, connected strategy using an IoT
                      platform requires a comprehensive, integrated, yet
                      practical approach, combining new technology, skills and
                      capabilities supported by effective organizational change
                      management. It also requires building vendor relationships
                      and a digital ecosystem to provide connectivity, managed
                      services and embedded hardware or software.
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <br />

              <h2>Unlock The Business Value</h2>
              <p>
                As an objective provider of end-to-end solution advisory and
                implementation services, we combine deep experience in strategy,
                process improvement, digital technologies, and organizational
                effectiveness with expertise in IoT deployments.
              </p>

              <p>
                Regardless of existing processes, technical maturity, or
                business objectives, we collaborate with our clients to
                facilitate, guide, and support them through all of phases of the
                digital implementation process to accelerate the value of their
                investment.
              </p>

              <h2>What We Do - Services Portfolio</h2>
              <p>
                Leverage our expertise in platform engineering to ensure
                transformative business results. Explore our portfolio of
                platform engineering offerings to experience the agility and
                quick delivery.
              </p>

              <div className="common-services-panel-area">
                <h2>Mobile Platform Engineering</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={mobilePlatform} alt="Mobile Platform" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Millennial’s demand a better way to engage, connect,
                      communicate, or conduct business with anyone, anywhere,
                      anytime from any web-enabled device. Mobile lies at the
                      heart of this digital revolution. Invest in the digital
                      channel where your customers spend most of their time.
                      It’s time to integrate mobile into the fabric of your
                      business and reap rich rewards. Our Mobile centre of
                      excellence has been delivering superlative mobile
                      experiences to customers of all sizes across industry
                      domains for more than a decade.
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area transparent-bg-services">
                <h2>Offerings</h2>
                <p>
                  Mobile offerings enable our clients to create mobile solutions
                  that facilitate rich, engaging, and productive customer
                  interactions that drive business value. Our full spectrum of
                  mobile services include:
                </p>

                <div className="digital-consulting-option cloud-digital-consulting-option offering-mobile-data">
                  <div className="row">
                    {Offerings.map((option, index) => (
                      <div className="col-md-3" key={index}>
                        <div className="dg-consult">
                          <h3>{option.title}</h3>
                          <img src={option.imgSrc} alt={option.imgAlt} />
                          <ul>
                            {option.points.map((point, i) => (
                              <li key={i}>{point}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area">
                <h2>Featured Technology Specializations</h2>
                <div className="logoworking-technology">
                  {technologyLogos.map((logo, index) => (
                    <div className="custom-colm" key={index}>
                      <span>
                        <img src={logo} alt={`Technology Logo ${index + 1}`} />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <br></br>

              <h2>Digital Process Automation</h2>

              <div className="common-services-panel-area transparent-bg-services">
                <h2>Our Approach</h2>
                <p>
                  Organizations are streamlining data-intensive, time-consuming,
                  repetitive processes using automation. Robotics Process
                  Automation, Cognitive Computing, and Artificial Intelligence
                  are innovating intelligent software system deployment. We
                  enable organizations to build innovative automated solutions
                  for front office, back office, and support functions.
                </p>
                <div className="process-approch">
                  {processSteps.map((step, index) => (
                    <div className="process-ap-panel" key={index}>
                      <div className="icon-overflow">
                        <img src={step.imgSrc} alt={`Step ${index + 1}`} />
                      </div>
                      <p>{step.description}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="common-services-panel-area mrtp-50">
                <h2>Offerings</h2>
                <p>
                  We provide end-to-end open-source solutions that align with
                  cloud solutions for better security, improved productivity,
                  and increased customer retention.
                </p>

                <div className="digital-consulting-option">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="dg-consult">
                        <img
                          src={desktopAutomationBg}
                          alt="Desktop Automation"
                        />
                        <h4>Desktop Automation</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="dg-consult">
                        <img src={entAuto} alt="Enterprise Automation" />
                        <h4>Enterprise Automation</h4>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="dg-consult">
                        <img src={cloudAuto} alt="Cloud Automation" />
                        <h4>Cloud</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="common-services-panel-area transparent-bg-services">
                <h2>Desktop Automation</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      ProCorp’s solutions for desktop automation offering
                      improves accuracy and consistency of IT processes while
                      saving time and cost. We achieve this by automating
                      repetitive and routine IT tasks to reduce downtime and
                      improve incident management processes. Automating
                      repetitive control activities ensure that controls are
                      consistent across systems for easier audits.
                    </p>
                    <p>
                      Through Automation, repetitive tasks are completed
                      quicker, more accurately, and tirelessly as compared to
                      humans. Robots can capture and interpret IT applications
                      to enable transaction processing, data manipulation, and
                      communication across multiple systems. This ‘virtual
                      work-force’ improves the efficiency and effectiveness of
                      operations while providing substantial financial benefits
                      by freeing up humans to do other high value tasks that
                      require emotional intelligence, reasoning, judgment, and
                      interaction with the customers.
                    </p>
                    <h4>Our desktop automation services can cover:</h4>
                    <ul>
                      <li>Spreadsheets</li>
                      <li>Word documents</li>
                      <li>PDFs</li>
                      <li>Emails</li>
                      <li>Data and Analytical tools</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={dektopAuto} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="common-services-panel-area">
                <h2>Enterprise Process Automation</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={entProcessAuto} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Enable your business to grow and enhance customer service
                      without leveraging any additional resources. Through our
                      automation readiness assessments and end-to-end of
                      enterprise automation roadmaps, we provide custom
                      solutions that are tailored to meet your needs.
                    </p>
                    <h4>
                      Our offerings in enterprise process automation include the
                      following:
                    </h4>
                    <ul>
                      <li>Workflow and Rules</li>
                      <li>Imaging</li>
                      <li>Digital Channels</li>
                      <li>Analytics/Reporting</li>
                      <li>Collaboration Tools</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="common-services-panel-area transparent-bg-services">
                <h2>Our Differentiators</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      With state-of-the-art Robotic Process Automation (RPA), we
                      significantly reduce the burden of recurring
                      decision-making tasks and frees human creativity for
                      strategic roles. Our key differentiators are:
                    </p>

                    <ul>
                      <li>
                        Dedicated RPA CoE – an elite team of requirement
                        analysts, senior developers equipped with RPA process
                        and technology skills
                      </li>
                      <li>
                        Expertise across a broad spectrum of RPA technologies
                        and tool vendors ( UiPath , KOFAX , Automation Anywhere,
                        BluePrism)
                      </li>
                      <li>
                        5-step transformation methodology to fast-track
                        digitization, standardization, and simplification of
                        upstream and downstream processes
                      </li>
                      <li>
                        10+ support function processes automation experience
                      </li>
                      <li>
                        Successful implementation across 500+ FTE with best
                        practices
                      </li>
                      <li>
                        Global delivery and flexible engagement models that
                        guarantee faster implementation and cost-effective
                        solutions
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={devDifferent} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />

              <h2>Experience Engineering</h2>
              <p>
                Customers no longer buy products and services; they buy
                experiences delivered via products and services. Be it
                conversions, user engagement, or customer satisfaction, digital
                experience plays a pivotal role in the digital economy.
                Enterprises of all sizes have started recognizing this need to
                create meaningful and memorable experiences that customers of
                all ages love.
              </p>

              <br />
              <div className="common-services-panel-area transparent-bg-services">
                <h2>What we do – Services portfolio</h2>

                <p>
                  We follow the best practices across a variety of disciplines
                  such as visual design, usability, information architecture,
                  and interaction design to create immersive, seamless user
                  experiences that consistently appeal to your customers.
                </p>

                <div className="process-approch">
                  {processSteps2.map((step, index) => (
                    <div key={index} className="process-ap-panel">
                      <div className="icon-overflow">
                        <img src={step.imgSrc} alt={step.label} />
                      </div>
                      <p>{step.label}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="common-services-panel-area">
                <h2>User Experience</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={ux} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Through intuitive and elegant user experiences, we create
                      memorable moments by prioritizing user needs.
                    </p>
                    <h4>What we provide</h4>
                    <ul>
                      <li>
                        Connecting every journey to create a consistent and
                        intuitive 360-degree experience
                      </li>
                      <li>Building interactive and responsive designs</li>
                      <li>
                        Applying deep understanding about customer interests,
                        gained through analytics, IoT and artificial
                        intelligence
                      </li>
                      <li>
                        Creating custom product recommendations using analytics
                      </li>
                      <li>
                        Building omnichannel experiences for B2B customers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area mrtp-50">
                <h2>Our Differentiators</h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={devDifferent} alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p>
                      Understanding user behavior, needs, and motivation is
                      critical to the success of any UX project. Our process of
                      UX optimization has users at its core. Our experienced UI
                      architects, visual interface and interaction designers are
                      highly qualified and adept at creating captivating
                      experiences for your customers for
                    </p>
                    <ul>
                      <li>Design Thinking</li>
                      <li>Research-led Innovation</li>
                      <li>Industry-specific design</li>
                      <li>Personalized through predictive analytics</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="platform-heading-para">
                <div className="row">
                  <div className="col-md-6">
                    <div className="platform-common-panel">
                      <h3>Quality Engineering</h3>
                      <p>
                        Quality Assurance (QA) services enable you to minimize
                        defects and optimize the utilization of your system,
                        resulting in higher quality and lower cost. Make your
                        applications world-ready with our full spectrum of QE
                        and QA services. With our extensive experience in
                        creating and executing QA strategies, we take end-to-end
                        responsibility for your QE and QA requirements for
                        improved software lifecycle efficiency and accelerated
                        time-to-market.
                      </p>
                    </div>

                    <div className="platform-common-panel">
                      <h3>Offerings</h3>
                      <p>
                        Explore our portfolio of cognitive offerings for
                        End-to-end Manual &amp; Automated Quality Assurance.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="platform-common-panel">
                      <h3>Test Engineering</h3>
                      <p>
                        Test engineering leverages the cognitive capabilities of
                        the tester to improve the quality, user experience, and
                        usability of the software application. Our services
                        include functional testing, usability, integration,
                        regression testing, and smoke testing.
                      </p>
                    </div>

                    <div className="platform-common-panel">
                      <h3>Test Automation ( AI-MLOps)</h3>
                      <p>
                        Improve efficiency and plan faster release cycles with
                        our structured approach to automated design and
                        development. Test automation helps reduce regression
                        testing time and slashes the time-to-market, leading to
                        significant cost savings in the long haul.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="common-services-panel-area transparent-bg-services">
                <h2>Rapid Application Testing</h2>
                <div className="row">
                  <div className="col-md-6">
                    <p>
                      Testing early and frequently ensures high quality
                      throughout the software lifecycle. Rapid Applicating
                      testing provides instant feedback for developers to work
                      with, creating apps that are secure as they arrive.
                    </p>
                    <h4>Key benefits:</h4>
                    <ul>
                      <li>Predictable quality</li>
                      <li>Defect prevention</li>
                      <li>High test coverage</li>
                      <li>Early feedback</li>
                      <li>Dev-QA collaboration</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="common-image-overflow">
                      <img src={dataModeling} alt="dataModeling" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="platform-heading-para">
                <div className="row">
                  <div className="col-md-6">
                    <div className="platform-common-panel">
                      <h3>API Test Automation</h3>
                      <p>
                        Begin the testing phase even before the UI is ready.
                        Validate and verify the business logic by testing and
                        certifying the APIs with our API test automation
                        services.
                      </p>
                    </div>

                    <div className="platform-common-panel">
                      <h3>DevOps Testing</h3>
                      <p>
                        » Assessment of the roadmap for continuous delivery »
                        Standardized tools, processes, and frameworks »
                        End-to-end toolchain and integration » Automated
                        solutions for continuous testing
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="platform-common-panel">
                      <h3>Performance Test Engineering</h3>
                      <p>
                        We provide end-to-end performance testing services by
                        adopting best practices, standards, methodologies, and a
                        tailored approach specific to your requirements.
                        Performance testing helps businesses evaluate the
                        quality of their application in terms of responsiveness,
                        speed, scalability, and stability under a variety of
                        load conditions.
                      </p>
                    </div>

                    <div className="platform-common-panel">
                      <h3>Our Differentiators</h3>
                      <p>
                        With our 20+ years of experience in providing manual
                        testing and test automation services to several Fortune
                        1000 clients, we have fine-tuned our processes to save
                        the time and cost for our customers.
                      </p>
                      <h4>
                        Our Solutions enhance your testing process by providing:
                      </h4>
                      <ul>
                        <li>Business agility through shortened test cycles</li>
                        <li>
                          Improved efficiency by systematically uncovering
                          defects in early SDLC phases
                        </li>
                        <li>An adaptable test automation suite</li>
                        <li>Enhanced quality at minimal cost</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Platform;
