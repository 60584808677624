import React, { useEffect, useState } from "react";
import "../css/Navbar.css";
import logo from "../Assests/logo/logo.png";
import Modal from "react-modal";
import { Link, useLocation } from "react-router-dom";
import "../css/Form.css";
import axios from "axios";
import { Helmet } from "react-helmet";

Modal.setAppElement("#root");

const CustomNavbar = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the specific section when the location pathname or hash changes
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
      zIndex: 1020, // Ensure it overlays all content
    },
    content: {
      width: "680px",
      maxWidth: "97%",
      inset: "0px",
      maxHeight: '100%',
      // height: "500px",
      margin: "90px auto auto auto ",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: "none",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",

      position: "relative", // For positioning the close button
      transition: "all 0.3s ease", // Smooth transition effect
    },
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [showMenu, setShowMenu] = useState(true);

  const talktoMenu = () => {
    setShowMenu(!showMenu);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const response = await axios.post(
        "https://procorpsystems.co/set-pref",
         formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully!");
      } else {
        console.error("There was an error submitting the form.");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
    closeModal();
  };
  return (
    <>
    <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

      </Helmet>
      <div className="m-0 sticky-top">
        <nav
          id="main-navbar"
          class="navbar m-0 navbar-expand-lg navbar-dark custom-navbar p-0"
        >
          <div class="container-fluid p-0 ">
            <Link class="navbar-brand py-0" to="/">
              <img className="mx-md-4" src={logo} style={{ maxWidth: 280 }} />
            </Link>

            <div class="w-100 navbar-toggler border border-0">
              <div className="d-flex justify-content-between border-none align-items-center">
                <span className="text-light nav-text"> NAVIGATE</span>
                <span
                  border-none
                  className={`navbar-toggler  border-none ${menuOpen ? "active" : ""
                    }`}
                  onClick={toggleMenu}
                  data-bs-toggle="collapse"
                  data-bs-target="#basic-navbar-nav"
                  aria-controls="navbarNav"
                  aria-expanded={menuOpen}
                  aria-label="Toggle navigation"
                >
                  <span className="toggle-menu  border-none ">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </span>
                <span className="nav-item dropdown custom-dropdown">
                  <Link
                    className="nav-link talk-to-expert"
                    id="talkto-dropdown"
                    role="button"
                    onClick={talktoMenu}
                    aria-expanded={showMenu ? "true" : "false"}
                  >
                    <span className="drop-menu-item">TALK TO AN EXPERT</span>
                  </Link>
                  {showMenu && (
                    <div
                      className="talkto-menu"
                      aria-labelledby="talkto-dropdown"
                    >
                      <div className="arrow"></div>
                      {/* Arrow element added here */}
                      <p className="text-center m-0"></p>
                      <div className="p-3 box">
                        <h4>We're ready to help</h4>
                        <p>
                          Our ProCorp experts can answer your questions and
                          provide a free assessment.
                        </p>
                        <ul>
                          <li role="button" onClick={openModal}>
                            <span className="dropdown-item py-2+">
                              <i className="bi bi-calendar2-week-fill mx-3 "></i>
                              Schedule a meeting
                            </span>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item py-2"
                              to="tel:14693670669"
                            >
                              <i className="bi bi-telephone mx-3"></i> 1
                              469-367-0669
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item py-2"
                              to="mailto:sales@procorpsystems.co"
                            >
                              <i className="bi bi-envelope mx-3"></i>
                              sales@procorpsystems.co
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="collapse-btn" onClick={talktoMenu}>
                        collapse
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <br />

            <div class="collapse navbar-collapse p-0" id="basic-navbar-nav">
              <div className=" cust-navbar-collapse">
                <ul class="navbar-nav m-0 ms-auto">
                  <li class="nav-item dropdown custom-dropdown">
                    <Link
                      class="nav-link "
                      to="about"
                      id="about-dropdown"
                      role="button"
                    >
                      <span className="drop-menu-item">About</span>
                      <i class="bi bi-chevron-down "></i>
                    </Link>
                    <ul class="dropdown-menu" aria-labelledby="about-dropdown">
                      <li>
                        <Link class="dropdown-item" to="about#who-we-are">
                          <i class="bi bi-chevron-right "></i>
                          Who We Are
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/about#what-we-do">
                          <i class="bi bi-chevron-right "></i>
                          What We Do
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/about#our-mission">
                          <i class="bi bi-chevron-right "></i>
                          Our Mission
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown custom-dropdown">
                    <Link
                      class="nav-link "
                      to="/services"
                      id="services-dropdown"
                      role="button"
                    >
                      <span className="drop-menu-item">Services</span>
                      <i class="bi bi-chevron-down "></i>
                    </Link>
                    <ul class="dropdown-menu" aria-labelledby="about-dropdown">
                      <li>
                        <Link class="dropdown-item" to="services/consulting">
                          <i class="bi bi-chevron-right "></i>
                          Consulting
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="services/cloud">
                          <i class="bi bi-chevron-right "></i>
                          Cloud
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="services/platform">
                          <i class="bi bi-chevron-right "></i>
                          Platform
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="services/data">
                          <i class="bi bi-chevron-right "></i>
                          Data
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown custom-dropdown">
                    <Link
                      class="nav-link "
                      to="/solution"
                      id="solutions-dropdown"
                      role="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    >
                      <span className="drop-menu-item">Solutions</span>
                      <i class="bi bi-chevron-down "></i>
                    </Link>
                    <ul class="dropdown-menu" aria-labelledby="about-dropdown">
                      <li>
                        <Link class="dropdown-item" to="/solution/microservices">
                          <i class="bi bi-chevron-right "></i>
                          Microservices
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/solution/apieconamy">
                          <i class="bi bi-chevron-right "></i>
                          API Economy
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="/solution#blockchain">
                          <i class="bi bi-chevron-right "></i>
                          Block Chain
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="solution/openbanking">
                          <i class="bi bi-chevron-right "></i>
                          Open Banking
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="solution/payments">
                          <i class="bi bi-chevron-right "></i>
                          Payments
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item dropdown custom-dropdown">
                    <Link
                      class="nav-link "
                      to="/partners"
                      id="partners-dropdown"
                      role="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    >
                      <span className="drop-menu-item">Partners</span>
                      <i class="bi bi-chevron-down "></i>
                    </Link>
                    <ul class="dropdown-menu" aria-labelledby="about-dropdown">
                      <li>
                        <Link class="dropdown-item" to="partners#amazon-partners">
                          <i class="bi bi-chevron-right "></i>
                          Amazon
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="partners#microsoft-partners">
                          <i class="bi bi-chevron-right "></i>
                          MicroSoft
                        </Link>
                      </li>
                      <li>
                        <Link class="dropdown-item" to="partners#salesforce-partners">
                          <i class="bi bi-chevron-right "></i>
                          SalesForce
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="resources">
                      Resources
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link class="nav-link" to="procorp-global-locations">
                      Global Location
                    </Link>
                  </li>
                </ul>
                <span className="nav-item dropdown custom-dropdown talk-to-us-mobile">
                  <Link
                    className="nav-link talk-to-expert"
                    id="talkto-dropdown"
                    role="button"
                    onClick={talktoMenu}
                    aria-expanded={showMenu ? "true" : "false"}
                  >
                    <span className="drop-menu-item" >TALK TO AN EXPERT</span>
                  </Link>
                  {showMenu && (
                    <div
                      className="talkto-menu"
                      aria-labelledby="talkto-dropdown"
                    >
                      <div className="arrow"></div>
                      {/* Arrow element added here */}
                      <p className="text-center m-0"></p>
                      <div className="p-3 box">
                        <h4>We're ready to help</h4>
                        <p>
                          Our ProCorp experts can answer your questions and
                          provide a free assessment.
                        </p>
                        <ul>
                          <li role="button" onClick={openModal}>
                            <span className="dropdown-item py-2">
                              <i className="bi bi-calendar2-week-fill mx-3 "></i>
                              Schedule a meeting
                            </span>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item py-2"
                              to="tel:14693670669"
                            >
                              <i className="bi bi-telephone mx-3"></i> 1
                              469-367-0669
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item py-2"
                              to="mailto:sales@procorpsystems.co"
                            >
                              <i className="bi bi-envelope mx-3"></i>
                              sales@procorpsystems.co
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="collapse-btn" onClick={talktoMenu}>
                        collapse
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="modal-dialog nav-modal m-0 custom-talk-formpx-3">
          <div className="modal-header schedule-header px-2  ">
            <h2 className="my-1 w-100">Schedule a meeting</h2>

            <div>
              <span
                onClick={closeModal}
                aria-hidden="true"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg " style={{ fontSize: 30 }}></i>
              </span>
            </div>
          </div>
          <div className="custom-talk-form mt-5">
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group  ">
                <div className="input-field">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="firstName">
                    <span className="cover-bg">First Name</span>
                  </label>
                </div>
                <div className="input-field">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="lastName">
                    <span className="cover-bg">Last Name</span>
                  </label>
                </div>
              </div>

              <div className="form-group ">
                <div className="input-field">
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="companyName">
                    <span className="cover-bg">Company Name</span>
                  </label>
                </div>
                <div className="input-field">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="email">
                    <span className="cover-bg">Email</span>
                  </label>
                </div>
              </div>

              <div className="form-group ">
                <div className="input-field">
                  <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="phoneNumber">
                    <span className="cover-bg">Phone Number</span>
                  </label>
                </div>
                <div className="input-field">
                  <input
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                  <label htmlFor="message">
                    <span className="cover-bg">Message</span>
                  </label>
                </div>
              </div>

              <div className="d-flex justify-content-end">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomNavbar;
