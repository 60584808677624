import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";

import solutionportfolio1 from "../../Assests/Solution/solution-portfolio-img-001.png";
import solutionportfolio2 from "../../Assests/Solution/solution-portfolio-img-002.png";
import solutionportfolio3 from "../../Assests/Solution/solution-portfolio-img-003.png";
import solutionportfolio4 from "../../Assests/Solution/solution-portfolio-img-0004.png";
import solutionportfolio5 from "../../Assests/Solution/solution-portfolio-img-005.png";
import solutionportfolio6 from "../../Assests/Solution/solution-portfolio-img-004.png";
import solutionportfolio7 from "../../Assests/Solution/solution-portfolio-img-007.png";

import digitalcustomer from "../../Assests/Solution/digital-customer.jpg";
import procorpleadereng from "../../Assests/Solution/procorp-leader-eng.png";
import newmicro from "../../Assests/Solution/new-micro.jpg";
import newapicode from "../../Assests/Solution/new-api-code.jpg";
import Blockchaintransparent from "../../Assests/Solution/Blockchain-transparent.png";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Solution = () => {
  useEffect(() => {
    AOS.init(); 
  }, []);


  const solutionPortfolioData = [
    {
      imgSrc: solutionportfolio1,
      title: "Integration Strategy",
      lists: [
        [
          "Legacy Migration Strategy",
          "Master Data Management",
          "Data Encryption",
          "High Volume Strategy",
        ],
        [
          "Legacy Migration Strategy",
          "Master Data Management",
          "Data Encryption",
          "High Volume Strategy",
        ],
      ],
    },
    {
      imgSrc: solutionportfolio2,
      title: "Governance",
      lists: [
        ["COE", "Change management", "Release management"],
        ["Building blocks", "Repeatable processes", "Innovation enablement"],
      ],
    },
    {
      imgSrc: solutionportfolio3,
      title: "Integration Strategy",
      lists: [
        ["Master Data Strategy", "Integration Patterns", "Integration Tools"],
        [
          "Reference Architecture",
          "Integration roadmap",
          "Clear Integration Strategy",
        ],
      ],
    },
    {
      imgSrc: solutionportfolio4,
      title: "Assessment & Strategy",
      lists: [
        [
          "Performance & Scalability",
          "Application Usability",
          "Security & Visibility",
          "Integration Patters",
        ],
        [
          "Keep teams true to the mission",
          "Reduce over-engineering",
          "Clean up Technical Debt",
        ],
      ],
    },
    {
      imgSrc: solutionportfolio5,
      title: "Enterprise Strategy",
      lists: [
        ["Roadmap & Prioritization", "Business/IT Alignment"],
        [
          "MDM",
          "Identity management",
          "Information security",
          "Security Policies",
        ],
      ],
    },
    {
      imgSrc: solutionportfolio6,
      title: "Application Rationalization",
      lists: [
        ["New Clouds", "Legacy/Homegrown Apps", "Feasibility"],
        ["Platform Analysis", "Decisions based on a scorecard"],
      ],
    },
    {
      imgSrc: solutionportfolio7,
      title: "Platform Testing",
      lists: [["Test Planning Application Tuning"], ["Optimized Performance"]],
    },
  ];
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Solutions</title>

        <meta name="keywords" content="Solutions" />

        <meta name="description" content="Solutions" />
      </Helmet>
      <div>
        <section className="global-web-page">
          <section
            className="top-global-slide"
            style={{
              backgroundColor: "#454545",
              backgroundImage:
                "url('/Images/ee93e4ef01a9434e0259f00d2d492907.jpg')",
            }}
          >
            <div className="container">
              <h1>Solutions</h1>

              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li className="active">Solutions</li>
              </ul>
            </div>
          </section>

          <section className="gray-shade-bg">
            <div className="solutions">
              <div className="container">
                <h2>Overview</h2>
                <h3>
                  Modernization Strategies as a Foundation for Digital
                  Transformation{" "}
                </h3>

                <div className="common-solution-img-text">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="image-overflow-panel">
                        <img src={digitalcustomer} alt="digital-customer" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p>
                        Enterprises today operate in the age of the digital
                        customer where they must deal with an ever-changing set
                        of expectations. It demands companies to build and
                        provide engaging customer experiences. No matter your
                        industry, you've either learned this, or soon will: the
                        era of monolithic service architectures is over. As the
                        need for a nimbler enterprise takes priority,
                        organizations of all types are switching to
                        microservices. A microservices architecture can offer a
                        more scalable, resilient, secure enterprise — all while
                        streamlining the process and time it takes to build and
                        ship working improvements to any application.
                      </p>
                    </div>
                  </div>
                </div>

                <h3>Application and Platform leaders </h3>

                <div className="common-solution-img-text">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        often struggle to develop successful application
                        modernization business cases, especially across multiple
                        platforms. Those who succeed take a business-focused
                        approach, chunking the work to target the most critical
                        business capabilities, and delivering in multiple
                        waves."
                      </p>
                    </div>
                  </div>
                </div>

                <h3>Key Challenges</h3>

                <div className="common-solution-img-text">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        Application leaders often find it challenging to create
                        a modernization business case that convinces management
                        – especially business stakeholders – to invest, with
                        case weakness often stemming from taking an IT-centric
                        approach.
                      </p>
                    </div>
                  </div>
                </div>

                <h3>Procorp’s Thought Leadership in Value Engineering</h3>

                <div className="common-solution-img-text">
                  <div className="row">
                    <div className="col-md-6">
                      <p>
                        Application groups allow modernized applications to
                        languish without continual fit-for-purpose review,
                        treating modernization as a one-time event rather than
                        an ongoing practice. The key to winning the age of the
                        digital customer lies in improving speed and agility. It
                        is challenging to scale Monolithic architecture and
                        hence does not lend itself to agile deployments.
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="image-overflow">
                        <img src={procorpleadereng} alt="procorp-leader-eng" />
                      </div>
                    </div>
                  </div>
                </div>

                <div classNameName="common-services-panel-area solution-portfolio-section">
                  <h2>What we do – Solution Portfolio</h2>
                  <div classNameName="row">
                    {solutionPortfolioData.map((item, index) => (
                      <div classNameName="col-md-4" key={index}>
                        <div classNameName="solution-portfolio-panel">
                          <div classNameName="icon-overflow-portfolio">
                            <img src={item.imgSrc} alt={item.title} />
                          </div>
                          {item.lists.map((list, listIndex) => (
                            <div key={listIndex}>
                              <h4>
                                {listIndex === 0
                                  ? item.title
                                  : "Long Term Success"}
                              </h4>
                              <ul>
                                {list.map((li, liIndex) => (
                                  <li key={liIndex}>{li}</li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <Link name="micro"></Link>
            <section className="about-section microservices-section">
              <div className="container">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  Microservices
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    {/* <!-- <div className="micro-service-bg" data-aos="fade-up" data-aos-delay="500"></div> --> */}
                    <div
                      className="image-overflow"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <img src={newmicro} alt="new-micro" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="about-content-panel"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <p>
                        Microservices help overcome these shortcomings of
                        monolithic architecture and provide a solution that
                        helps systems and applications be as modular as
                        possible. It comes with a pre-integrated eco-system with
                        architectural reference tools that ensure best practices
                        for NFR’s (Non-Functional Requirements) like logging,
                        monitoring, discovery, security. Microservices reference
                        architecture results in a system that is scalable,
                        agile, and flexible. With our Microservices and API
                        solutions, companies can iterate quickly, simplify
                        deployment, and shorten the time-to-market.
                      </p>
                      <p>
                        <strong>
                          {" "}
                          ProCorp’s Microservices Reference Architecture
                        </strong>{" "}
                        defines ownership boundaries to each system component
                        and provides the ability to adopt any framework for
                        different parts. It aims to increase agility by
                        decoupling system components and reducing the cost of
                        application scaling. By creating a reference
                        architecture, we have mastered the art of achieving the
                        transformation from monoliths to microservices.
                      </p>
                    </div>
                    <Link to="/solution/microservices" className="border-btn">
                      Learn More{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <Link name="apieconomy"></Link>
            <section className="api-economy-section">
              <div className="container">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  API Economy
                </h2>
                <div className="row">
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <p>
                      The API economy refers to the set of business models and
                      practices designed around the use of APIs in today's
                      digital economy. It involves the exposure of an
                      organization's digital services and assets through
                      application programming interfaces (APIs) in a controlled
                      way.
                    </p>
                    <p>
                      Applications and their underlying data are
                      long-established cornerstones of many organizations. All
                      too often, however, they have been the territory of
                      internal R&D and IT departments. From the earliest days of
                      computing, systems have had to talk to each other to share
                      information across physical and logical boundaries and
                      solve for the interdependencies inherent in many business
                      scenarios. The trend toward integration has been steadily
                      accelerating over the years. It is driven by increasingly
                      sophisticated ecosystems and business processes that are
                      supported by complex interactions across multiple
                      endpoints in custom software, in-house packaged
                      applications, and third-party services (cloud or
                      otherwise).
                    </p>
                    <p>
                      The growth of APIs stems from an elementary need: a better
                      way to encapsulate and share information and enable
                      transaction processing between elements in the solution
                      stack. Unfortunately, APIs have often been treated as
                      tactical assets until relatively recently. APIs, their
                      offspring of EDI and fuel the new API economy.
                    </p>
                    <Link to="/solution/api-economy" className="border-btn">
                      Learn More{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="image-overflow">
                      <img src={newapicode} />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Link name="blockchain"></Link>
            <section className="about-section microservices-section" id="blockchain">
              <div className="container">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  Blockchain
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    <strong
                      style={{
                        fontSize: "24px",
                        fontWeight: "400",
                        margin: "0 0 20px",
                        display: "block",
                      }}
                    >
                      What we do – Solution Portfolio
                    </strong>
                    <div
                      className="imgage-overflow blockchain-transparent-overflow"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <img
                        src={Blockchaintransparent}
                        alt="Blockchain-transparent"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="about-content-panel"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <p>
                        Blockchain technology offers a way for market
                        participants to access dematerialized assets directly
                        without always going through other participants
                        needlessly
                      </p>
                      <p>
                        <strong>Centralized Repository</strong> (today’s
                        system): most participants are disconnected from their
                        asset depository, settling transaction would require
                        participants to collaborate in a flow that is slow,
                        inefficient, and expensive
                      </p>
                      <p>
                        <strong>Shared Repository:</strong> all participants can
                        interact with depository directly without going
                        involving third parties, potentially making post trade
                        operations cheaper and faster
                      </p>
                      <p>
                        <strong>
                          Trading, clearing, and settlement functions
                        </strong>{" "}
                        can all be automated on a blockchain network using smart
                        contracts and oracles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <Link name="openbanking"></Link>
            <section className="open-banking-section">
              <div className="container">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  Open Banking
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className="content-paragraph"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <p>
                        New technologies and business models are redefining bank
                        operating models and how banks interact with customers.
                        Although once a leading technology adopter, banking now
                        lags other industries in terms of digital
                        transformation.
                      </p>

                      <p>
                        Traditional banks need to modernize to offer a relevant,
                        engaging real-time customer experience and to fend off
                        challengers. New banks need the benefits of modern
                        design, personalized products and services, low fees,
                        and snappy customer service to tempt people in. All
                        banks need to choose their technology providers and
                        advisors carefully to ensure their modernization journey
                        is a success.
                      </p>

                      <h4>The roadmap to digitization</h4>

                      <p>
                        Customers are restive, with high expectations that are
                        increasingly set by their experiences outside of
                        financial services. Many banks are evaluating how they
                        can gain the agility and flexibility they need to meet
                        rising customer expectations
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 banking-list">
                    <div
                      className="banking-potfolio-image"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    ></div>
                    <ul>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        API Management Services
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        TPP Authorization Services
                      </li>
                      <li>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>{" "}
                        Operational management (24/7) of the entire
                        infrastructure
                      </li>
                    </ul>
                    <Link to="/solution/open-banking" className="border-btn">
                      Learn More{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </section>

            <Link name="payments"></Link>
            <section className="payment-section">
              <div className="container">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  Payments
                </h2>
                <div className="row">
                  <div
                    className="col-md-6"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="payment-panel-bg"></div>
                  </div>
                  <div className="col-md-6">
                    <p data-aos="fade-up" data-aos-delay="500">
                      The global cards and payments industry is witnessing a
                      diminishing trend in traditional banking and constantly
                      progressing towards emerging digital technologies. To keep
                      up with the trend, financial institutions are adopting to
                      new technologies and complying with constantly changing
                      regulations / compliances. Looking ahead, we must adapt –
                      providing solutions that solve customer challenges when
                      and where needed. Our offerings are designed to be
                      frictionless, be automated, and promote growth beyond to
                      radically transform the way we operate to drive global
                      standardization and scalability.
                    </p>

                    <p data-aos="fade-up" data-aos-delay="700">
                      <strong>Cards & Payments</strong> business is one such
                      segment within the financial services industry which
                      exemplifies this business trend. As digital commerce grows
                      more rapidly, both the incumbents and challengers must
                      find ways to reduce friction in payments and improve
                      security to win in the marketplace. Digitization in
                      payments will continue to make it ubiquitous and embedded
                      but it is equally important to make it more secure as
                      well.
                    </p>

                    <p data-aos="fade-up" data-aos-delay="700">
                      <strong>ProCorp has rich experience</strong> in handling
                      complex and diverse platforms across every aspect of Cards
                      & Payments value chain covering card networks, card
                      processors, merchant acquirers, payments service providers
                    </p>

                    <Link to="payments" className="border-btn black">
                      Learn More{" "}
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
      </div>
      
    </>
  );
};

export default Solution;
