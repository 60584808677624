import React from "react";
import openbanking from "../../Assests/openbanking/open-banking.jpg";
import accounticoncenter from "../../Assests/openbanking/account-icon-center.png";
import paymenticoncenter from "../../Assests/openbanking/payment-icon-center.png";
import roadmap from "../../Assests/openbanking/road-map.jpg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const Openbanking = () => {
  const services = [
    {
      title: "API Management Services",
      description:
        "Complete Management of ASPSP XS2A APIs (versioning, maintenance)",
    },
    {
      title: "TPP Authorization Services",
      description:
        "Identification of authorized TPP’s through PRETA and trusted service providers.",
    },
    {
      title: "TPP Support Services",
      description: "Management of TPP Onboarding and mandatory TPP Helpdesk",
    },
    {
      title: "Monitoring & Reporting",
      description:
        "Reporting dashboards related to API Interfaces Availability and Performance of API Interfaces supervised by SOC",
    },
    {
      title: "Operational management (24/7) of the entire infrastructure",
      description: "Maintenance of the entire infrastructure.",
    },
    {
      title: "Easy to implement solution",
      description:
        "Partnerships with core banking systems (Plug & Play) Fast implementation through standardization",
    },
  ];
  const oneFramework = [
    {
      title: "Account aggregation",
      imageSrc: accounticoncenter,
      altText: "Account Aggregation",
      description:
        "Access bank account information on behalf of the end user and build innovative solutions based on real-time data. Here are just a few examples of solutions you can build using the data accessible via our <strong>Hub ONE Framework ™:</strong>",
      listItems: [
        "360° view of user accounts",
        "Personal financial management",
        "Income verification",
        "Credit Scoring and Up-selling / Cross-selling",
      ],
    },
    {
      title: "Payment initiation",
      imageSrc: paymenticoncenter,
      altText: "Payment Initiation",
      description:
        "<strong>Hub ONE Framework ™:</strong> One can help your users to initiate payments for any purpose integrating with leading API Gateways, for example:",
      listItems: [
        "Deposit money to their account within your application",
        "Pay invoices",
        "Transfer money between friends",
        "Setup recurring money transfers",
        "Move money between company accounts (treasury management)",
      ],
    },
  ];
  return (
    <>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="csrf-token"
          content="qmaBSzMPyK7ShPRhlYpgW4KfNkFY8JCLG2QNxaXm"
        />

        <title>Open Banking</title>

        <meta name="keywords" content="Open Banking" />

        <meta name="description" content="Open Banking" />
      </Helmet>
      <section className="global-web-page">
        <section
          className="global-slide-lg"
          style={{
            backgroundColor: "#454545",
            backgroundImage:
              "url('/Images/1ebd57eeb7a45bdb1bd743d2db7cf81d.jpg')",
          }}
        >
          <div className="container">
            <h1>Open Banking</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/solution">
                  Solutions
                </Link>
              </li>
              <li className="active">Open Banking</li>
            </ul>
          </div>
        </section>

        <section className="gray-shade-bg">
          <section className="detail-page-section">
            <div className="detail-top">
              <div className="container">
                <h2>Open Banking</h2>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <section className="consulting-section cloud-computing-section">
                    <div className="row">
                      <div className="col-md-6" style={{ margin: "0 0 30px" }}>
                        <div className="common-image-overflow">
                          <img src={openbanking} alt="Open Banking" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="digital-consult-panel margin-zero">
                          <p>
                            New technologies and business models are redefining
                            bank operating models and how banks interact with
                            customers. Although once a leading technology
                            adopter, banking now lags other industries in terms
                            of digital transformation.
                          </p>
                          <p>
                            Traditional banks need to modernize to offer a
                            relevant, engaging real-time customer experience and
                            to fend off challengers. New banks need the benefits
                            of modern design, personalized products and
                            services, low fees, and snappy customer service to
                            tempt people in. All banks need to choose their
                            technology providers and advisors carefully to
                            ensure their modernization journey is a success.
                          </p>
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="common-services-panel-area transparent-bg-services">
                      <h2>The roadmap to digitization</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <p>
                            Customers are restive, with high expectations that
                            are increasingly set by their experiences outside of
                            financial services. Many banks are evaluating how
                            they can gain the agility and flexibility they need
                            to meet rising customer expectations. This requires
                            a sustainable, modern banking platform that’s built
                            on four principles:
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="common-image-overflow">
                            <img src={roadmap} alt="Roadmap to Digitization" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="common-services-panel-area transparent-bg-services">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="text-content-cem">
                            <h4>Redefining the core</h4>
                            <p>
                              The core system is the beating heart of the bank.
                              It contains all the primary records of customers,
                              accounts, and transactions. But over time, the
                              core has taken on more activity than originally
                              intended. It is increasingly expected to interact
                              with customers using any device in real time,
                              24/7. A modern core enables your bank to become
                              more agile and customer centric.
                            </p>
                          </div>
                          <div className="text-content-cem">
                            <h4>Embracing the cloud</h4>
                            <p>
                              With the promise of lower costs, elastic scale and
                              pay-per-use, many banks have already moved
                              non-critical applications, such as customer
                              relationship management or email, into the cloud.
                              With advancements in cloud security and
                              regulation, banks are now looking to use the cloud
                              for mission-critical applications, such as core
                              processing. Many are increasingly interested in
                              cloud-native applications.{" "}
                            </p>
                            <p>
                              A modern core empowers you to migrate to a
                              lower-cost cloud environment with minimal
                              disruption to business as usual.
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-content-cem">
                            <h4>Driving digital uptake</h4>
                            <p>
                              Corporate giants such as Amazon, Facebook, Google,
                              and Netflix have taken customer experience and
                              service to a whole new level. To remain
                              competitive, banks must offer a compelling
                              customer experience from the moment of first
                              contact. A positive account opening experience,
                              coupled with frictionless servicing, insights, and
                              advice, will build the most important component of
                              all: customer loyalty. A modern core is the
                              essential foundation for exemplary and consistent
                              customer experience
                            </p>
                          </div>
                          <div className="text-content-cem">
                            <h4>
                              Adapting to an ever-changing and complex
                              regulatory environment
                            </h4>
                            <p>
                              Banks worldwide face a growing volume of complex
                              regulations, and many banks must comply with
                              regulations in multiple jurisdictions. A modern,
                              cloud-enabled core empowers banks to adapt to
                              regulatory change in global locations without
                              affecting the core code.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2
                      style={{ fontSize: "35px", lineHeight: "48px" }}
                      className="mt-5"
                    >
                      Benefit from Open Banking with our Hub ONE Framework{" "}
                      <sup>™</sup>
                    </h2>

                    <div className="common-services-panel-area transparent-bg-services">
                      <div className="row">
                        {oneFramework.map((service, index) => (
                          <div className="col-md-6" key={index}>
                            <div className="banking-agg">
                              <div className="icon-center-banking">
                                <img
                                  src={service.imageSrc}
                                  alt={service.altText}
                                />
                              </div>
                              <h3>{service.title}</h3>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: service.description,
                                }}
                              />
                              <ul>
                                {service.listItems.map((item, itemIndex) => (
                                  <li key={itemIndex}>{item}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <h2 className="mt-5">What we do – Services Portfolio</h2>

                    <div className="common-services-panel-area transparent-bg-services">
                      <div className="row">
                        {services.map((service, index) => (
                          <div className={`col-md-6`} key={index}>
                            <div className="text-content-cem banking-text-content-cem">
                              <h4>{service.title}</h4>
                              <p>{service.description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  );
};

export default Openbanking;
