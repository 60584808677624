import React from 'react'
import "../css/TalktoUs.css"
import calender from "../Assests/icons-calendar.png"
import phone from "../Assests/icons-phone.png"
import email from "../Assests/icons-mail.png"
import { Link } from 'react-router-dom'

const TalktoUs = () => {
    return (
        <div>
            <section className="excited-talk-section talk-to-us">
                <div className="container">
                    <h2 data-aos="fade-up" data-aos-delay="400">Talk to Us</h2>
                    <div className="talk-max">
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                <div className="talk-information">
                                    <div className="pc-experts-connect clearfix">
                                        <Link to="#" data-toggle="modal" data-target="#modal-meeting">
                                            <div className="connect-colm-left">
                                                <img src={calender} alt="icons-calendar" />
                                            </div>
                                            <div className="connect-colm-right">
                                                Schedule a meeting
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="pc-experts-connect clearfix">
                                        <Link to="tel:14693670669">
                                            <div className="connect-colm-left">
                                                <img src={phone} alt="icons-phone" />
                                            </div>
                                            <div className="connect-colm-right">
                                                1 469-367-0669
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="pc-experts-connect clearfix">
                                        <Link to="mailto:sales@procorpsystems.co">
                                            <div className="connect-colm-left">
                                                <img src={email} alt="icons-mail" />
                                            </div>
                                            <div className="connect-colm-right">
                                                sales@procorpsystems.co
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-8">
                                <div data-aos="fade-right" data-aos-delay="400" className="custom-talk-form">
                                    <form method="post" action="https://procorpsystems.co/save-enquiry" id="saveEnquiry">
                                        <input type="hidden" name="_token" value="BV4rpK4AFwsEwmahWoRFZciB0GNBFWd4mkkUtQB7" />
                                        <div className="form-group">
                                            <div className="input-field">
                                                <input type="text" id="first-name" required name="fname" />
                                                <label for="first-name"><span className="cover-bg">First Name</span></label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-field">
                                                <input type="text" id="last-name" required name="lname" />
                                                <label for="last-name"><span className="cover-bg">Last Name</span></label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-field">
                                                <input type="text" id="company-name" required name="company" />
                                                <label for="company-name"><span className="cover-bg">Company Name</span></label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-field">
                                                <input type="email" id="email" required name="email" />
                                                <label for="email"><span className="cover-bg">Email</span></label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-field">
                                                <input type="text" id="phone-number" required name="phone" />
                                                <label for="phone-number"><span className="cover-bg">Phone Number</span></label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="selectbox">
                                                <select id="permission_level" name="state" required>
                                                    <option value="">Select Region</option>
                                                    <option value="Alabama">Alabama</option>
                                                    <option value="Alaska">Alaska</option>
                                                    <option value="American Samoa">American Samoa</option>
                                                    <option value="Arizona">Arizona</option>
                                                    <option value="Arkansas">Arkansas</option>
                                                    <option value="California">California</option>
                                                    <option value="Colorado">Colorado</option>
                                                    <option value="Connecticut">Connecticut</option>
                                                    <option value="Delaware">Delaware</option>
                                                    <option value="District of Columbia">District of Columbia</option>
                                                    <option value="Florida">Florida</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Guam">Guam</option>
                                                    <option value="Hawaii">Hawaii</option>
                                                    <option value="Idaho">Idaho</option>
                                                    <option value="Illinois">Illinois</option>
                                                    <option value="Indiana">Indiana</option>
                                                    <option value="Iowa">Iowa</option>
                                                    <option value="Kansas">Kansas</option>
                                                    <option value="Kentucky">Kentucky</option>
                                                    <option value="Louisiana">Louisiana</option>
                                                    <option value="Maine">Maine</option>
                                                    <option value="Maryland">Maryland</option>
                                                    <option value="Massachusetts">Massachusetts</option>
                                                    <option value="Michigan">Michigan</option>
                                                    <option value="Minnesota">Minnesota</option>
                                                    <option value="Mississippi">Mississippi</option>
                                                    <option value="Missouri">Missouri</option>
                                                    <option value="Montana">Montana</option>
                                                    <option value="Nebraska">Nebraska</option>
                                                    <option value="Nevada">Nevada</option>
                                                    <option value="New Hampshire">New Hampshire</option>
                                                    <option value="New Jersey">New Jersey</option>
                                                    <option value="New Mexico">New Mexico</option>
                                                    <option value="New York">New York</option>
                                                    <option value="North Carolina">North Carolina</option>
                                                    <option value="North Dakota">North Dakota</option>
                                                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                    <option value="Ohio">Ohio</option>
                                                    <option value="Oklahoma">Oklahoma</option>
                                                    <option value="Oregon">Oregon</option>
                                                    <option value="Pennsylvania">Pennsylvania</option>
                                                    <option value="Puerto Rico">Puerto Rico</option>
                                                    <option value="Rhode Island">Rhode Island</option>
                                                    <option value="South Carolina">South Carolina</option>
                                                    <option value="South Dakota">South Dakota</option>
                                                    <option value="Tennessee">Tennessee</option>
                                                    <option value="Texas">Texas</option>
                                                    <option value="Utah">Utah</option>
                                                    <option value="Vermont">Vermont</option>
                                                    <option value="Virgin Islands">Virgin Islands</option>
                                                    <option value="Virginia">Virginia</option>
                                                    <option value="Washington">Washington</option>
                                                    <option value="West Virginia">West Virginia</option>
                                                    <option value="Wisconsin">Wisconsin</option>
                                                    <option value="Wyoming">Wyoming</option>
                                                </select>
                                                <div className="caret-icon-down"><i aria-hidden="true" className="fa fa-caret-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-field">
                                                <input type="text" id="message-field" required name="message" />
                                                <label for="message-field"><span className="cover-bg">Message</span></label>
                                            </div>
                                        </div>
                                        <button type="submit" className="common-btn text-capital send-message-btn">submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TalktoUs